import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';

import { UiEmbed, Link } from '@powdr/components';
import { BlockTypes, ThemeProps as Prop } from '@powdr/constants';
import { ContentBlockType } from '@powdr/model';
import { themeUtil, formatDate } from '@powdr/utils';

const CPNT = BlockTypes.BASIC;

// styled components
const ContentBlockBasic = styled.div`
  position: relative;

  .link-icon {
    margin-left: 5px;
    margin-top: -3px;
    height: 1em;
    width: 1em;

    svg {
      fill: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};
    }
  }

  .image-container {
    position: relative;
  }

  .cb-title {
    margin-bottom: 15px;
  }

  .cb-copy {
    margin-bottom: 15px;
  }

  .cb-basic-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px 0;
    color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};
  }

  &:hover {
    .cb-basic-button {
      color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};
    }

    .link-icon {
      svg {
        fill: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};
      }
    }
  }

  small {
    font-size: 14px;
    text-transform: none;
    margin-bottom: 10px;
  }
`;

const StyledImage = styled(GatsbyImage)`
  margin-bottom: 15px;
`;

const Image = styled.img`
  width: 100%;
  margin-bottom: 15px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
`;

export const Basic = ({
  title, copy, blockType, colorProfile, image, videoId, isStaticImageLink, ariaLabel,
  buttonEnabled, buttonLink, buttonText, buttonTarget, buttonClick, date, endDate,
  altButtonEnabled, altButtonText, altButtonLink, formattedContent,
}) => (
  <ContentBlockBasic colorProfile={colorProfile} className={`content-block-${blockType}`}>
    {(image && buttonEnabled && !videoId) && (
      <Link
        onClick={() => buttonClick()}
        aria-label={ariaLabel}
        href={buttonLink}
        target={buttonTarget}
      >
        {(!isStaticImageLink) && <StyledImage image={image.src} alt={image.name} />}
        {(isStaticImageLink) && <Image src={image.src} alt={image.name} />}
      </Link>
    )}
    {(image && !buttonEnabled && !videoId
      && !isStaticImageLink) && <StyledImage image={image.src} alt={image.name} />}
    {(image && !buttonEnabled && !videoId
      && isStaticImageLink) && <Image src={image.src} alt={image.name} />}
    {(videoId) && (
      <div className="image-container">
        <UiEmbed videoId={videoId} />
        {(image && !isStaticImageLink) && <StyledImage image={image.src} alt={image.name} />}
        {(image && isStaticImageLink) && <Image src={image.src} alt={image.name} />}
      </div>
    )}
    {(title) && <h3 className="cb-title">{title}</h3>}
    {(date) && <small>{`${formatDate(date).month.num}/${formatDate(date).day.num}/${formatDate(date).year}${(endDate) ? ` - ${formatDate(endDate).month.num}/${formatDate(endDate).day.num}/${formatDate(endDate).year}` : ''}`}</small>}
    {(copy) && <p className="cb-copy">{ReactHtmlParser(copy)}</p>}
    {(formattedContent) && <div className="cb-formatted-content">{ReactHtmlParser(formattedContent)}</div>}
    <ButtonWrapper>
      {(buttonText && buttonEnabled) && (
        <Link
          className="button with-background uppercase"
          onClick={() => buttonClick()}
          aria-label={ariaLabel}
          role="button"
          href={buttonLink}
          target={buttonTarget}
        >
          {buttonText}
        </Link>
      )}
      {(altButtonEnabled) && (
        <Link
          className="button with-background uppercase"
          onClick={() => buttonClick()}
          aria-label={ariaLabel}
          role="button"
          href={altButtonLink}
          target={buttonTarget}
        >
          {altButtonText}
        </Link>
      )}
    </ButtonWrapper>
  </ContentBlockBasic>
);

Basic.propTypes = PropTypes.objectOf(ContentBlockType).isRequired;
