/* eslint-disable no-nested-ternary */
import classNames from 'classnames';
import React, { useContext, useState } from 'react';

import {
  DorFeed, Icon, WidgetWeather, WidgetPromotions,
  SeasonToggle,
} from '@powdr/components';
import { BannerPlacement, GlobalLayoutValues } from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { useStaticData } from '@powdr/web/src/hooks';

import {
  FeedEcommerceBanner,
  StyledFeedContainer,
  MobileDorDrawer,
  DorDrawerCloseButton,
  FeedScrollContainer,
  WidgetWrapper,
} from './styles';

export const FeedContainer = () => {
  const { headerConfig } = useStaticData();
  const { isMobile, themeConfig, toggleBodyScroll } = useContext(AppContext);
  const [isMegaDorFeedOpen, setIsMegaDorFeedOpen] = useState(false);
  const [isMegaPromotionsOpen, setIsMegaPromotionsOpen] = useState(false);
  const splitWidget = (headerConfig.isPromotionsEnabled && headerConfig.isDorFeedEnabled);
  const isFeedBanner = (
    headerConfig.bannerPlacement === BannerPlacement.IN_FEEDS_CONTAINER
    && headerConfig.isBannerEnabled
  );

  const handleWeatherWidgetClick = () => {
    if (isMobile) {
      toggleBodyScroll(true);
      if (!isMegaDorFeedOpen) setIsMegaDorFeedOpen(true);
    }

    if (!isMobile) {
      setIsMegaPromotionsOpen(false); // close promotions to prevent overlap
      setIsMegaDorFeedOpen(!isMegaDorFeedOpen);
    }
  };

  const handlePromotionsWidgetClick = () => {
    if (!headerConfig.promoButtonUrl) {
      setIsMegaDorFeedOpen(false); // close dor feed to prevent overlap
      setIsMegaPromotionsOpen(!isMegaPromotionsOpen);
    }
  };

  return (
    <StyledFeedContainer
      className={classNames('feed-container')}
      headerHeightMobile={themeConfig.layout.headerHeightMobile}
      isDorFeedEnabled={headerConfig.isDorFeedEnabled}
      mobileWeatherWidgetWidth={GlobalLayoutValues.MOBILE.WEATHER_WIDGET_WIDTH}
      headerFeedContainer={themeConfig.layout.headerFeedContainer}
    >
      {/* Banner */}
      {isFeedBanner && <FeedEcommerceBanner isFromFeedContainer isFeedBanner={isFeedBanner} />}

      {/* Seasonality */}
      {headerConfig.seasonalityPlacement === BannerPlacement.IN_FEEDS_CONTAINER
        && headerConfig.isSeasonalityEnabled && <SeasonToggle />}

      {/* Header Widgets */}
      <WidgetWrapper>
        {headerConfig.isDorFeedEnabled && (
          <>
            <WidgetWeather
              onClick={handleWeatherWidgetClick}
              toggleWidget={handleWeatherWidgetClick}
              isOpen={isMegaDorFeedOpen}
              width={(splitWidget)
                ? (themeConfig.layout.headerFeedContainer / 2)
                : themeConfig.layout.headerFeedContainer}
              headerHeightMobile={themeConfig.layout.headerHeightMobile}
              headerHeight={themeConfig.layout.headerHeight}
              isFeedBanner={isFeedBanner}
            />
            <MobileDorDrawer className={classNames({ open: isMegaDorFeedOpen && isMobile })}>
              <DorDrawerCloseButton
                onClick={() => { setIsMegaDorFeedOpen(false); toggleBodyScroll(false); }}
              >
                <Icon name="ui-close" width="20" height="20" />
              </DorDrawerCloseButton>
              <FeedScrollContainer>
                <DorFeed />
              </FeedScrollContainer>
            </MobileDorDrawer>
          </>
        )}

        {headerConfig.isPromotionsEnabled && (
          <WidgetPromotions
            onClick={handlePromotionsWidgetClick}
            toggleWidget={handlePromotionsWidgetClick}
            isOpen={isMegaPromotionsOpen}
            width={(splitWidget)
              ? (themeConfig.layout.headerFeedContainer / 2)
              : themeConfig.layout.headerFeedContainer}
            headerHeight={themeConfig.layout.headerHeight}
            isFeedBanner={isFeedBanner}
          />
        )}
      </WidgetWrapper>
    </StyledFeedContainer>

  );
};
