import PropTypes from 'prop-types';
import React from 'react';

import { Icon } from '@powdr/components';
import { deslugify } from '@powdr/utils';

import {
  Address, Email, PhoneNumber, StyledSocialIconList,
} from './styles';

export const SocialIconList = ({
  title,
  primarySet,
  address,
  email,
  phoneNumber,
  relationships,
  parent,
}) => {
  const data = relationships?.socialIcons;

  if (!data && !email && !phoneNumber) return null;

  // TODO: Convert this to use styled components and remove utility classes
  return (
    <StyledSocialIconList
      isEmailSignup={parent === 'email-signup'}
      className="social-link-set"
      role="navigation"
    >
      {parent !== 'email-signup' && (<h3>{title}</h3>)}
      {(email) && <Email href={`mailto:${email}`}>{email}</Email>}
      {(address) && <Address href={`https://www.google.com/maps?q=${address}`} target="_blank" aria-label="Open Location to Google Maps (opens in new tab)">{address}</Address>}
      {(phoneNumber) && <PhoneNumber href={`tel:${phoneNumber}`}>{phoneNumber}</PhoneNumber>}
      <ul className="social">
        {data.map((socialBtn) => {
          if ((parent === 'email-signup' && !primarySet) || !socialBtn.socialSite) return null;
          const socialSiteString = deslugify(socialBtn.socialSite).replace('Social ', '');

          return (
            <li key={`${socialBtn.socialSite}-${socialBtn.id}`}>
              <a
                title={socialSiteString}
                className="btn m-reg m-round-40-p-40"
                href={socialBtn.iconLink}
                target="_blank"
                rel="noreferrer"
                aria-label={`${socialSiteString} (opens in new tab)`}
              >
                <Icon name={socialBtn.socialSite} width="20" height="20" />
              </a>
            </li>
          );
        })}
      </ul>
    </StyledSocialIconList>
  );
};

SocialIconList.propTypes = {
  title: PropTypes.string,
  primarySet: PropTypes.bool,
  parent: PropTypes.string,
  address: PropTypes.string,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  relationships: PropTypes.shape().isRequired,
};

SocialIconList.defaultProps = {
  title: '',
  primarySet: false,
  parent: '',
  address: '',
  email: '',
  phoneNumber: '',
};
