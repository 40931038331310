/* eslint-disable import/no-unresolved */
import styled, { css } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import 'swiper/css/controller';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';

import {
  ThemeProps as Prop, Breakpoints, Components, CarouselPaginationPlacement,
} from '@powdr/constants';
import { rgbaTransparencyToHex, themeUtil } from '@powdr/utils';

const CPNT = Components.CONTENT_CAROUSEL;

export const StyledSwiperCarousel = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: ${({ $colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, $colorProfile)};

`;

export const StyledSwiper = styled(Swiper)`
  width: 100%;
  height: 100%;
`;

export const StyledSwiperSlide = styled(SwiperSlide)``;

export const FractionPaginationWrapper = styled.div`
  display: flex;
  height: 115px;
  align-items: center;
  justify-content: center;

  ${Breakpoints.mobile(css`
    height: 75px;
    ${({ $paginationPlacement }) => (($paginationPlacement === CarouselPaginationPlacement.ON_SLIDE) ? 'height: fit-content;' : '')}
  `)}

  ${({ $paginationPlacement, $colorProfile }) => (($paginationPlacement === CarouselPaginationPlacement.ON_SLIDE)
    ? `
      position: absolute;
      height: fit-content;
      padding: 15px;
      margin: 10px;
      bottom: 0;
      right: 0;
      z-index: 2;
      background-color: ${themeUtil(Prop.BACKGROUND, CPNT, $colorProfile) + rgbaTransparencyToHex(0.75)};
    ` : '')}
`;

export const FractionPagination = styled.div`
  color: ${({ $colorProfile }) => themeUtil(Prop.CONTENT, CPNT, $colorProfile)};
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  font-size: ${({ theme }) => theme.FontSize.MED40};

  ${Breakpoints.mobile(css`
    ${({ $paginationPlacement, theme }) => (($paginationPlacement === CarouselPaginationPlacement.ON_SLIDE) ? `font-size: ${theme.FontSize.REG50};` : '')}
  `)}
`;

export const OnSlideArrows = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  pointer-events: none;
  justify-content: space-between;
  position: absolute;
`;

export const ControlArrowWrapper = styled.button`
  cursor: pointer;

  // arrows positioned on slide
  ${({ placeOnSlide, direction, colorProfile }) => ((placeOnSlide) ? `
    width: 35px;
    height: 100%;
    pointer-events: all;

    &:hover {
      .control-arrow {
        &:after {
          border: solid ${themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
          border-width: 35px;
          border-top: 35px solid transparent;
          border-bottom: 35px solid transparent;
          ${(direction === 'prev') ? 'border-right' : 'border-left'}: none;
        }

        .control-arrow-icon {
          svg {
            fill: ${themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
          }
        }
      }
    }` : ''
  )}
`;

export const ControlArrow = styled.div`
  // arrows positioned on slide
  ${({ placeOnSlide, direction, colorProfile }) => ((placeOnSlide) ? `
      position: absolute;
      top: 50%;
      ${(direction === 'prev') ? 'left' : 'right'}: 0;

      &:after {
        content: " ";
        position: absolute;
        top: calc(50% - 35px);
        ${(direction === 'prev') ? 'left' : 'right'}: 0;
        border: solid ${themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
        border-width: 35px;
        border-top: 35px solid transparent;
        border-bottom: 35px solid transparent;
        ${(direction === 'prev') ? 'border-right' : 'border-left'}: none;
        width: 0;
        height: 0;
        z-index: 2;
      }

      .control-arrow-icon {
        position: relative;
        z-index: 3;
        ${(direction === 'prev') ? 'left: 5px;' : 'right: 5px; transform: rotate(180deg);'};

        svg {
          fill: ${themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
        }
      }
    ` : `
      ${(direction === 'prev') ? '' : 'transform: rotate(-180deg);'};

      .control-arrow-icon {
        svg {
          fill: ${themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
        }
      }


      &:hover {
        .control-arrow-icon {
          svg {
            fill: ${themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
          }
        }
      }
    `
  )}
`;
