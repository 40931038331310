/* eslint-disable import/no-unresolved */
import PropTypes from 'prop-types';
import React, { useMemo, useRef, useState } from 'react';
import {
  Navigation, Autoplay, EffectFade, Pagination,
} from 'swiper/modules';

import { Icon } from '@powdr/components';
import {
  CarouselControlTypes, CarouselPaginationPlacement,
  CarouselPaginationTypes, CarouselTransitionEffects,
  ColorProfiles,
} from '@powdr/constants';

import {
  StyledSwiperCarousel, StyledSwiper, StyledSwiperSlide,
  OnSlideArrows, ControlArrowWrapper, ControlArrow,
  FractionPaginationWrapper, FractionPagination,
} from './styles';

export const SwiperCarousel = ({
  className,
  isAutoRotate,
  isInfiniteScroll,
  isControlsOnSlide,
  controlsOnSlideType,
  isHideControlsBackground,
  transitionEffect,
  paginationType,
  paginationPlacement,
  isAutoHeight,
  colorProfile,
  children,
}) => {
  const [carouselItems] = useState(children.filter((e) => e !== null));
  const [activeIndex, setActiveIndex] = useState();
  const mainCarouselRef = useRef();
  const rotationSpeed = 7000; // ms
  const animationSpeed = 600; // ms

  const ControlArrowHandler = ({ placeOnSlide, direction }) => useMemo(() => (
    <ControlArrowWrapper
      placeOnSlide={placeOnSlide}
      direction={direction}
      onClick={() => ((direction === 'prev')
        ? mainCarouselRef.current?.slidePrev()
        : mainCarouselRef.current?.slideNext())}
    >
      <ControlArrow
        className="control-arrow"
        placeOnSlide={placeOnSlide}
        direction={direction}
        colorProfile={colorProfile || ColorProfiles.BASE}
      >
        <Icon
          className="control-arrow-icon"
          name={(placeOnSlide) ? 'ui-arrow-left' : 'ui-arrow-circle-left'}
          height={(placeOnSlide) ? '15' : '45'}
          width={(placeOnSlide) ? '15' : '45'}
        />
      </ControlArrow>
    </ControlArrowWrapper>
  ), [direction, placeOnSlide]);

  return (
    (carouselItems.length > 1) ? (
      <StyledSwiperCarousel
        className={className}
        $colorProfile={colorProfile}
      >
        <>
          {/* Slide Count */}
          {(paginationType === CarouselPaginationTypes.FRACTION) && (
            <FractionPaginationWrapper
              $colorProfile={colorProfile}
              $paginationPlacement={paginationPlacement}
            >
              <FractionPagination
                $colorProfile={colorProfile}
                $paginationPlacement={paginationPlacement}
              >
                {`${activeIndex} / ${carouselItems.length}`}
              </FractionPagination>
            </FractionPaginationWrapper>
          )}

          {/* Main Carousel */}
          <StyledSwiper
            modules={[Autoplay, Navigation, EffectFade]}
            onBeforeInit={(swiper) => { mainCarouselRef.current = swiper; }}
            onSlideChange={(swiper) => { setActiveIndex(swiper.realIndex + 1); }}
            autoplay={(isAutoRotate) ? {
              delay: rotationSpeed,
              disableOnInteraction: false,
            } : false}
            autoHeight={isAutoHeight}
            loop={isInfiniteScroll}
            speed={animationSpeed}
            effect={transitionEffect || null}
          >
            {carouselItems.map((item) => (
              <StyledSwiperSlide key={item.key}>
                {item}
              </StyledSwiperSlide>
            ))}
            {/* On-Slide Carousel Controls */}
            {(isControlsOnSlide) && (
              <OnSlideArrows>
                <ControlArrowHandler placeOnSlide direction="prev" />
                <ControlArrowHandler placeOnSlide direction="next" />
              </OnSlideArrows>
            )}
          </StyledSwiper>

        </>
      </StyledSwiperCarousel>
    ) : carouselItems[0]
  );
};

SwiperCarousel.propTypes = {
  className: PropTypes.string,
  isAutoRotate: PropTypes.bool,
  isInfiniteScroll: PropTypes.bool,
  isControlsOnSlide: PropTypes.bool,
  controlsOnSlideType: PropTypes.oneOf(Object.values(CarouselControlTypes)),
  isHideControlsBackground: PropTypes.bool,
  transitionEffect: PropTypes.oneOf(Object.values(CarouselTransitionEffects)),
  paginationType: PropTypes.oneOf(Object.values(CarouselPaginationTypes)),
  paginationPlacement: PropTypes.oneOf(Object.values(CarouselPaginationPlacement)),
  isAutoHeight: PropTypes.bool,
  colorProfile: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

SwiperCarousel.defaultProps = {
  className: '',
  isAutoRotate: false,
  isInfiniteScroll: true,
  isControlsOnSlide: false,
  controlsOnSlideType: CarouselControlTypes.EDGE_TRIANGLES,
  transitionEffect: null,
  isHideControlsBackground: false,
  paginationType: CarouselPaginationTypes.NONE,
  paginationPlacement: CarouselPaginationPlacement.IN_CONTROLS,
  isAutoHeight: false,
  colorProfile: null,
};
