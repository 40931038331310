// eslint-disable-next-line import/no-unresolved
import { navigate } from '@reach/router';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';

import { Icon, Link, StickyNav } from '@powdr/components';
import { AppContext } from '@powdr/context';
import { useSeason } from '@powdr/hooks';
import { getLevelLink, getLevelName, isRuntime } from '@powdr/utils';
import { useStaticData } from '@powdr/web/src/hooks';

import {
  StyledPageTitle, PageBackButton, StyledPageHeader, ThirdLevelNav,
} from './styles';

export const PageHeader = ({
  backButtonLink, headerHeight, levels, title,
}) => {
  if (isRuntime()) return null;
  const { navigation } = useStaticData();
  const { isMobile } = useContext(AppContext);
  const { checkSeasonality } = useSeason();
  // Using this to decide whether to navigate to prev history or form parent page link below.
  const isUseHistory = (window?.history?.state || !backButtonLink) || false;

  const levelTwoNav = useMemo(
    () => navigation?.childPages
      ?.find((level1) => getLevelLink(level1) === `/${levels[0]}`)?.childPages
      ?.filter((page) => !page.isHideFromNav && checkSeasonality(page.season)) || [],
    [checkSeasonality, levels, navigation],
  );

  const levelThreeNav = useMemo(
    () => levelTwoNav?.find((level2) => getLevelLink(level2) === `/${levels[0]}/${levels[1]}`)?.childPages
      ?.filter((page) => !page.isHideFromNav && checkSeasonality(page.season)) || [],
    [checkSeasonality, levels, levelTwoNav],
  );

  return (
    <StyledPageHeader>
      <StickyNav
        headerHeight={headerHeight}
        levelTwoNav={levelTwoNav}
        levelThreeNav={levelThreeNav}
        levels={levels}
      />
      { !!(title) && (
        // Missing logic for making sure page title is correct, maybe move title logic from layout?
        <StyledPageTitle className="container">{title}</StyledPageTitle>
      )}
      { !isMobile && levelThreeNav.length > 1 && (
        <ThirdLevelNav className="container">
          <ul>
            { levelThreeNav.map((levelThreeItem) => (
              <li className="t-item" key={levelThreeItem.id}>
                { getLevelLink(levelThreeItem).includes(`/${levels[2]}`) && (
                  <h2>
                    <Link
                      className={classNames('btn', { 'm-active': getLevelLink(levelThreeItem).endsWith(`/${levels[2]}`) })}
                      href={getLevelLink(levelThreeItem)}
                    >
                      {getLevelName(levelThreeItem)}
                    </Link>
                  </h2>
                )}
                { !getLevelLink(levelThreeItem).includes(`/${levels[2]}`) && (
                  <div>
                    <Link
                      // TODO: find all m-active and btn classes and convert to styled components
                      className={classNames('btn', { 'm-active': getLevelLink(levelThreeItem).includes(`/${levels[0]}/${levels[1]}/${levels[2]}`) })}
                      href={getLevelLink(levelThreeItem)}
                    >
                      {getLevelName(levelThreeItem)}
                    </Link>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </ThirdLevelNav>
      )}
      { backButtonLink && (
        <PageBackButton className="levels-back-button container" ng-if="cmsLevel1.ls.isLevelsBackButton">
          { isUseHistory
            ? (
              <button type="button" onClick={() => navigate(-1)}>
                Back
                <Icon className="levels-back-icon" name="ui-back-arrow" width="20" />
              </button>
            )

            : (
              <Link
                aria-label="Back"
                href={backButtonLink}
              >
                <button type="button">
                  Back
                  <Icon className="levels-back-icon" name="ui-back-arrow" width="20" />
                </button>
              </Link>
            )}
        </PageBackButton>
      )}
    </StyledPageHeader>
  );
};

PageHeader.propTypes = {
  backButtonLink: PropTypes.string,
  headerHeight: PropTypes.number,
  levels: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string,
};

PageHeader.defaultProps = {
  backButtonLink: null,
  headerHeight: 0,
  title: '',
};
