/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line import/no-unresolved
import { format, compareAsc, parse } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { Icon, UiTable, Slider } from '@powdr/components';
import { AppContext } from '@powdr/context';
import {
  globalContentParser, fixCmsLinks, textFilter, cleanSliderData, cleanTableData,
} from '@powdr/utils';

import {
  StyledTemplatedContent, NewsStyleDate,
  DynamicTitle, DynamicBody, ShortDesc, NewsStyleImage,
  StartEndDate, DateContainer, RowSection,
  ContentContainer, SlideTableContainer,
  VenueHoursContainer, DynamicVenue, DynamicHours,
} from './styles';

export const TemplatedContent = ({
  fields,
}) => {
  const { themeConfig: { modal } } = useContext(AppContext);
  const {
    title, body: bodyContent,
    field_date: dateString, field_end_date: endDateString,
    field_short_description: shortDesc,
    field_ui_table: tableContent, field_slider: sliderAPI, field_image: image,
    field_author: author, field_contact_name: contactName,
    field_contact_email: contactEmail, field_contact_phone: contactPhone,
    field_venue: venue, field_hours: hours, relationships: components,
  } = fields;
  const body = (bodyContent?.value) ? bodyContent.value : bodyContent;
  const slider = (components?.slider) ? components.slider : sliderAPI;
  const table = (tableContent?.rows) ? tableContent.rows : tableContent;

  const startDate = (dateString) ? parse(dateString, 'yyyy-MM-dd', new Date()) : null;
  const endDate = (endDateString) ? parse(endDateString, 'yyyy-MM-dd', new Date()) : null;
  const newsStyleModal = false;

  return (
    <StyledTemplatedContent splitContent={(table || slider)}>
      {(title || body || table || slider) && (
        <RowSection>
          <ContentContainer splitContent={(table || slider)}>
            {(startDate && newsStyleModal) && <NewsStyleDate>{format(startDate, 'MMMM d, yyyy')}</NewsStyleDate>}
            {(title) && <DynamicTitle>{textFilter(title)}</DynamicTitle>}
            {(shortDesc) && <ShortDesc>{textFilter(shortDesc)}</ShortDesc>}
            {(image && newsStyleModal) && <NewsStyleImage />}
            {((startDate || endDate) && !newsStyleModal) && (
              <DateContainer>
                <Icon name="content-calendar" width="25" height="25" />
                <StartEndDate>
                  {`${format(startDate, 'MMMM do yyyy')}`}
                  {(endDate && compareAsc(startDate, endDate) !== 0) ? ` - ${format(endDate, 'MMMM do yyyy')}` : ''}
                </StartEndDate>
              </DateContainer>
            )}
            {(body) && <DynamicBody>{globalContentParser(fixCmsLinks(body))}</DynamicBody>}
            {(venue || hours)
              && (
              <VenueHoursContainer>
                {(venue) && (
                  <DynamicVenue hasHours={!!(hours)}>
                    <h5 className="label">Venue: </h5>
                    <p className="data">{venue}</p>
                  </DynamicVenue>
                )}
                {(hours) && (
                  <DynamicHours>
                    <h5 className="label">Hours: </h5>
                    <p className="data">{hours}</p>
                  </DynamicHours>
                )}
              </VenueHoursContainer>
              )}
            {(author) && <p>{author}</p>}
            {(contactName) && <p>{contactName}</p>}
            {(contactEmail) && <p>{contactEmail}</p>}
            {(contactPhone) && <p>{contactPhone}</p>}
          </ContentContainer>
          {(table || slider) && (
            <SlideTableContainer>
              {(slider) && (
                <Slider {...cleanSliderData(slider)} parentColorProfile={modal} />
              )}
              {(table) && (
                <UiTable
                  tables={[{ rows: cleanTableData(table) }]}
                  parentColorProfile={modal}
                />
              )}
            </SlideTableContainer>
          )}
        </RowSection>
      )}
    </StyledTemplatedContent>
  );
};

TemplatedContent.propTypes = {
  fields: PropTypes.shape().isRequired,
};

TemplatedContent.defaultProps = {};
