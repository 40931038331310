import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import styled, { css } from 'styled-components';

import { Icon } from '@powdr/components/icon';
import { Link } from '@powdr/components/link';
import { AlternatingBlockLayoutOptions, BlockTypes, Breakpoints } from '@powdr/constants';
import { ContentBlockType } from '@powdr/model';
import { getDateInfo, clamp } from '@powdr/utils';

// eslint-disable-next-line no-unused-vars
const CPNT = BlockTypes.IMAGE_FLAG;

// styled components
const ContentBlockImageFlag = styled.div`
  display: flex;
  position: relative;
  flex-direction: ${({ alternateBlockLayoutState }) => (
    (alternateBlockLayoutState === AlternatingBlockLayoutOptions.DEFAULT)
      ? 'row'
      : 'row-reverse'
  )};
  gap: 20px;

  .cb-title {
    text-transform: uppercase;
    margin-bottom: 5px;

    ${Breakpoints.large(css`
      font-size: ${({ theme }) => theme.FontSize.MED10};
    `)}

    ${Breakpoints.standard(css`
      font-size: ${({ theme }) => theme.FontSize.MED10};
    `)}
  }

  small {
    ${({ theme }) => theme.fonts.PRI_BDY_MED};
    font-weight: bold;
    margin-bottom: 5px;
  }

  .cb-copy {
    margin-bottom: 15px;
    ${() => clamp(4)}

    ${Breakpoints.mobile(css`
      margin-bottom: 7px;
    `)}
  }

  ${Breakpoints.mobile(css`
    gap: 10px;
  `)}
`;

const ImageContainer = styled.div`
  width: 33.3%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 66.6%;
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  width: fit-content;

  .link-icon {
    margin-left: 5px;
  }
`;

const Image = styled.img`
  height: 100%;
  object-fit: contain;
`;

const StyledGatsbyImage = styled(GatsbyImage)`
  height: 100%;
`;

export const ImageFlag = ({
  title, copy, image, colorProfile, blockType, ariaLabel,
  buttonEnabled, buttonText, buttonLink, buttonTarget, buttonIconName, buttonClick,
  date, endDate, isOverlayEnabled, isStaticImageLink, alternateBlockLayoutState,
}) => (
  <ContentBlockImageFlag
    colorProfile={colorProfile}
    className={`content-block-${blockType}`}
    alternateBlockLayoutState={alternateBlockLayoutState}
  >
    <ImageContainer>
      {(image && !isStaticImageLink) && <StyledGatsbyImage image={image.src} alt={image.name} />}
      {(image && isStaticImageLink) && <Image src={image.src} alt={image.name} />}
    </ImageContainer>
    <ContentContainer>
      <h3 className="cb-title">{title}</h3>
      {(date) && <small>{ReactHtmlParser(getDateInfo(date, endDate, blockType).full)}</small>}
      <p className="cb-copy">{copy}</p>
      {(buttonEnabled && buttonText && !isOverlayEnabled) && (
      <StyledLink
        className="button with-background uppercase"
        href={buttonLink}
        target={buttonTarget}
        onClick={() => buttonClick()}
        aria-label={ariaLabel}
        role="button"
      >
        {buttonText}
        {(buttonIconName) && <Icon className="link-icon" name={buttonIconName} width="13" height="13" />}
      </StyledLink>
      )}
    </ContentContainer>
  </ContentBlockImageFlag>
);

ImageFlag.propTypes = PropTypes.objectOf(ContentBlockType).isRequired;
