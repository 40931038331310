import styled from 'styled-components';

export const StyledCompareCards = styled.div`
  ${({ theme }) => theme.fonts.PRI_BDY_MED};
  ${({ isMobile }) => {
    if (isMobile) {
      return 'display: block;';
    }

    return `
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    `;
  }}
`;

export const CarouselContainer = styled.div`
  position: relative;
  padding-top: 15px;
`;
