/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { ContentCarousel, ContentBlock, Icon } from '@powdr/components';
import { CarouselIndicatorTypes, CBCarouselIndicatorOptions } from '@powdr/constants';
import { useSeason } from '@powdr/hooks';
import { colorProfileByIndex } from '@powdr/utils';

import { StyledContentBlockCarousel, CarouselIcon } from './styles';

export const ContentBlockCarousel = ({
  id,
  iconName,
  blockType,
  customSlideIndicators,
  slidesToShow,
  colorProfile,
  relationships,
  parentColorProfile,
}) => {
  const { checkSeasonality } = useSeason();
  const { contentBlocks } = relationships;
  const trueColorProfile = colorProfileByIndex(colorProfile);

  const customIndicators = () => (
    (customSlideIndicators) ? {
      type: (customSlideIndicators === CBCarouselIndicatorOptions.TEXT_INDICATORS)
        ? CarouselIndicatorTypes.TEXT
        : (customSlideIndicators === CBCarouselIndicatorOptions.ICON_INDICATORS)
          ? CarouselIndicatorTypes.ICON : '',
      items: contentBlocks.map((cb) => (
        {
          iconName: cb.iconName,
          height: cb.height,
        }
      )).filter((e) => e.iconName !== null),
    } : null
  );

  const contentBlockMap = useMemo(
    () => contentBlocks.map((contentBlock, index) => {
      const { id: blockId, season, isHideBlock } = contentBlock;

      if (checkSeasonality(season) && !isHideBlock) {
        return (
          <ContentBlock
            contentBlock={contentBlock}
            key={blockId}
            index={index}
            columnOption={1}
            blockType={blockType}
            colorProfile={trueColorProfile}
          />
        );
      }

      return null;
    }).filter((x) => x),
    [contentBlocks, blockType, checkSeasonality, trueColorProfile],
  );

  if (contentBlockMap?.length === 0) return null;

  return (
    <StyledContentBlockCarousel key={id}>
      {iconName && (
        <CarouselIcon colorProfile={parentColorProfile}>
          <Icon name={iconName} height="50" />
        </CarouselIcon>
      )}
      <ContentCarousel
        colorProfile={parentColorProfile}
        isBottomArrows
        isTopIndicators={(customSlideIndicators !== CBCarouselIndicatorOptions.NO_INDICATORS
          && customIndicators()?.items.length > 0)}
        customIndicators={customIndicators()}
        slidesToShow={(contentBlockMap.length > parseInt(slidesToShow, 10))
          ? parseInt(slidesToShow, 10)
          : 1}
      >
        {contentBlockMap}
      </ContentCarousel>
    </StyledContentBlockCarousel>
  );
};

ContentBlockCarousel.propTypes = {
  id: PropTypes.string,
  iconName: PropTypes.string,
  blockType: PropTypes.string,
  customSlideIndicators: PropTypes.number,
  slidesToShow: PropTypes.string,
  colorProfile: PropTypes.number,
  parentColorProfile: PropTypes.number,
  relationships: PropTypes.shape(),
};

ContentBlockCarousel.defaultProps = {
  id: '',
  iconName: '',
  blockType: '',
  customSlideIndicators: 0,
  slidesToShow: null,
  colorProfile: null,
  relationships: null,
  parentColorProfile: null,
};
