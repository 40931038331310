import { Link as GatsbyLink } from 'gatsby';
import PropTypes from 'prop-types';
import { stringify } from 'query-string';
import React, { useContext } from 'react';
import {
  useQueryParams,
  StringParam,
  encodeQueryParams,
} from 'use-query-params';

import { LinkTargets, QueryParamNames } from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { LinkType } from '@powdr/model';

const queryParamOptions = {
  [QueryParamNames.SEASON]: StringParam,
  [QueryParamNames.PRODUCT]: StringParam,
  [QueryParamNames.PAGE]: StringParam,
};

export const Link = ({
  className, title, ariaLabel, role, href, target, onClick, state, children,
}) => {
  const { isCancelScroll } = useContext(AppContext);
  const [urlQueryParams] = useQueryParams(queryParamOptions);
  const internal = /^\/(?!\/)/.test(href); // check if relative or external link
  const hrefHasParams = /\?./.test(href); // check if incoming href has an existing query param
  const hrefHasAnchor = /#./.test(href); // check if incoming href has an anchor tag
  const params = stringify(encodeQueryParams(queryParamOptions, urlQueryParams));
  const anchor = (hrefHasAnchor) ? href?.substring(href.indexOf('#')) : '';
  const targetOutside = !!((target && target !== LinkTargets.SELF));

  return (
    (!internal || targetOutside) ? (
      <a
        className={className}
        title={title}
        onClick={onClick}
        target={target}
        href={href}
        role={(role) || ''}
        aria-label={`${ariaLabel || title} (opens in new tab)`}
      >
        {children}
      </a>
    ) : (
      <GatsbyLink
        className={className}
        title={title}
        onClick={onClick}
        to={params ? `${(hrefHasAnchor) ? href?.substring(0, href.indexOf('#')) : href}${(hrefHasParams) ? '&' : '?'}${params}${anchor}` : href}
        state={{ ...state, isCancelScroll }}
        role={(role) || ''}
        ariaLabel={ariaLabel || title}
      >
        {children}
      </GatsbyLink>
    )
  );
};

Link.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  ariaLabel: PropTypes.string,
  role: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.oneOf(Object.values(LinkTargets)),
  onClick: PropTypes.func,
  state: PropTypes.shape(),
  children: PropTypes.node,
};

Link.defaultProps = {
  className: '',
  title: '',
  ariaLabel: '',
  role: null,
  href: '#',
  target: '',
  onClick: () => {},
  state: {},
  children: null,
};
