import { ThemeProps as Prop, ColorProfiles, Components } from '@powdr/constants';

export const defaultTheme = {
  [ColorProfiles.BASE]: {
    [Prop.BACKGROUND]: '#FFFFFF',
    [Prop.HEADER]: '#3F3F3F',
    [Prop.CONTENT]: '#3F3F3F',
    [Prop.LINK_TXT]: '#009bd1',
    [Prop.LINK_TXT_HOVER]: '#009bd1',
    [Prop.BTN_BG]: '#009bd1',
    [Prop.BTN_TXT]: '#FFFFFF',
    [Prop.BTN_BG_HOVER]: '#3F3F3F',
    [Prop.BTN_TXT_HOVER]: '#ffffff',
    [Prop.BTN_BG_ACTIVE]: '#009bd1',
    [Prop.BTN_TXT_ACTIVE]: '#ffffff',
    [Prop.BTN_BG_INACTIVE]: '#3F3F3F',
    [Prop.BTN_TXT_INACTIVE]: '#ffffff',
    [Prop.ELEMENT]: '#009bd1',
    [Prop.ELEMENT_TXT]: '#FFFFFF',
    [Prop.BORDER]: '#3F3F3F',
    [Prop.ICON]: '#009bd1',
    [Prop.ICON_HOVER]: '#3f3f3f',
  },
  [ColorProfiles.PRIMARY]: {
    [Prop.BACKGROUND]: '#009bd1',
    [Prop.HEADER]: '#FFFFFF',
    [Prop.CONTENT]: '#FFFFFF',
    [Prop.LINK_TXT]: '#3f3f3f',
    [Prop.LINK_TXT_HOVER]: '#3f3f3f',
    [Prop.BTN_BG]: '#FFFFFF',
    [Prop.BTN_TXT]: '#009bd1',
    [Prop.BTN_BG_HOVER]: '#3F3F3F',
    [Prop.BTN_TXT_HOVER]: '#FFFFFF',
    [Prop.BTN_BG_ACTIVE]: '#FFFFFF',
    [Prop.BTN_TXT_ACTIVE]: '#009bd1',
    [Prop.BTN_BG_INACTIVE]: '#3F3F3F',
    [Prop.BTN_TXT_INACTIVE]: '#FFFFFF',
    [Prop.ELEMENT]: '#FFFFFF',
    [Prop.ELEMENT_TXT]: '#3F3F3F',
    [Prop.BORDER]: '#3F3F3F',
    [Prop.ICON]: '#FFFFFF',
    [Prop.ICON_HOVER]: '#3F3F3F',
  },
  [ColorProfiles.SECONDARY]: {
    [Prop.BACKGROUND]: '#FFFFFF',
    [Prop.HEADER]: '#3F3F3F',
    [Prop.CONTENT]: '#3F3F3F',
    [Prop.LINK_TXT]: '#009bd1',
    [Prop.LINK_TXT_HOVER]: '#009bd1',
    [Prop.BTN_BG]: '#009bd1',
    [Prop.BTN_TXT]: '#FFFFFF',
    [Prop.BTN_BG_HOVER]: '#3F3F3F',
    [Prop.BTN_TXT_HOVER]: '#ffffff',
    [Prop.BTN_BG_ACTIVE]: '#009bd1',
    [Prop.BTN_TXT_ACTIVE]: '#ffffff',
    [Prop.BTN_BG_INACTIVE]: '#3F3F3F',
    [Prop.BTN_TXT_INACTIVE]: '#ffffff',
    [Prop.ELEMENT]: '#009bd1',
    [Prop.ELEMENT_TXT]: '#FFFFFF',
    [Prop.BORDER]: '#3F3F3F',
    [Prop.ICON]: '#009bd1',
    [Prop.ICON_HOVER]: '#3f3f3f',
  },
  [ColorProfiles.TERTIARY]: {
    [Prop.BACKGROUND]: '#B3B3B3',
    [Prop.HEADER]: '#3F3F3F',
    [Prop.CONTENT]: '#3F3F3F',
    [Prop.LINK_TXT]: '#009bd1',
    [Prop.LINK_TXT_HOVER]: '#009bd1',
    [Prop.BTN_BG]: '#FFFFFF',
    [Prop.BTN_TXT]: '#009bd1',
    [Prop.BTN_BG_HOVER]: '#3F3F3F',
    [Prop.BTN_TXT_HOVER]: '#FFFFFF',
    [Prop.BTN_BG_ACTIVE]: '#009bd1',
    [Prop.BTN_TXT_ACTIVE]: '#FFFFFF',
    [Prop.BTN_BG_INACTIVE]: '#3f3f3f',
    [Prop.BTN_TXT_INACTIVE]: '#FFFFFF',
    [Prop.ELEMENT]: '#009bd1',
    [Prop.ELEMENT_TXT]: '#FFFFFF',
    [Prop.BORDER]: '#3f3f3f',
    [Prop.ICON]: '#FFFFFF',
    [Prop.ICON_HOVER]: '#e8f5fa',
  },
  [ColorProfiles.QUATERNARY]: {
    [Prop.BACKGROUND]: '#FFFFFF',
    [Prop.HEADER]: '#3F3F3F',
    [Prop.CONTENT]: '#3F3F3F',
    [Prop.LINK_TXT]: '#009bd1',
    [Prop.LINK_TXT_HOVER]: '#009bd1',
    [Prop.BTN_BG]: '#009bd1',
    [Prop.BTN_TXT]: '#FFFFFF',
    [Prop.BTN_BG_HOVER]: '#3F3F3F',
    [Prop.BTN_TXT_HOVER]: '#ffffff',
    [Prop.BTN_BG_ACTIVE]: '#009bd1',
    [Prop.BTN_TXT_ACTIVE]: '#ffffff',
    [Prop.BTN_BG_INACTIVE]: '#3F3F3F',
    [Prop.BTN_TXT_INACTIVE]: '#ffffff',
    [Prop.ELEMENT]: '#009bd1',
    [Prop.ELEMENT_TXT]: '#FFFFFF',
    [Prop.BORDER]: '#3F3F3F',
    [Prop.ICON]: '#009bd1',
    [Prop.ICON_HOVER]: '#3f3f3f',
  },
  [Components.HEADER]: {
    [Prop.LOGO_BG]: '#FFFFFF',
    [Prop.NAV_BG]: '#FFFFFF',
    [Prop.NAV_ITEM_TXT_TRANSLUCENT]: '#FFFFFF',
    [Prop.NAV_ITEM_TXT_SOLID]: '#3F3F3F',
    [Prop.NAV_ITEM_TXT_ACTIVE]: '#FFFFFF',
    [Prop.NAV_ITEM_BG_ACTIVE]: '#009bd1',
    [Prop.NAV_ITEM_ACTIVE_ACCENT]: '#009bd1',
    [Prop.WW_ICON_COLOR]: '#FFD200',
  },
  [Components.MOBILE_NAV]: {
    [Prop.MENU_BTN_BG]: '#FFFFFF',
    [Prop.MENU_BTN_ICON]: '#009bd1',
    [Prop.LOGO_BG]: '#FFFFFF',
    [Prop.CLOSE_BTN_BG]: '#009bd1',
    [Prop.CLOSE_BTN_ICON]: '#FFFFFF',
    [Prop.MAIN_NAV_BG]: '#FFFFFF',
    [Prop.MAIN_NAV_TXT]: '#3F3F3F',
    [Prop.MAIN_NAV_BORDER]: '#3F3F3F',
    [Prop.WW_ICON_COLOR]: '#FFD200',
    [Prop.DRAWER_BTN_BG]: '#FFFFFF',
    [Prop.DRAWER_BTN_TXT]: '#3F3F3F',
    [Prop.PROMO_BTN_BG]: '#FFFFFF',
    [Prop.PROMO_BTN_TXT]: '#3F3F3F',
    [Prop.SUB_NAV_BG]: '#009bd1',
    [Prop.SUB_NAV_TXT]: '#FFFFFF',
    [Prop.SUB_NAV_BORDER]: '#3F3F3F',
  },
};
