/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { ColorProfiles } from '@powdr/constants';

import { HotspotImage } from './components';
import {
  ActiveHotspotImageContainer, HotspotImageSelector, HotspotImageSelectors, StyledHotspotGroup,
} from './styles';

export const HotspotGroup = ({
  parentColorProfile,
  relationships,
}) => {
  const { hotspotImages } = relationships;
  const [activeGroupIndex, setActiveGroupIndex] = useState(0);
  const [modalActive, setModalActive] = useState(false);
  const colorProfile = parentColorProfile || ColorProfiles.BASE;

  const selectionHandler = (idx) => {
    setActiveGroupIndex(idx);
    setModalActive(false);
  };

  return (
    <StyledHotspotGroup colorProfile={colorProfile}>
      <HotspotImageSelectors>
        {hotspotImages.map((hotspotImage, idx) => (
          <HotspotImageSelector
            key={hotspotImage.id}
            colorProfile={colorProfile}
            onClick={() => selectionHandler(idx)}
            active={idx === activeGroupIndex}
          >
            {hotspotImage.title}
          </HotspotImageSelector>
        ))}
      </HotspotImageSelectors>
      <ActiveHotspotImageContainer>
        <HotspotImage
          {...hotspotImages[activeGroupIndex]}
          isActive={modalActive}
          modalStateChange={setModalActive}
          colorProfile={colorProfile}
        />
      </ActiveHotspotImageContainer>
    </StyledHotspotGroup>
  );
};

HotspotGroup.propTypes = {
  parentColorProfile: PropTypes.string,
  relationships: PropTypes.shape().isRequired,
};

HotspotGroup.defaultProps = {
  parentColorProfile: null,
};
