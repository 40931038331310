import PropTypes from 'prop-types';
import React from 'react';

import { StyledButton } from './styles';

export const Button = ({
  children,
  className,
  colorProfile,
  colors,
  disabled,
  icon,
  onClick,
}) => (
  <StyledButton
    className={className}
    $colors={colors}
    $colorProfile={colorProfile}
    disabled={disabled}
    onClick={disabled ? () => {} : onClick}
    type="button"
  >
    {icon && icon}
    {children}
  </StyledButton>
);

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  colorProfile: PropTypes.number,
  colors: PropTypes.instanceOf(Object),
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  children: null,
  className: '',
  colorProfile: null,
  colors: null,
  disabled: false,
  icon: null,
  onClick: () => {},
};
