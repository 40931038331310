// eslint-disable-next-line import/no-unresolved
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Icon } from '@powdr/components';
import { getVideoLink } from '@powdr/utils';

import {
  StyledUiEmbed, AlignmentContainer, BlurBackground,
  CloseButton, PlayButton,
} from './styles';

export const UiEmbed = ({
  videoId,
  videoLink,
}) => {
  const [isVideoOpen, setVideoOpen] = useState(false);
  const embedLink = getVideoLink(videoLink, videoId, false);

  return (
    <StyledUiEmbed className="ui-embed fit">
      <AlignmentContainer>
        {(isVideoOpen) && (
        <div className="fit">
          <BlurBackground />
          <iframe title="embed-video" className="fit" src={embedLink} allow="autoplay" allowFullScreen />
        </div>
        )}
        {(!isVideoOpen) && (
        <PlayButton onClick={() => setVideoOpen(true)}>
          <Icon name="content-play" />
        </PlayButton>
        )}
        {(isVideoOpen) && (
        <CloseButton onClick={() => setVideoOpen(false)}>
          <Icon className="close-button-icon" name="ui-close" width="15" height="15" />
        </CloseButton>
        )}
      </AlignmentContainer>
    </StyledUiEmbed>
  );
};

UiEmbed.propTypes = {
  videoId: PropTypes.string,
  videoLink: PropTypes.string,
};

UiEmbed.defaultProps = {
  videoId: '',
  videoLink: '',
};
