// eslint-disable-next-line import/no-unresolved
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { ContentCarousel, UiEmbed } from '@powdr/components';

import {
  StyledSlider, SlideItem, SlideImageStatic, SlideImageDynamic,
} from './styles';

export const Slider = ({
  // isThumbnailsEnabled, // this field exists in CMS but Angular code doesn't use it :shrug:
  relationships,
  parentColorProfile,
}) => {
  const { slides } = relationships;
  const slidesAdjusted = (slides.length === 2) ? slides.concat(slides) : slides;

  const SlideMap = () => useMemo(() => slidesAdjusted.map((item) => {
    const {
      embeddedVideoLink, relationships: itemRelationships, staticImage, id,
    } = item;
    const image = itemRelationships?.primaryImage?.relationships?.image?.gatsbyImage
                  || null;

    return (
      <SlideItem key={id}>
        {(embeddedVideoLink) && <UiEmbed videoLink={embeddedVideoLink} />}
        {(image) && <SlideImageDynamic image={image} alt="" />}
        {(!image && staticImage) && <SlideImageStatic src={staticImage} alt="" />}
      </SlideItem>
    );
  }), []);

  return (
    <StyledSlider className="slider">
      {(slides.length > 1)
        ? (
          <ContentCarousel
            colorProfile={parentColorProfile}
            isThumbnails
            isAutoRotate={false}
          >
            {SlideMap()}
          </ContentCarousel>
        ) : (
          SlideMap()
        )}
    </StyledSlider>
  );
};

Slider.propTypes = {
  relationships: PropTypes.shape().isRequired,
  parentColorProfile: PropTypes.string.isRequired,
};

Slider.defaultProps = {

};
