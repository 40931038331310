/* eslint-disable no-useless-catch */
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import styled from 'styled-components';

import { icons } from './icons';

const StyledIcon = styled.div`
  width: ${({ width }) => (width ? `${(width === 'fit-content') ? `${width}` : `${width}px`}` : 'auto')};
  height: ${({ height }) => (height ? `${(height === 'fit-content') ? `${height}` : `${height}px`}` : 'auto')};
  min-width: ${({ width }) => (width ? `${(width === 'fit-content') ? `${width}` : `${width}px`}` : '')};

  svg {
    fill: ${({ color }) => color ?? 'white'};
    display: block;
    width: 100%;
    height: 100%;
  }
`;

export const Icon = ({
  className, name, width, height, color,
}) => {
  const IconComp = icons[name];
  const elementRef = useRef();

  if (!IconComp) return null;

  return (
    <StyledIcon
      className={className}
      color={color}
      width={width}
      height={height}
      ref={elementRef}
    >
      <IconComp />
    </StyledIcon>
  );
};

Icon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
};

Icon.defaultProps = {
  className: '',
  width: null,
  height: null,
  color: null,
  name: 'unknown',
};
