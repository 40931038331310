import styled, { css } from 'styled-components';

import { Link } from '@powdr/components';
import { Components, Breakpoints, ThemeProps as Prop } from '@powdr/constants';
import { componentColor } from '@powdr/utils';

const CPNT = Components.FOOTER_NAV;

export const StyledFooterNav = styled.div`
  min-height: 110px;
  width: 100%;
`;

export const LinkList = styled.ul`
  text-align: left;
`;

export const LinkItem = styled.li`
  display: block;
  line-height: 1.5;
`;

export const FooterLink = styled(Link)`
  display: block;
  font-size: ${({ theme }) => theme.FontSize.REG30};
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  color: ${() => componentColor(Prop.LINK_TXT, CPNT)};
  transition: all 0.15s ease-in-out;

  &:hover, &:active {
    color: ${() => componentColor(Prop.LINK_TXT_HOVER, CPNT)};
  }

  ${Breakpoints.large(css`
    font-size: ${({ theme }) => theme.FontSize.REG40};
  `)}

  ${Breakpoints.larger(css`
    font-size: ${({ theme }) => theme.FontSize.REG50} ;
  `)}
`;
