import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useContext, useMemo } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useDispatch, useSelector } from 'react-redux';

import {
  Loader, ContentCarousel, Icon,
} from '@powdr/components';
import { Symbols } from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { getWeather } from '@powdr/stores/actions/weather-action';
import { formatDate, getSnowfallMeasurementUnit } from '@powdr/utils';
import { useStaticData } from '@powdr/web/src/hooks';

import { StyledDorWeather } from './styles';

export const DorWeather = ({
  title,
  html,
  parentColorProfile,
  isParentNavFeed,
  isStaticForecast,
  staticItems,
}) => {
  const { property } = useStaticData();
  const dispatch = useDispatch();
  const useWeatherData = useSelector((state) => state.weather);
  const { error, data } = useWeatherData;
  const { isMobile } = useContext(AppContext);
  const measurementUnit = getSnowfallMeasurementUnit();

  useEffect(() => {
    dispatch(getWeather());
  }, [dispatch]);

  if (!isStaticForecast && error) {
    console.error('DOR Weather API Failed:', error.message);
  }

  const SliderMap = (items) => useMemo(() => items?.map((item) => (
    <div
      key={`${item.day.short}-${Math.random()}`}
      className={classNames('slides', {
        current: formatDate(new Date()).day.num === item.day.num,
      })}
    >
      <Icon className="icon" name={item.icon} width="32" height="32" />
      <div className="temperature">
        {`${item.temp.min} / ${item.temp.max}${Symbols.DEGREE}`}
      </div>

      {item?.snow && (
      <div className="forecast-short">
        {`${item.snow.min}${measurementUnit} / ${item.snow.max}${measurementUnit}`}
      </div>
      )}

      {item.forecast.short && (
      <div className="forecast-short">{item.forecast.short}</div>
      )}

      {item.day.short && (
      <div className="time-of-day">{item.day.short}</div>
      )}
    </div>
  )), [items]);

  return (
    <StyledDorWeather
      colorProfile={parentColorProfile}
      className={classNames('dor-weather', { feed: isParentNavFeed })}
      property={property}
      isParentNavFeed={isParentNavFeed}
    >
      <>
        {(!isParentNavFeed && (title || html)) && (
          <div className="dw-header">
            {title && (
            <h3>{title}</h3>
            )}
            {html && ReactHtmlParser(html)}
          </div>
        )}
        <div className="dw-status">
          {isParentNavFeed || isMobile ? (
            <ContentCarousel
              isArrowsInSlide
              isHideArrowsInSlideBackground
              isInfiniteScroll={false}
              slidesToShow={isMobile ? 2 : 3}
              colorProfile={parentColorProfile}
            >
              {SliderMap((!isStaticForecast) ? data?.dorWeather || [] : staticItems)}
            </ContentCarousel>
          ) : SliderMap((!isStaticForecast) ? data?.dorWeather || [] : staticItems)}
        </div>
      </>
    </StyledDorWeather>
  );
};

DorWeather.propTypes = {
  title: PropTypes.string,
  html: PropTypes.string,
  isParentNavFeed: PropTypes.bool,
  parentColorProfile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  isStaticForecast: PropTypes.bool,
  staticItems: PropTypes.arrayOf(),
};

DorWeather.defaultProps = {
  title: null,
  html: null,
  isParentNavFeed: false,
  parentColorProfile: null,
  isStaticForecast: false,
  staticItems: null,
};
