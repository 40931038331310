import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Icon } from '@powdr/components/icon';
import { Link } from '@powdr/components/link';
import { BlockTypes, ThemeProps as Prop } from '@powdr/constants';
import { useWindowResize } from '@powdr/hooks';
import { ContentBlockType } from '@powdr/model';
import {
  themeUtil, getBackgroundImageSrc, rgbaTransparencyToHex, clamp,
} from '@powdr/utils';

const CPNT = BlockTypes.FULL;

// styled components
const ContentBlockFull = styled(Link)`
  display: block;
  position: relative;
  width: 100%;
  background-image: ${({ bgImage }) => `url('${bgImage}')`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .cb-title {
    ${() => clamp(2)}
    margin-bottom: 15px;
  }

  .cb-copy {
    ${() => clamp(3)}
  }

  .full-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 330px;
    overflow: hidden;

    &:hover:not(.overlay) {
      &:before {
        transform: translateY(0);
      }
    }

    &:after {
      content: '';
      background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile) + rgbaTransparencyToHex(0.8)};
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &:before {
      content: '';
      background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: all .3s ease-in-out;
      transform: translateY(100%);
    }

    .cb-content {
      text-align: left;
      width: 100%;
      background: transparent;
      padding: 40px;
      position: relative;
      z-index: 1;

      .cb-icon {
        margin-bottom: 20px;

        svg {
          fill: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
        }
      }

      .cb-title, .cb-copy {
        max-width: 100%;
      }

      .button {
        margin-top: 20px;
      }

    }
  }
`;

export const Full = ({
  title, copy, isOverlayEnabled, image, colorProfile, blockType, columnOption, ariaLabel,
  buttonEnabled, buttonText, buttonLink, buttonTarget, buttonClick,
  iconName, isStaticImageLink,
}) => {
  const [bgImage, setBgImage] = useState('');
  const resize = useWindowResize();

  useEffect(() => {
    if (resize.windowWidth) {
      setBgImage(
        (!isStaticImageLink)
          ? getBackgroundImageSrc(image?.src, resize.windowWidth, columnOption)
          : image.src,
      );
    }
  }, [resize.windowWidth]);

  return (
    <ContentBlockFull
      href={buttonLink}
      target={buttonTarget}
      colorProfile={colorProfile}
      bgImage={bgImage}
      onClick={() => buttonClick()}
      aria-label={ariaLabel}
      role="button"
      className={`content-block-${blockType} `}
    >
      <div className={classNames('full-container', { overlay: isOverlayEnabled })}>
        <div className="cb-content">
          {(iconName) && <Icon className="cb-icon" name={iconName} width="40" height="40" />}
          {(title) && <h3 className="cb-title">{title}</h3>}
          {(copy) && <p className="cb-copy">{copy}</p>}
          {(buttonEnabled && buttonText) && (
            <button type="button" className="button with-background uppercase" aria-label={ariaLabel}>{buttonText}</button>
          )}
        </div>
      </div>
    </ContentBlockFull>
  );
};

Full.propTypes = PropTypes.objectOf(ContentBlockType).isRequired;
