import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
  useRef, useEffect, useState, useContext, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryParams, encodeDelimitedArray, decodeDelimitedArray } from 'use-query-params';

import {
  Loader, Icon, ContentBlock, UiAccordion, UiAccordionPanel, UiDrawer,
} from '@powdr/components';
import { AppContext } from '@powdr/context';
import { useIsInView } from '@powdr/hooks';
import { getViewAll } from '@powdr/stores/actions/drupal-action';
import {
  slugify, camalize, camalizeToTitle, getUniqueListBy,
  getArrayIntersection, aliasContentBlockDataFromViews, textFilter,
} from '@powdr/utils';

import {
  StyledProducts,
  StyledCheckboxGroup,
  StyledProductTags,
  StyledGrid,
  StyledLayoutMain,
  StyledLayoutAside,
  StyledLayoutRow,
  StyledDrawer,
} from './styles';

const CommaArrayParam = {
  encode: (array) => encodeDelimitedArray(array, ','),
  decode: (array) => decodeDelimitedArray(array, ','),
};

export const FilterType = {
  AND_OR: 'andor',
  OR: 'or',
  AND: 'and',
};

const CheckboxGroup = ({
  id,
  className,
  inputs,
  checked,
  layout,
  handleFiltersOnChange,
}) => (
  <StyledCheckboxGroup key={id} id={id} className={`layout-${layout}`}>
    <ul key={`ul-${id}`} id={`ul-${id}`} className={classNames('checkboxes', className)}>
      {inputs && inputs
        .map((field) => (
          <li key={`li-${slugify(field.label)}`} className="checkbox">
            <label
              key={`label-${slugify(field.label)}`}
              htmlFor={field.id}
              className="input-checkbox"
            >
              <input
                key={`input-${slugify(field.id)}`}
                id={field.id}
                name={field.id}
                onChange={(e) => handleFiltersOnChange(e)}
                checked={checked?.includes(field.id) || false}
                type="checkbox"
                value={field.id}
                data-group-id={field.groupId}
              />
              {textFilter(field.label)}
            </label>
          </li>
        ))}
    </ul>
  </StyledCheckboxGroup>
);

CheckboxGroup.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      groupId: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  checked: PropTypes.arrayOf(PropTypes.string),
  layout: PropTypes.string,
  handleFiltersOnChange: PropTypes.func,
};

CheckboxGroup.defaultProps = {
  id: null,
  className: null,
  inputs: [],
  checked: null,
  layout: 'row',
  handleFiltersOnChange: null,
};

const preFix = {
  FILTER: 'field_filter_',
  FIELD: 'field_',
};

export const ProductFinder = ({
  parentColorProfile,
  id,
  title,
  blockType,
  isGrouped,
  columnOption,
  filterType,
  entityView,
  relationships,
}) => {
  const {
    toggleBodyScroll, isMobile,
    secondLevelNavHeight, fullHeaderHeight,
  } = useContext(AppContext);
  const dispatch = useDispatch();
  const [content] = relationships.content;
  const queryTypes = content.relationships.productGroups
    ?.filter((f) => f.name !== undefined)
    ?.map((p) => ({ [camalize(p?.name?.replace(preFix.FILTER, ''))]: CommaArrayParam })) || {};
  const [urlQueryParams, setUrlQueryParams] = useQueryParams(Object.assign(...queryTypes));
  const drawerStickyOffset = fullHeaderHeight + secondLevelNavHeight;
  const useProductData = useSelector((state) => state.drupal);
  const {
    isLoading: isProductLoading,
    error: hasProductError,
    drupal: productsData,
  } = useProductData;
  const inViewThresholdPercentage = 0.07;

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [filterGrp, setFilterGrp] = useState([]);
  const productRef = useRef();
  const isInView = useIsInView(productRef, 0, inViewThresholdPercentage);

  const products = useMemo(() => {
    const [cont] = relationships.content;
    const prd = productsData[entityView.viewMachineName];

    // Remove Field Prefix to match ContentBlock, add label, name, id
    const prod = prd?.map((obj) => {
      const props = aliasContentBlockDataFromViews(obj);
      const tags = Object.entries(obj)
        ?.filter(([k, v]) => k.match(preFix.FILTER) && v !== '')
        ?.map(([, v]) => v.split(', ')
          .map((m) => camalize(m))).flat();
      return Object.assign(...[
        props,
        { tags },
      ]);
    }) || [];

    // Get all possible filters to create
    // checkboxes under filter groups for products
    const tagsKv = prd?.map((obj) => Object.entries(obj)
      ?.filter(([k, v]) => k.match(preFix.FILTER) && v !== '')
      ?.map(([k, v]) => ({
        [camalize(k?.replace(preFix.FILTER, ''))]: v.split(', ')
          .map((m) => ({
            label: m,
            id: camalize(m),
          })),
      }))).flat() || [];

    // Remove Field Prefix to match ContentBlock, add label, name, id
    const filtersGrps = cont.relationships.productGroups
      ?.filter((f) => f.name !== undefined)
      ?.map((p) => {
        const key = camalize(p?.name?.replace(preFix.FILTER, ''));
        const inputs = tagsKv
          .filter((f) => f[key])
          .map((m) => m[key]).flat()
          .map((m) => (
            {
              groupId: key,
              ...m,
            }
          ));

        return {
          ...p,
          name: key,
          inputs: getUniqueListBy(inputs, 'id'),
        };
      }) || [];

    setFilterGrp(filtersGrps);

    return prod;
  }, [productsData, entityView.viewMachineName, relationships.content]);

  useEffect(() => {
    dispatch(getViewAll(entityView.viewMachineName));
  }, [dispatch, entityView.viewMachineName]);

  const getIds = (prd, ids) => prd
    ?.filter((f) => f.tags
      .some((e) => ids?.includes(e)))
    ?.map((m) => m.id) || [];

  const productIds = useMemo(() => {
    let prodIds = [];
    const queryIds = Object.values(urlQueryParams)
      ?.map((v) => v) // return ids
      ?.filter((f) => f !== undefined); // filter out undefined

    if (products.length > 0 && queryIds.flat().length > 0 && filterType === FilterType.OR) {
      prodIds = getIds(products, queryIds.flat());
    }

    if (products.length > 0 && queryIds.length > 0 && filterType === FilterType.AND_OR) {
      prodIds = queryIds
        ?.map((m) => getIds(products, m))
        ?.filter((f) => f.length > 0); // filter out empty arrays

      prodIds = [...prodIds].length > 1
        ? getArrayIntersection(...prodIds)
        : getIds(products, queryIds.flat());
    }

    return prodIds;
  }, [urlQueryParams, products, filterType]);

  const handleCheckboxClick = (e) => {
    const { value, checked } = e.target;
    const propId = e.target.dataset.groupId;

    setUrlQueryParams((prevState) => ({
      [propId]: checked
        ? [...prevState[propId] || [], value]
        : prevState[propId]?.filter((v) => v !== value) || [],
    }));
  };

  const handleTagClick = (propId, value) => {
    setUrlQueryParams((prevState) => ({
      [propId]: prevState[propId]?.filter((v) => v !== value) || [],
    }));
  };

  const handleClearClick = () => {
    setUrlQueryParams(Object.keys(urlQueryParams)
      .reduce((a, b) => {
        a[b] = undefined;
        return a;
      }, {}));
  };

  // ERRORS START
  if (hasProductError) {
    console.error('DRUPAL View API Failed:', hasProductError.message);
    return null;
  }
  // ERRORS END

  const onDrawerOpen = () => {
    setIsDrawerOpen(true);
    toggleBodyScroll(true);
  };

  const onDrawerClose = () => {
    setIsDrawerOpen(false);
    toggleBodyScroll(false);
  };

  // TODO: the drawer/accordion/aside components could use a little refactor love
  return (
    <StyledProducts colorProfile={parentColorProfile}>
      {isProductLoading ? (
        <Loader
          className="icon"
          classAnimationEffect="loader-animated spin infinite"
          iconName="dor-sunny"
          size="30"
        />
      ) : (
        <StyledLayoutRow>
          {(isMobile && isInView) && (
            <StyledDrawer
              colorProfile={parentColorProfile}
              top={drawerStickyOffset}
            >
              <UiDrawer
                isDrawerOpen={isDrawerOpen}
                isDrawerEnabled
                handleDrawOpen={onDrawerOpen}
                handleCanvasClose={onDrawerClose}
                drawerOffsetTop={`${drawerStickyOffset - 65}`}
                drawerWidth={310}
              >
                <UiAccordion>
                  {filterGrp.map((grp, i) => (grp.inputs.length > 0 && (
                    <UiAccordionPanel
                      panelId={i}
                      label={grp.label}
                      key={grp.label}
                    >
                      <CheckboxGroup
                        id={grp.id}
                        className="animated fadeInUp u-an-de-0"
                        layout="list"
                        inputs={grp.inputs}
                        checked={urlQueryParams[grp.name]}
                        handleFiltersOnChange={(e) => handleCheckboxClick(e)}
                      />
                    </UiAccordionPanel>
                  )
                  ))}
                </UiAccordion>
                <button
                  type="button"
                  className="btn-clear btn"
                  onClick={(e) => handleClearClick(e)}
                >
                  Clear Filter
                </button>
              </UiDrawer>
            </StyledDrawer>
          )}
          {(!isMobile) && (
            <StyledLayoutAside>
              <UiAccordion>
                {filterGrp.map((grp, i) => (grp.inputs.length > 0 && (
                  <UiAccordionPanel
                    panelId={i}
                    label={grp.label}
                    key={grp.label}
                  >
                    <CheckboxGroup
                      id={grp.id}
                      className="animated fadeInUp u-an-de-0"
                      layout="list"
                      inputs={grp.inputs}
                      checked={urlQueryParams[grp.name]}
                      handleFiltersOnChange={(e) => handleCheckboxClick(e)}
                    />
                  </UiAccordionPanel>
                )
                ))}
              </UiAccordion>
              <button
                type="button"
                className="btn-clear btn"
                onClick={(e) => handleClearClick(e)}
              >
                Clear Filter
              </button>
            </StyledLayoutAside>
          )}
          <StyledLayoutMain ref={productRef}>
            <StyledProducts
              colorProfile={parentColorProfile}
              className="products"
              top={drawerStickyOffset}
            >
              {title && (
                <header>
                  <h3>{title}</h3>
                  <p>

                    {productIds?.length > 0 ? productIds.length : products.length}
                    {' '}
                    Products
                  </p>
                </header>
              )}

              <StyledProductTags>
                {Object.entries(urlQueryParams)
                  .filter((key, val) => val !== undefined)
                  .map(([key, val]) => (
                    val?.map((v) => (
                      <button
                        className="btn"
                        key={v}
                        type="button"
                        data-group-id={key}
                        onClick={() => handleTagClick(key, v)}
                      >
                        {camalizeToTitle(v)}
                        <Icon
                          name="content-info-close"
                          width="16"
                          height="16"
                          className="icon"
                        />
                      </button>
                    )) || []
                  ))}
              </StyledProductTags>
              <StyledGrid columns={columnOption}>
                {products
                  ?.filter((f) => (productIds?.length > 0 ? productIds.includes(f.id) : true))
                  ?.map((prd, index) => {
                    const { id: blockId, viewImage } = prd;
                    return (
                      <ContentBlock
                        viewImage={viewImage}
                        className="content-block"
                        contentBlock={prd}
                        key={blockId}
                        index={index}
                        blockType={blockType}
                        colorProfile={parentColorProfile}
                      />
                    );
                  }) || []}
              </StyledGrid>
            </StyledProducts>
          </StyledLayoutMain>
        </StyledLayoutRow>
      )}
    </StyledProducts>
  );
};

ProductFinder.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  blockType: PropTypes.string,
  isGrouped: PropTypes.bool,
  columnOption: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  filterType: PropTypes.oneOf([
    FilterType.AND_OR,
    FilterType.OR,
    FilterType.AND,
  ]),
  entityView: PropTypes.shape({
    viewMachineName: PropTypes.string,
  }),
  relationships: PropTypes.shape(),
  parentColorProfile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

ProductFinder.defaultProps = {
  id: null,
  title: null,
  blockType: null,
  isGrouped: null,
  columnOption: null,
  filterType: FilterType.AND_OR,
  entityView: null,
  relationships: null,
  parentColorProfile: null,
};
