/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';

import { Components, ThemeProps as Prop } from '@powdr/constants';
import { rgbaTransparencyToHex, themeUtil } from '@powdr/utils';

const CPNT = Components.CHECKBOX;

const chipShared = css`
  label {
    border-radius: 10px;
    padding: 10px 30px 10px 8px;
    height: 40px;
    font-size: ${({ theme }) => theme.FontSize.REG30};

    input[type='checkbox']:not(:checked) {
      display: none;
    }
    input[type='checkbox'],
    input[type='checkbox']:after,
    input[type='checkbox']:before {
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
      border-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
      left: auto;
      top: auto;
      position: absolute;
      width: 25px;
      height: 20px;
    }
    input[type='checkbox']:checked:after {
      border-color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
    }
    .label {
      padding-left: 25px;
    }
  }
`;

const chipUnchecked = css`
  label {
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile) + rgbaTransparencyToHex(0.25)};
    color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
  }
`;

const chipChecked = css`
  label {
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
    font-weight: bold;
    input[type='checkbox'] {
      margin: 0 2px 0 0
    }
    input[type='checkbox']:checked:after {
      border-width: 3.5px;
      width: 18px;
      height: 10px;
      left: auto;
      top: auto;
    }
  }
`;

const nonChipsShared = css`
  &:hover {
    input[type='checkbox']:before {
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_ACTIVE, CPNT, colorProfile)};
    }
  }

  input[type='checkbox']:checked:before {
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_ACTIVE, CPNT, colorProfile)};
  }
`;

export const StyledCheckbox = styled.div`
  label {
    align-items: center;
    justify-items: center;
    cursor: pointer;
    display: flex;
    position: relative;
    ${({ theme }) => theme.fonts.PRI_BDY_MED};
    font-size: ${({ theme }) => theme.FontSize.REG50};
    color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
    text-transform: uppercase;

    input[type='checkbox'],
    input[type='checkbox']:after,
    input[type='checkbox']:before {
      content: none;
      overflow: hidden;
      width: 20px;
      height: 20px;
      margin-right: 10px;
      outline: none;
    }

    input[type='checkbox']:after,
    input[type='checkbox']:before {
      content: "";
      cursor: pointer;
      top: 3px;
      left: 3px;
      position: absolute;
      border: 1px solid;
      border-radius: 4px;
      border-color: ${({ colorProfile }) => themeUtil(Prop.BORDER, CPNT, colorProfile)};
      margin-right: 7px;
      display: block;
    }

    input[type='checkbox']:before {
      background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};
    }

    input[type='checkbox']:checked:after {
      border-color: ${({ colorProfile }) => themeUtil(Prop.BORDER, CPNT, colorProfile)};
      color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_ACTIVE, CPNT, colorProfile)};
      transform: rotate(-57deg);
      border: 2.5px solid;
      width: 9px;
      height: 5px;
      top: 9px;
      left: 8px;
      border-top: none;
      border-right: none;
      border-radius: 0;
    }
  }

  ${({ isChips }) => (isChips ? chipShared : nonChipsShared)}
  ${({ isChips, isChecked }) => (isChips
    ? isChecked
      ? chipChecked
      : chipUnchecked
    : null)}
`;
