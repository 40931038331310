import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { Icon } from '@powdr/components/icon';
import { Link } from '@powdr/components/link';
import {
  AlternatingBlockLayoutOptions, BlockTypes, Breakpoints, ThemeProps as Prop,
} from '@powdr/constants';
import { useWindowResize } from '@powdr/hooks';
import { ContentBlockType } from '@powdr/model';
import { themeUtil, getBackgroundImageSrc } from '@powdr/utils';

const CPNT = BlockTypes.PRICE_SPLIT;

// styled components
const ContentBlockPriceSplit = styled.div`
  display: flex;
  flex-direction: ${({ alternateBlockLayoutState }) => (
    (alternateBlockLayoutState === AlternatingBlockLayoutOptions.DEFAULT)
      ? 'row'
      : 'row-reverse'
  )};
  justify-content: normal;
  box-shadow: 0 0 4px 4px rgba(41, 45, 49, 0.1), 0 0 4px 2px rgba(41, 45, 49, 0.1);
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 3px;

  ${Breakpoints.mobile(css`
    display: block;
    min-height: 600px;
    overflow: hidden;
  `)};
`;

const Image = styled.div`
  display: block;
  width: 230px;
  flex: 0 0 230px;
  background-image: ${({ bgImage }) => `url('${bgImage}')`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  ${Breakpoints.mobile(css`
    width: 100%;
    height: 290px;
  `)}
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 100%;
  position: relative;
  z-index: 1;
  text-align: left;
  padding: 15px;
  min-height: 235px;
  overflow: visible;
  gap: 15px;

  strong {
    font-weight: bold;
  }

  p {
    overflow: hidden;
    ${({ theme }) => theme.fonts.PRI_BDY_MED};
    font-size: 18px;
    line-height: 1.1;
  }

  h3 {
    margin-bottom: 10px;
    font-size: ${({ theme }) => theme.FontSize.MED30};

    span.subtext {
      font-size: 14px;
      margin-bottom: 10px;
      display: block;
      text-transform: capitalize;
    }
  }

  ${Breakpoints.mobile(css`
    min-height: auto;
    height: auto;
    padding: 15px;
    text-align: center;
  `)}
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 230px;
  flex: 0 0 230px;
  text-align: left;
  padding: 15px;
  min-height: 235px;
  overflow: visible;
  background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};

  ${Breakpoints.mobile(css`
    align-items: center;
    min-height: auto;
    height: auto;
    width: 100%;
    padding: 15px;
  `)}

  strong {
    font-weight: bold;
  }

  h4,
  span.from {
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 10px;
  }

  span.from {
    display: block;
    font-size: 16px;
  }

  h4 {
    font-size: 32px;
  }

  .button {
    text-align: center;
  }

  .alt-button {
    display: flex;
    justify-content: center;
    color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};
    //font-family: 'jellee', sans-serif;
    font-size: ${({ theme }) => theme.FontSize.REG40};
    margin-top: 10px;

    &:hover {
      color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};
    }
  }
`;

const LinkBanner = styled(Link)`
  display: block;
  position: absolute;
  top: 3px;
  ${({ alternateBlockLayoutState }) => (
    (alternateBlockLayoutState === AlternatingBlockLayoutOptions.DEFAULT)
      ? 'right: 3px;'
      : ''
  )};
  z-index: 1;

  &:after {
    content: '';
    position: absolute;
    display: block;
    z-index: 1;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 80px 80px 0;
    border-bottom-color: transparent;
    border-right-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
  }

  .icon {
    z-index: 2;
    position: relative;
    padding: 10px;
  }

  .cb-icon {
    svg {
      fill: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
    }
  }
`;

export const PriceSplit = ({
  title, copy, isOverlayEnabled, colorProfile, blockType, columnOption,
  buttonEnabled, buttonText, buttonLink, buttonTarget, buttonClick,
  altButtonEnabled, altButtonText, altButtonLink, ariaLabel,
  iconName, iconLink, image, isStaticImageLink,
  priceHeader, price, shortDescription, alternateBlockLayoutState,
}) => {
  const [bgImage, setBgImage] = useState('');
  const resize = useWindowResize();

  useEffect(() => {
    if (resize.windowWidth) {
      setBgImage(
        (!isStaticImageLink)
          ? getBackgroundImageSrc(image?.src, resize.windowWidth, columnOption)
          : image.src,
      );
    }
  }, [resize.windowWidth]);

  return (
    <ContentBlockPriceSplit
      className={`content-block-${blockType} `}
      bgImage={bgImage}
      colorProfile={colorProfile}
      alternateBlockLayoutState={alternateBlockLayoutState}
    >
      <Image bgImage={bgImage} />
      <ContentContainer>
        {(title) && (
          <h3 className="cb-title">
            <span className="subtext">{shortDescription}</span>
            <span className="title-text">{title}</span>
          </h3>
        )}
        {(copy) && <p className="price-copy">{copy}</p>}
      </ContentContainer>
      <PriceContainer className="cb-content cb-col-right" colorProfile={colorProfile}>
        {(price) && (
          <h4>
            {(priceHeader) && <span className="from subtext">{priceHeader}</span>}
            {price}
          </h4>
        )}
        {(buttonEnabled && buttonText && !isOverlayEnabled) && (
          <Link
            className="button uppercase with-background"
            href={buttonLink}
            target={buttonTarget}
            onClick={() => buttonClick()}
            aria-label={ariaLabel}
            role="button"
          >
            {buttonText}
          </Link>
        )}
        {(altButtonEnabled && !isOverlayEnabled) && (
          <Link className="button alt-button uppercase" href={altButtonLink}>
            {altButtonText}
          </Link>
        )}
      </PriceContainer>
      {(iconName) && (
        <LinkBanner
          className="banner"
          href={iconLink}
          alternateBlockLayoutState={alternateBlockLayoutState}
        >
          <div className="icon">
            <Icon className="cb-icon" name={iconName} width="25" height="25" />
          </div>
        </LinkBanner>
      )}
    </ContentBlockPriceSplit>
  );
};

PriceSplit.propTypes = PropTypes.objectOf(ContentBlockType).isRequired;
