export const importSVGs = (r) => {
  const svgs = {};
  // eslint-disable-next-line no-return-assign
  r.keys().forEach((key) => {
    // TODO: better solve?
    // Due to minification error in react #130 we had to manually convert file names to equivalent
    // component names eg. ./site-desktop-logo.svg becomes SiteDesktopLogo, this is unfortunate but
    // this is after 2 1/2 days of troubleshooting and trying to identify the issue. The problem
    // involves webpack, the build process, and the plugin `gatsby-plugin-react-svg`
    // eslint-disable-next-line no-useless-escape
    const componentName = key.split('/')[key.split.length - 1].slice(0, key.lastIndexOf('.') - 2).replace(/(^[\w\d]|-[\w\d])/g, (char) => char.replace(/-/, '').toUpperCase());
    svgs[componentName] = r(key);
  });

  return svgs;
};
