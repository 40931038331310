import styled from 'styled-components';

export const StyledPartners = styled.footer`
.sub-partner {
    width: 85px;
  }
  ul {
    min-height: 95px;
  }

  li.l-item {
    display: inline-block;
    &.divider-partner {
      /* @include transition(opacity 0.5s 1s);
      @include opacity(0); */
      display: inline-block;
      width: 1px; /* Line width */
      height: 40px; /* Override in-line if you want specific height. */
      vertical-align: middle;
      padding: 0 !important;
      margin: 0 16px;
      /* &.fadeIn {
        @include opacity(1);
      }
      @include bp-mobile-tablet-portrait {
        display: block !important;
        margin: 16px 10%;
        width: auto;
        height: 1px;
      } */
    }

    /* @include bp-smallest {
      padding: 16px 20px;
    } */
  }

  ul.partners {
    a {
      img {
        width: 75px !important;
      }
    }
    li.l-item {
      vertical-align: middle;
      padding: 16px 20px;
    }
  }

  ul.partners.l-group.u-bg-other.u-center-block {
    height: auto;
  }

  ul li .icon-c svg {
    height: auto;
  }
`;
