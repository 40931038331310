import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { Alignment, ColorProfiles, VerticalPosition } from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { colorProfileByIndex, globalContentParser } from '@powdr/utils';

import {
  Image, Content, ContentSection, ContentWrapper, FloatingImageContainer,
  FloatingImage, ImageWrapper, StyledFloatingImageSection,
} from './styles';

export const FloatingImageSection = ({
  colorProfile,
  contentAlignment,
  contentSizePercentage,
  content,
  desktopImageHeight,
  mobileImagePosition,
  relationships,
}) => {
  const { isMobile } = useContext(AppContext);
  const { gatsbyImage: image } = relationships?.primaryImage?.relationships?.image || {};
  return (
    <StyledFloatingImageSection
      colorProfile={colorProfileByIndex(colorProfile)}
      mobileImagePosition={mobileImagePosition || VerticalPosition.TOP}
    >
      <ContentSection contentAlignment={contentAlignment || Alignment.LEFT}>
        <ContentWrapper width={contentSizePercentage || 50}>
          {(content) && (
            <Content colorProfile={colorProfileByIndex(colorProfile)}>
              {globalContentParser(content.value)}
            </Content>
          )}
        </ContentWrapper>
      </ContentSection>
      <ImageWrapper>
        {(isMobile)
          ? <Image image={image} alt="" />
          : (
            <FloatingImageContainer>
              <FloatingImage height={desktopImageHeight || 500}>
                <Image
                  objectFit="inherit"
                  imgStyle={{
                    width: 'fit-content',
                    height: `${desktopImageHeight}px`,
                    right: (contentAlignment === Alignment.LEFT || !contentAlignment) ? 0 : 'auto',
                    left: (contentAlignment === Alignment.LEFT || !contentAlignment) ? 'auto' : 0,
                  }}
                  image={image}
                  alt=""
                  height={desktopImageHeight || 500}
                />
              </FloatingImage>
            </FloatingImageContainer>
          )}
      </ImageWrapper>
    </StyledFloatingImageSection>
  );
};

FloatingImageSection.propTypes = {
  colorProfile: PropTypes.number,
  contentAlignment: PropTypes.string,
  contentSizePercentage: PropTypes.number,
  content: PropTypes.shape(
    { value: PropTypes.string },
  ),
  desktopImageHeight: PropTypes.number,
  mobileImagePosition: PropTypes.string,
  relationships: PropTypes.shape().isRequired,
};

FloatingImageSection.defaultProps = {
  colorProfile: ColorProfiles.BASE,
  contentAlignment: Alignment.LEFT,
  contentSizePercentage: 50,
  content: null,
  desktopImageHeight: 500,
  mobileImagePosition: VerticalPosition.TOP,
};
