import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Loader, Icon } from '@powdr/components';
import { getConditions } from '@powdr/stores/actions/conditions-action';
import { getSnowfallMeasurementUnit } from '@powdr/utils';
import { useStaticData } from '@powdr/web/src/hooks';

import { StyledDorSnowReport } from './styles';

export const DorSnowReport = ({
  title,
  html,
  hide,
  isParentNavFeed,
  filterBy,
  parentColorProfile,
}) => {
  const { property } = useStaticData();
  const dispatch = useDispatch();
  const useConditionsData = useSelector((state) => state.conditions);
  const { isLoading, error, data } = useConditionsData;
  const measurementUnit = getSnowfallMeasurementUnit();

  useEffect(() => {
    dispatch(getConditions());
  }, [dispatch]);

  if (error) {
    console.error('DOR Conditions API Failed:', error.message);
    return null;
  }

  const SnowReportRender = data?.dorSnowReport
    ?.filter((item) => filterBy
      .map((filterItem) => filterItem.toLowerCase())
      .includes(item.duration.toLowerCase()))
    .map((item, i) => (
      <li
        className={classNames('col', `animated fadeInUp u-an-de-${i}`)}
        key={item.duration}
      >
        <Icon className="icon" name="content-snowflake" width="20" height="20" />
        <h3 className="amount">
          {item.amount}
          {measurementUnit}
        </h3>
        <hr />
        <p className="duration">{item.duration}</p>
      </li>
    )) || [];

  return (
    <StyledDorSnowReport
      colorProfile={parentColorProfile}
      className="dor-snow-report"
      property={property}
      isParentNavFeed={isParentNavFeed}
    >
      {isLoading ? (
        <Loader
          className="icon"
          classAnimationEffect="loader-animated spin infinite"
          iconName="dor-sunny"
          size="30"
        />
      ) : (
        <ul className="dor-snow-report-grid">
          {SnowReportRender}
        </ul>
      )}
    </StyledDorSnowReport>
  );
};

DorSnowReport.propTypes = {
  filterBy: PropTypes.arrayOf(PropTypes.string),
  parentColorProfile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

DorSnowReport.defaultProps = {
  filterBy: [''],
  parentColorProfile: null,
};
