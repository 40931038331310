/* eslint-disable no-useless-escape */
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { SocialIconList } from '@powdr/components';
import { isValidEmail } from '@powdr/utils';
import { useStaticData } from '@powdr/web/src/hooks/';

import { icons } from '../icon/icons';

import {
  EmailSignupContainer, SocialIconListWrapper,
  FormContainer, SignupForm, FormLabel,
  EmailInputField, SubmitButton, CaptchaAlignmentWrapper, InputSubmitWrapper, LabelInputWrapper,
  StyledReCAPTCHA,
} from './styles';

export const EmailSignup = ({
  id,
  emailServiceName,
  formLabel,
  onCompletionRedirect,
  emailServiceUrl,
  isHideSocialButtons,
}) => {
  const [email, setEmail] = useState('');
  const [isUserVerified, setUserVerified] = useState(false);
  const {
    reCaptchaDBPropertyName, reCaptchaPublicKey, property, socialIcons,
  } = useStaticData();
  const consentDate = format(new Date(), 'MM/dd/yyyy');
  const validateEmail = () => isValidEmail(email);
  const emailUpdated = (e) => setEmail(e.target.value);

  const handleSubmit = (e) => {
    if (isUserVerified && validateEmail()) {
      localStorage.setItem('email', email);
    } else {
      e.preventDefault();
    }
  };

  // TODO: have captcha appear under input
  return (
    <EmailSignupContainer isHideSocialButtons={isHideSocialButtons}>
      <FormContainer>
        <SignupForm onSubmit={handleSubmit} name="silverpopForm" action={emailServiceUrl} method="POST">
          {(!isHideSocialButtons) && (
            <SocialIconListWrapper>
              {socialIcons.map((iconSet) => <SocialIconList key={iconSet.id} parent="email-signup" {...iconSet} />)}
            </SocialIconListWrapper>
          )}
          <LabelInputWrapper>
            <FormLabel htmlFor="field-email">{formLabel}</FormLabel>
            <CaptchaAlignmentWrapper>
              <InputSubmitWrapper>
                <EmailInputField id="field-email" name="Email" type="email" placeholder="E-mail address" onChange={emailUpdated} />
                <SubmitButton type="submit" value="Go" disabled={(!isUserVerified || !validateEmail())} />
              </InputSubmitWrapper>
              {(validateEmail()) && (
                <StyledReCAPTCHA
                  sitekey={reCaptchaPublicKey}
                  theme="light"
                  size="normal"
                  onChange={() => setUserVerified(true)}
                  onExpired={() => setUserVerified(false)}
                />
              )}
            </CaptchaAlignmentWrapper>
          </LabelInputWrapper>
          <input name="formSourceName" type="hidden" value="StandardForm" autoComplete="off" />
          <input name="sp_exp" type="hidden" value="yes" autoComplete="off" />
          <input
            name={`${property} Express Consent Date`}
            type="hidden"
            value={consentDate}
            autoComplete="off"
          />
          <input
            name={`${property} Express Consent Source`}
            type="hidden"
            value={(property === 'picomountain' ? 'Pico Email Signup' : `${reCaptchaDBPropertyName} Email Signup`)}
            autoComplete="off"
          />
        </SignupForm>
      </FormContainer>
    </EmailSignupContainer>
  );
};

EmailSignup.propTypes = {
  id: PropTypes.string.isRequired,
  emailServiceName: PropTypes.string,
  formLabel: PropTypes.string,
  onCompletionRedirect: PropTypes.string,
  emailServiceUrl: PropTypes.string,
  isHideSocialButtons: PropTypes.bool,
};

EmailSignup.defaultProps = {
  emailServiceName: '',
  emailServiceUrl: '',
  formLabel: 'Sign Up for Powdr Alerts and Deals',
  onCompletionRedirect: '#',
  isHideSocialButtons: false,
};
