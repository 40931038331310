import classNames from 'classnames';
// import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React, {
  useRef, useEffect, useState, useContext,
} from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useDispatch, useSelector } from 'react-redux';

import {
  Loader, Icon, ContentCarousel, UiAccordion, UiAccordionPanel, UiDrawer,
} from '@powdr/components';
import {
  trailFilter,
  liftFilter,
  trailLegendSummer,
  liftTableColumns,
  trailTableColumns,
  StatusTypes,
  LiftsTrails,
  LiftColumn,
  TrailColumn,
  SectorConditions,
  LiftConditions,
  StatusTrailSubTypes,
  DrawrGeneral,
  ContentFilter,
  CondTrailTypes,
  CondSectorLabels,
  CondSectorLengthLabels,
  StatusProps,
  Property,
  MediaTypes,
} from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { useIsInView } from '@powdr/hooks';
import { getConditions } from '@powdr/stores/actions/conditions-action';
import { getStatus } from '@powdr/stores/actions/status-action';
import {
  convertCmsSetToBools,
  roundIt,
  milesToKilometers,
  getNormalizedIds,
} from '@powdr/utils';
import { useStaticData } from '@powdr/web/src/hooks';

import {
  StyledDorLiftsTrails,
  StyledDorTable,
  StyledDorSector,
  StyledDorFilter,
  StyledLiftsDetails,
  StyledSectorDetails,
  StyledSectorDifficulty,
  StyledLayoutSectors,
  StyledLayoutWrapper,
  StyledLayoutFilters,
  StyledDorDrawer,
  StyledLegendTrailTypeRating,
  StyledLayoutGrid,
} from './styles';

const DorCheckboxGroup = ({
  title,
  id,
  className,
  checkInputsFields,
  checked,
  layout,
  handleFiltersOnChange,
}) => (
  <StyledDorFilter id={id} className={`layout-${layout}`}>
    {title && (<h4>{title}</h4>)}
    <form className={classNames('form', `${className}`)}>
      <ul className="checkboxes">
        {(checkInputsFields) && checkInputsFields
          .map((field) => (
            <li key={field.id} className="checkbox">
              <label
                htmlFor={field.id}
                className="input-checkbox"
              >
                <input
                  id={field.id}
                  name={field.id}
                  onChange={(e) => handleFiltersOnChange(e)}
                  type="checkbox"
                  value={field?.id}
                />
                {(field?.icons) ? field?.icons?.map((i) => (
                  <Icon
                    className={`icon ${i.css}`}
                    name={i.icon}
                    width="23"
                    height="23"
                  />
                )) : (
                  <Icon
                    className={`icon ${field.css}`}
                    name={field.icon}
                    width="23"
                    height="23"
                  />
                )}
                {field.label}
              </label>
            </li>
          ))}
      </ul>
    </form>
  </StyledDorFilter>
);

DorCheckboxGroup.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  checkInputsFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      enabled: PropTypes.bool,
      icon: PropTypes.string,
      css: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  checked: PropTypes.arrayOf(PropTypes.string),
  layout: PropTypes.string,
  handleFiltersOnChange: PropTypes.func,
};

DorCheckboxGroup.defaultProps = {
  title: null,
  id: null,
  className: null,
  checkInputsFields: [],
  checked: null,
  layout: 'row',
  handleFiltersOnChange: null,
};

export const DorLiftsTrails = ({
  parentColorProfile,
  hideFilterContent,
  hideFilterDrawer,
  hideLiftsTrails,
  hideLiftColDesktop,
  hideTrailColDesktop,
  hideTrailColMobile,
  hideTrailFeature,
  hideTrailGroomedStatus,
  hideTrailDifficulty,
  hideSectorConditions,
  hideLiftColMobile,
  hideLiftsConditions,
  hideGeneralDrawr,
  hideTrailFeatureDiff,
  hideTrailFeatureSize,
  hideTrailStatus,
  hideLiftStatus,
  filterDefaultState,
  panelsDefaultState,
  parentComponentSeason,
}) => {
  const { property } = useStaticData();
  const dispatch = useDispatch();
  const {
    isMobile,
    secondLevelNavHeight,
    themeConfig,
  } = useContext(AppContext);
  const season = parentComponentSeason || StatusProps.SEASON.ALL_SEASONS;

  const tableThStickyOffset = themeConfig.layout.headerHeight + secondLevelNavHeight + 10;
  const drawerStickyOffset = themeConfig.layout.headerHeight + secondLevelNavHeight - 50;
  const useConditionsData = useSelector((state) => state.conditions);
  const useStatusData = useSelector((state) => state.status);
  const {
    isLoading: conditionsIsLoading,
    error: conditionsError,
    data: conditionsData,
  } = useConditionsData;
  const {
    isLoading: statusIsLoading,
    error: statusError,
    data: statusData,
  } = useStatusData;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [sectors, setSectors] = useState([]);
  const [reports, setReports] = useState([]);
  const [liftConditions, setLiftConditions] = useState([]);
  const [trailConditions, setTrailConditions] = useState([]);
  const [trailIds, setTrailIds] = useState([]);
  const [types, setTypes] = useState({});
  const [currentFilters, setCurrentFilters] = useState([]);
  const [liftTrailFilter] = useState(trailFilter);
  const [expandedRows, setExpandedRows] = useState([]);
  const liftTrailContentRef = useRef();
  const isInView = useIsInView(liftTrailContentRef);

  // TODO: Refactor into arrays, Per Ians comment
  const hideLiftCol = isMobile ? hideLiftColMobile : hideLiftColDesktop;
  const cmsLiftColumn = convertCmsSetToBools(hideLiftCol, LiftColumn);
  const hideTrailCol = isMobile ? hideTrailColMobile : hideTrailColDesktop;
  const cmsTrailColumn = convertCmsSetToBools(hideTrailCol, TrailColumn);
  const cmsFilterContent = convertCmsSetToBools(hideFilterContent, ContentFilter);
  const cmsLiftsTrails = convertCmsSetToBools(hideLiftsTrails, LiftsTrails);
  const cmsSectorConditions = convertCmsSetToBools(hideSectorConditions, SectorConditions);
  // const cmsFilterDrawr = convertCmsSetToBools(hideFilterDrawer, DrawrFilter);
  const cmsLiftsConditions = convertCmsSetToBools(hideLiftsConditions, LiftConditions);
  const cmsGeneralDrawr = convertCmsSetToBools(hideGeneralDrawr, DrawrGeneral);
  const { toggleBodyScroll } = useContext(AppContext);

  const [
    statusTrailCb,
    difficultyTrailCb,
    featureDifficultyTrailCb,
  ] = liftTrailFilter;

  const [
    statusLiftCb,
  ] = liftFilter;

  const layoutTypes = {
    ROW: 'row',
    COLUMN: 'column',
    NONE: 'none',
  };

  useEffect(() => {
    dispatch(getConditions());
    dispatch(getStatus());
  }, [dispatch]);

  useEffect(() => {
    // eslint-disable-next-line no-shadow
    if (statusData) {
      const trails = statusData?.dorLiftsTrails?.types[season]
        ?.find((f) => f.id === StatusTypes.TRAIL) || {};

      const lifts = statusData?.dorLiftsTrails?.types[season]
        ?.find((f) => f.id === StatusTypes.LIFT) || {};

      const pois = statusData?.dorLiftsTrails?.types[season]
        ?.find((f) => f.id === StatusTypes.PIO) || {};

      const normalizedSettings = getNormalizedIds(hideLiftsTrails, StatusTrailSubTypes);

      const fTrail = { ...trails };

      fTrail.items = fTrail?.items
        ?.filter((f) => !normalizedSettings
          .includes(f.subtype)) || [];

      const sectorReduced = statusData
        ?.dorLiftsTrails.sectors[season]
        ?.map((f) => {
          const itOne = f.items?.map((ff) => {
            const itTwo = ff.items?.filter((fff) => (!normalizedSettings
              .includes(fff.subtype)));
            return {
              ...ff,
              items: itTwo,
            };
          });
          return {
            ...f,
            items: itOne,
          };
        });

      setTypes({ lifts, trails: fTrail, pois });
      setSectors(sectorReduced);
      setReports(statusData?.dorLiftsTrails.reports[season]);
      setTrailConditions(conditionsData?.dorLiftsTrails.trail);
      setLiftConditions(conditionsData?.liftReport);
    }
  }, [
    statusData,
    season,
    conditionsData?.dorLiftsTrails.lift,
    hideLiftsTrails,
    conditionsData?.liftReport,
    conditionsData?.dorLiftsTrails.trail,
  ]);

  useEffect(() => {
    const { trails } = types;
    const statusResults = trails?.items
      .filter(({ trail }) => (currentFilters
        .includes(trail.status.id)))
      .map((f) => f.id) || [];

    const difficultyResults = trails?.items
      .filter(({ trail }) => (currentFilters
        .includes(trail.difficulty.id)))
      .map((f) => f.id) || [];

    const groomedResults = trails?.items
      .filter(({ trail }) => (currentFilters
        .includes(trail.groomed.id)))
      .map((f) => f.id) || [];

    const featureDifficultyResults = trails?.items
      .filter(({ trail }) => trail.featureDifficulty
        .find((f) => currentFilters.includes(f.id)))
      .map((f) => f.id) || [];

    const featureResults = trails?.items
      .filter(({ trail }) => trail.feature
        .find((f) => currentFilters.includes(f?.id)))
      .map((f) => f?.id) || [];

    const featureSizeResults = trails?.items
      .filter(({ trail }) => trail.featureSize
        .find((f) => currentFilters.includes(f.id)))
      .map((f) => f.id) || [];

    const combineResults = [...new Set([
      ...statusResults,
      ...difficultyResults,
      ...featureDifficultyResults,
      ...groomedResults,
      ...featureSizeResults,
      ...featureResults,
    ])];

    setTrailIds(combineResults);
  }, [types, currentFilters]);

  const handleCheckboxClick = (e, currentSetState) => {
    const { value, checked } = e.target;
    currentSetState((prevState) => (checked
      ? [...prevState, value]
      : prevState.filter((v) => v !== value)));
  };

  const handleOnExpandClickClose = (id) => {
    setExpandedRows((prevState) => (prevState.filter((v) => v !== id)));
  };

  // ERRORS START
  if (statusError) {
    console.error('DOR Status API Failed:', statusError.message);
    return null;
  }

  if (conditionsError) {
    console.error('DOR Conditions API Failed:', conditionsError.message);
    return null;
  }
  // ERRORS END

  const onDrawerOpen = () => {
    setIsDrawerOpen(true);
    toggleBodyScroll(true);
  };

  const onDrawerClose = () => {
    setIsDrawerOpen(false);
    toggleBodyScroll(false);
  };

  // RENDER COMPONENTS START
  // eslint-disable-next-line react/no-unstable-nested-components
  const DorLiftTableRender = (rows, theme = 'conditions') => (
    <StyledDorTable
      top={`${tableThStickyOffset}`}
      colorProfile={parentColorProfile}
      className={`${theme} table table-strip animated fadeInUp u-an-de-0`}
    >
      <thead>
        <tr>
          {liftTableColumns?.map((th) => !hideLiftCol
            .includes(th.id) && (<th className={th.className}>{th.label}</th>))}
        </tr>
      </thead>
      <tbody>
        {rows?.map((td) => (
          <tr key={td.id}>
            {cmsLiftColumn.NAME && (
              <td className="name">{td.lift.label}</td>
            )}

            {cmsLiftColumn.STATUS && (
              <td className="status">
                {td?.lift?.status?.icon && (
                  <Icon
                    className="icon"
                    name={`status-${td.lift.status.icon}`}
                    width="23"
                    height="23"
                  />
                )}
              </td>
            )}

            {cmsLiftColumn.TYPE && (
              <td className="type">
                {td?.lift?.type || ''}
              </td>
            )}

            {cmsLiftColumn.HOURS && (
              <td className="hours">
                {td?.lift?.hours || 0}
              </td>
            )}

            {cmsLiftColumn.VERTICAL && (
              <td className="vertical">
                {td?.lift?.vertical || 0}
              </td>
            )}

            {cmsLiftColumn.WAIT_TIME && (
              <td className="wait-time">
                {td?.lift?.waitTime || ''}
              </td>
            )}

            {cmsLiftColumn.NOTES && (
              <td className="notes">
                {ReactHtmlParser(td?.notes) || ''}
              </td>
            )}
          </tr>
        )) || []}
      </tbody>
    </StyledDorTable>
  );

  // eslint-disable-next-line react/no-unstable-nested-components
  const DorTableTD = ({
    // eslint-disable-next-line react/prop-types
    id, label, icon, className,
  }) => (
    <td className={className}>
      {label && label}
      {Array.isArray(icon)
        ? icon.map((i) => (
          <Icon
            className="icon"
            name={`${id}-${i.icon}`}
            width="23"
            height="23"
          />
        ))
        : (
          icon && (
            <Icon
              className="icon"
              name={`${id}-${icon}`}
              width="23"
              height="22"
            />
          )
        )}
    </td>
  );

  // eslint-disable-next-line react/no-unstable-nested-components
  const DorTrailTableRender = (rows, activeRows, activeExpandedRows) => (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <StyledDorTable
      top={tableThStickyOffset}
      colorProfile={parentColorProfile}
      className="table table-strip animated fadeInUp "
    >
      <thead>
        <tr>
          {trailTableColumns?.map((th) => !hideTrailCol
            .includes(th.id)
            && (
              <th className={th.className}>{th.label}</th>
            ))}
        </tr>
      </thead>
      <tbody>
        {/* THIS WILL BE REPLACED WITH DorTableTD WHEN I GET THE BUG FIGURED OUT */}
        {rows?.filter((td) => (activeRows.length > 0
          ? activeRows.includes(td.id)
          : td))
          .map((td) => (
            <>
              <tr key={td.id}>
                {cmsTrailColumn.NAME && (
                  <td className="name">
                    {td.trail?.label}
                  </td>
                )}

                {cmsTrailColumn.STATUS && (
                  <td className="status">
                    {td.trail?.status?.icon && (
                      <Icon
                        className={`icon ${td.trail.status.icon}`}
                        name={`status-${td.trail.status.icon}`}
                        width="23"
                        height="23"
                      />
                    )}
                  </td>
                )}

                {(cmsTrailColumn.DIFFICULTY) && (
                  <td className="difficulty">
                    {td.trail?.difficulty?.icon && (
                      <Icon
                        className={`icon ${td.trail.difficulty.icon}`}
                        name={`${td.trail.difficulty.icon}`}
                        width="23"
                        height="23"
                      />
                    )}
                  </td>
                )}

                {(cmsTrailColumn.FEATURE_DIFFICULTY) && (
                  <td className="difficulty">
                    {td.trail?.featureDifficulty?.filter((f) => !hideTrailFeatureDiff
                      .includes(f.icon))
                      .map((f) => f.icon && (
                        <Icon
                          className={`icon ${f.icon}`}
                          name={`${f.icon}`}
                          width="23"
                          height="23"
                        />
                      )) || []}
                  </td>
                )}

                {cmsTrailColumn.FEATURE_SIZE && (
                  <td className="feature-size">
                    {td.trail?.featureSize?.map((f) => f.icon && (
                      <Icon className="icon" name={`${f.icon}`} width="32" height="23" />
                    )) || []}
                  </td>
                )}

                {cmsTrailColumn.GROOMED && (
                  <td className="groomed">
                    {td.trail?.groomed?.icon && (
                      <Icon
                        className="icon"
                        name={`${td.trail.groomed.icon}`}
                        height="23"
                      />
                    )}
                  </td>
                )}

                {cmsTrailColumn.FEATURES && (
                  <td className="icons features">
                    {td.trail?.feature?.map((f) => f?.icon && (
                      (
                        <Icon
                          className={`icon ${f.id}`}
                          name={`${f?.icon}`}
                          width="18"
                          height="18"
                        />
                      )
                    )) || []}
                  </td>
                )}

                {cmsTrailColumn.NOTES && (<td className="notes">{ReactHtmlParser(td.notes)}</td>)}
                {cmsTrailColumn.DESCRIPTION && (<td className="description">{ReactHtmlParser(td.description)}</td>)}
                {cmsTrailColumn.DETAILS && (
                  <td className="details">
                    {td?.expandDetails && (
                      <label
                        htmlFor={`expand-${td.id}`}
                        className="expand-input-checkbox"
                      >
                        <input
                          id={`expand-${td.id}`}
                          name={`expand-${td.id}`}
                          onChange={
                            (e) => handleCheckboxClick(e, setExpandedRows)
                          }
                          type="checkbox"
                          value={td.id}
                        />
                        <Icon
                          className="icon content-info-open"
                          name={!activeExpandedRows.includes(`${td.id}`)
                            ? 'ui-arrow-down'
                            : 'ui-arrow-up'}
                          width="13"
                          height="13"
                        />
                      </label>
                    )}
                  </td>
                )}
              </tr>
              {cmsTrailColumn.DETAILS
                && activeExpandedRows.includes(`${td.id}`)
                ? (
                  <tr className="expanded-row">
                    <td colSpan={Object.values(cmsTrailColumn).filter((f) => f).length}>
                      <div
                        className={
                          classNames(
                            'expanded-td animated fadeInUp u-an-de-0',
                            { 'col-2': td.expandDetails.slides.length > 0 },
                          )
                        }
                      >
                        {(td.expandDetails.slides.length > 1) ? (
                          <div className="col media">
                            <ContentCarousel
                              slidesToShow={1}
                              isArrowsInSlide
                              isAutoRotate={false}
                            >
                              {td?.expandDetails?.slides?.map((slide) => {
                                if (slide.url && MediaTypes.IMAGE?.includes(slide.type)) {
                                  return <img src={slide.url} alt="slide" />;
                                }

                                if (slide.url && MediaTypes.VIDEO.includes(slide.type)) {
                                  return (
                                    <video muted controls>
                                      <source key={slide.url} src={slide.url} type="video/mp4" />
                                    </video>
                                  );
                                }
                              }) || []}
                            </ContentCarousel>
                          </div>
                        ) : (
                          td.expandDetails.slides[0]?.url && (
                            <div className="col media">
                              <img src={td.expandDetails.slides[0].url} alt="slide" />
                            </div>
                          )
                        )}

                        {td.expandDetails && (
                          <>
                            <div className="col content">
                              {td.trail.label && (
                                <h3>
                                  {td.trail.label}
                                </h3>
                              )}
                              {td.description && (
                                <>
                                  {ReactHtmlParser(td.description)}
                                </>
                              )}
                              {td.expandDetails.what && (
                                <p>
                                  <strong>What: </strong>
                                  {td.expandDetails.what}
                                </p>
                              )}
                              {td.expandDetails.who && (
                                <p>
                                  <strong>Who: </strong>
                                  {td.expandDetails.who}
                                </p>
                              )}
                              {td.expandDetails.where && (
                                <p>
                                  <strong>Where: </strong>
                                  {td.expandDetails.where}
                                </p>
                              )}
                              {td.expandDetails.when && (
                                <p>
                                  <strong>When: </strong>
                                  {td.expandDetails.when}
                                </p>
                              )}
                              {td.expandDetails.cta.url && (
                                <a
                                  className="btn"
                                  target="_blank"
                                  href={td.expandDetails.cta.url}
                                  rel="noreferrer"
                                >
                                  {td.expandDetails.cta.label}
                                </a>
                              )}
                            </div>
                            <button
                              className="expand-close-btn"
                              type="button"
                              onClick={() => handleOnExpandClickClose(`${td.id}`)}
                            >
                              <Icon
                                className="icon content-info-open"
                                name="content-info-close"
                                width="33"
                                height="33"
                              />
                            </button>
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                )
                : null}
            </>
          )) || []}
      </tbody>
    </StyledDorTable>
  );

  // eslint-disable-next-line react/no-unstable-nested-components
  const DorSectorContentRender = (sector) => {
    const { id } = sector;
    const trails = types?.trails?.items?.filter((s) => s.sectorId === id) || [];
    const lifts = types?.lifts?.items?.filter((s) => s.sectorId === id) || [];
    return (
      <>
        {lifts && DorLiftTableRender(lifts)}
        {trails && DorTrailTableRender(trails, trailIds, expandedRows)}
      </>
    );
  };

  const setTrailCondBySecLabel = (step1, step2, step3) => (
    // eslint-disable-next-line no-nested-ternary
    season === StatusProps.SEASON.WINTER
      ? step1
      : (season === StatusProps.SEASON.SUMMER)
        ? step2
        : step3);

  const getTrailCondBySecData = (sec, r) => {
    const { id } = sec || {};
    const [result] = r?.filter((s) => s.id === id) || [];
    const { report, difficulty } = result || {};
    const conTrailReport = trailConditions?.filter((s) => (
      s.id === id && s.type === CondTrailTypes.ALPINE)) || [];

    const [obj] = conTrailReport || null;

    if (obj) {
      obj.length.label = setTrailCondBySecLabel(
        CondSectorLengthLabels.SKIABLE_MILES,
        CondSectorLengthLabels.RIDABLE_MILES,
        CondSectorLengthLabels.MILES,
      );

      obj.length.total = roundIt(obj.length.total, 2) || 0;
      obj.length.open = roundIt(obj.length.open, 2) || 0;

      obj.area.label = setTrailCondBySecLabel(
        CondSectorLengthLabels.SKIABLE_ACRES,
        CondSectorLengthLabels.RIDABLE_ACRES,
        CondSectorLengthLabels.ACRES,
      );

      obj.area.total = roundIt(obj.area.total, 2);
      obj.area.open = roundIt(obj.area.open, 2);

      if (obj.name === CondSectorLabels.NORDIC
        && property === Property.MTBACHELOR) {
        obj.area = null;
        obj.length.label = setTrailCondBySecLabel(
          CondSectorLengthLabels.SKIABLE_KILOMETERS,
          CondSectorLengthLabels.RIDABLE_MILES,
          CondSectorLengthLabels.MILES,
        );

        obj.length.total = roundIt(milesToKilometers(obj.length.total), 2);
        obj.length.open = roundIt(milesToKilometers(obj.length.open), 2);
      }
    }

    return {
      report,
      difficulty,
      obj,
    };
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const DorSectorDetailsRender = (sector) => {
    const { report, difficulty, obj } = getTrailCondBySecData(sector, reports);
    return (
      <>
        {difficulty
          && (
            <StyledSectorDifficulty
              colorProfile={parentColorProfile}
              className="sector-difficulty"
            >
              {difficulty?.map((diff) => diff?.icon
                && (
                  <li className={diff.id}>
                    <Icon
                      className={`icon ${diff.icon}`}
                      name={`${diff.icon}`}
                      width="10"
                      height="10"
                    />
                  </li>
                )) || []}
            </StyledSectorDifficulty>
          )}
        <StyledSectorDetails
          colorProfile={parentColorProfile}
          className="sector-details"
        >
          {cmsSectorConditions.TRAIL
            && (
              <li className="item">
                <strong>Lifts&nbsp;</strong>
                {report.lifts.open}
                {' of '}
                {report.lifts.total}
              </li>
            )}

          {cmsSectorConditions.LIFT
            && (
              <li className="item">
                <strong>Trail&nbsp;</strong>
                {report.trails.open}
                {' of '}
                {report.trails.total}
              </li>
            )}

          {cmsSectorConditions.ACRES && obj?.area
            && (
              <li className="item">
                <strong>{obj.area.label}</strong>
                {roundIt(obj?.area?.open, 2)}
                {' of '}
                {roundIt(obj?.area?.total, 2)}
              </li>
            )}

          {cmsSectorConditions.MILES && obj?.length
            && (
              <li className="item">
                <strong>{obj.length.label}</strong>
                {roundIt(obj.length.open, 2)}
                {' of '}
                {roundIt(obj.length.total, 2)}
              </li>
            )}
        </StyledSectorDetails>
      </>
    );
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const DorLiftsDetailsRender = () => {
    const currentSectors = sectors?.map((s) => s.id) || [];
    const trailReport = trailConditions
      ?.filter((s) => currentSectors
        .includes(s.id))
      .reduce((a, b) => ({
        open: a.open + b.open,
        total: a.total + b.total,
        area: {
          label: setTrailCondBySecLabel(
            CondSectorLengthLabels.SKIABLE_ACRES,
            CondSectorLengthLabels.RIDABLE_ACRES,
            CondSectorLengthLabels.ACRES,
          ),
          open: roundIt(a?.area?.open || 0) + roundIt(b?.area?.open || 0, 2),
          total: roundIt(a?.area?.total || 0) + roundIt(b?.area?.total || 0, 2),
        },
        length: {
          label: setTrailCondBySecLabel(
            CondSectorLengthLabels.SKIABLE_MILES,
            CondSectorLengthLabels.RIDABLE_MILES,
            CondSectorLengthLabels.MILES,
          ),
          open: roundIt(a?.length?.open || 0, 2) + roundIt(b?.length?.open || 0, 2),
          total: roundIt(a?.length?.total || 0, 2) + roundIt(b?.length?.total || 0, 2),
        },
      }), 0) || {};

    return (
      <StyledLiftsDetails colorProfile={parentColorProfile}>
        {cmsLiftsConditions.LIFTS
          && (
            <li className="item">
              <strong>Lifts Open</strong>
              {liftConditions?.open}
              {' of '}
              {liftConditions?.total}
            </li>
          )}

        {(cmsLiftsConditions.ACRES
          && trailReport?.area
          && trailReport?.area)
          && (
            <li className="item">
              <strong>{trailReport.area.label}</strong>
              {' '}
              {trailReport.area.open}
              {' of '}
              {trailReport.area.total}
            </li>
          )}

        {(cmsLiftsConditions.MILES
          && trailReport?.length
          && trailReport?.length)
          && (
            <li className="item">
              <strong>{trailReport.length.label}</strong>
              {' '}
              {trailReport.length.open}
              {' of '}
              {trailReport.length.total}
            </li>
          )}
      </StyledLiftsDetails>
    );
  };

  // eslint-disable-next-line no-shadow
  const DorTrailSectorRender = (sectors) => sectors
    ?.map((sector, i) => (
      (
        sector?.items?.find(
          (f) => f.id === StatusTypes.LIFT,
        )?.items.length > 0
        || sector?.items?.find(
          (f) => f.id === StatusTypes.TRAIL,
        )?.items.length > 0
      ) && (
        <UiAccordionPanel
          panelId={`${i}`}
          label={sector.label}
          extraDetailsRender={
            DorSectorDetailsRender(sector)
          }
          isOpen={(i < 4)}
          style={{
            'z-index': `${100 - i}`,
          }}
        >
          {DorSectorContentRender(sector)}
        </UiAccordionPanel>
      )
    )) || [];

  const DorLegendTrailTypeRatingRender = trailLegendSummer.map((diff) => {
    const { icons } = diff;
    return (
      <div className="col">
        <div className="header">
          <h4>{diff.label}</h4>
          <a href={diff.modal.url}>
            <Icon
              className="icon"
              name="content-info-open"
              width="16"
              height="16"
            />
          </a>
        </div>
        <div className="content">
          {icons?.map((icon) => (
            <span className="icon-wrap">
              <Icon
                className={`icon icon ${icon.icon}`}
                name={`${icon.icon}`}
                width={icon.width}
                height={icon.height}
              />
              <p>{icon.label}</p>
            </span>
          )) || []}
        </div>
      </div>
    );
  });

  return (
    <StyledDorLiftsTrails colorProfile={parentColorProfile}>
      {conditionsIsLoading && statusIsLoading ? (
        <Loader
          className="icon"
          classAnimationEffect="loader-animated spin infinite"
          iconName="dor-sunny"
          size="30"
        />
      ) : (
        <StyledLayoutWrapper ref={liftTrailContentRef}>
          <StyledLayoutFilters>
            <StyledDorDrawer
              colorProfile={parentColorProfile}
              top={`${drawerStickyOffset}`}
            >
              <UiDrawer
                isDrawerOpen={isDrawerOpen}
                isDrawerEnabled={isMobile && isInView}
                handleDrawOpen={onDrawerOpen}
                handleCanvasClose={onDrawerClose}
                drawerOffsetTop={`${drawerStickyOffset}`}
                drawerWidth={310}
              >
                {(cmsGeneralDrawr.FITLER_HEADER && conditionsData?.parkUpdate) && (
                  <div className="drawr-fitler-header">
                    <h3>
                      Total Features:
                      {conditionsData?.parkUpdate[0]?.total_features || 0}
                    </h3>
                  </div>
                )}
                <UiAccordion panelDefaultState={(filterDefaultState === 'open')}>
                  {trailFilter?.map((grp, i) => !hideFilterDrawer.includes(grp.id) && (
                    <UiAccordionPanel
                      id={`test-${i}`}
                      panelId={`${i}`}
                      label={grp.label}
                      style={{
                        'z-index': `${100 - i}`,
                      }}
                    >
                      <DorCheckboxGroup
                        id={grp.id}
                        className="animated fadeInUp u-an-de-0"
                        layout="list"
                        checkInputsFields={grp.fields
                          .filter((f) => ![
                            ...hideTrailStatus || [],
                            ...hideTrailDifficulty || [],
                            ...hideTrailFeature || [],
                            ...hideTrailFeatureDiff || [],
                            ...hideTrailFeatureSize || [],
                            ...hideTrailGroomedStatus || [],
                          ].includes(f.id))}
                        checked={currentFilters}
                        handleFiltersOnChange={
                          (e) => handleCheckboxClick(e, setCurrentFilters)
                        }
                      />
                    </UiAccordionPanel>
                  ))}
                </UiAccordion>
              </UiDrawer>
            </StyledDorDrawer>
          </StyledLayoutFilters>
          <StyledLayoutSectors>
            <StyledDorSector
              colorProfile={parentColorProfile}
              className="sectors"
              top={`${drawerStickyOffset}`}
            >
              {cmsLiftsTrails.LIFT && (
                <DorCheckboxGroup
                  id="sector-status-lifts-legend"
                  title="Lifts"
                  className="lift-legend"
                  checkInputsFields={statusLiftCb.fields
                    .filter((f) => !hideLiftStatus
                      .includes(f.id))}
                  checked={currentFilters}
                  handleFiltersOnChange={
                    (e) => handleCheckboxClick(e, setCurrentFilters)
                  }
                />
              )}

              {(cmsLiftsTrails.LIFT && types?.lifts) && (
                <UiAccordionPanel
                  id="sector-all-lifts-accordion"
                  label="All Lifts"
                  isOpen
                >
                  <DorLiftsDetailsRender />
                  {types?.lifts?.items && DorLiftTableRender(types.lifts.items, layoutTypes.NONE)}
                </UiAccordionPanel>
              )}

              {cmsFilterContent.STATUS && (
                <DorCheckboxGroup
                  id="sector-status-trails-filters"
                  title="Trails"
                  checkInputsFields={statusTrailCb?.fields
                    .filter((f) => !hideTrailStatus
                      .includes(f.id))}
                  checked={currentFilters}
                  handleFiltersOnChange={
                    (e) => handleCheckboxClick(e, setCurrentFilters)
                  }
                />
              )}

              {cmsFilterContent.FEATURE_DIFFICULTY && (
                <StyledLegendTrailTypeRating>
                  <UiAccordionPanel
                    id="sector-trail-types-rating-legend"
                    label="Trail Types + Ratings"
                    className="animated fadeInUp u-an-de-0"
                  >
                    <StyledLayoutGrid>
                      {DorLegendTrailTypeRatingRender}
                    </StyledLayoutGrid>
                  </UiAccordionPanel>
                </StyledLegendTrailTypeRating>
              )}

              {(cmsFilterContent.FEATURE_DIFFICULTY
                || cmsFilterContent.DIFFICULTY)
                && (
                  <UiAccordionPanel
                    id="sector-trail-types-rating-filters"
                    label="Filters For Trail Types + Ratings"
                    className="animated fadeInUp u-an-de-0"
                  >
                    {cmsFilterContent.FEATURE_DIFFICULTY && (
                      <DorCheckboxGroup
                        id={featureDifficultyTrailCb?.id}
                        layout={layoutTypes.COLUMN}
                        title="Filter By Trail Type"
                        checkInputsFields={featureDifficultyTrailCb?.fields}
                        checked={currentFilters}
                        handleFiltersOnChange={
                          (e) => handleCheckboxClick(e, setCurrentFilters)
                        }
                      />
                    )}
                    {cmsFilterContent.DIFFICULTY && (
                      <DorCheckboxGroup
                        id={difficultyTrailCb?.id}
                        layout={layoutTypes.COLUMN}
                        title="Filter Trails by Difficulty Rating"
                        checkInputsFields={difficultyTrailCb?.fields}
                        checked={currentFilters}
                        handleFiltersOnChange={
                          (e) => handleCheckboxClick(e, setCurrentFilters)
                        }
                      />
                    )}
                  </UiAccordionPanel>
                )}

              <UiAccordion panelDefaultState={(panelsDefaultState === 'open')}>
                {sectors && DorTrailSectorRender(sectors)}
              </UiAccordion>
            </StyledDorSector>
          </StyledLayoutSectors>
        </StyledLayoutWrapper>
      )}
    </StyledDorLiftsTrails>
  );
};

DorLiftsTrails.propTypes = {
  parentColorProfile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  hideFilterContent: PropTypes.arrayOf(PropTypes.string),
  hideFilterDrawer: PropTypes.arrayOf(PropTypes.string),
  hideLiftsTrails: PropTypes.arrayOf(PropTypes.string),
  hideLiftColDesktop: PropTypes.arrayOf(PropTypes.string),
  hideTrailColDesktop: PropTypes.arrayOf(PropTypes.string),
  hideTrailColMobile: PropTypes.arrayOf(PropTypes.string),
  hideTrailFeature: PropTypes.arrayOf(PropTypes.string),
  hideTrailFeatureSize: PropTypes.arrayOf(PropTypes.string),
  hideTrailGroomedStatus: PropTypes.arrayOf(PropTypes.string),
  hideTrailDifficulty: PropTypes.arrayOf(PropTypes.string),
  hideSectorConditions: PropTypes.arrayOf(PropTypes.string),
  hideLiftColMobile: PropTypes.arrayOf(PropTypes.string),
  hideLiftsConditions: PropTypes.arrayOf(PropTypes.string),
  hideGeneralDrawr: PropTypes.arrayOf(PropTypes.string),
  hideTrailFeatureDiff: PropTypes.arrayOf(PropTypes.string),
  hideTrailStatus: PropTypes.arrayOf(PropTypes.string),
  hideLiftStatus: PropTypes.arrayOf(PropTypes.string),
  filterDefaultState: PropTypes.string,
  panelsDefaultState: PropTypes.string,
  parentComponentSeason: PropTypes.string,
};

DorLiftsTrails.defaultProps = {
  parentColorProfile: null,
  hideFilterContent: [],
  hideFilterDrawer: [],
  hideLiftsTrails: [],
  hideLiftColDesktop: [],
  hideTrailColDesktop: [],
  hideTrailColMobile: [],
  hideTrailFeature: [],
  hideTrailFeatureSize: [],
  hideTrailGroomedStatus: [],
  hideTrailDifficulty: [],
  hideSectorConditions: [],
  hideLiftColMobile: [],
  hideLiftsConditions: [],
  hideGeneralDrawr: [],
  hideTrailFeatureDiff: [],
  hideTrailStatus: [],
  hideLiftStatus: [],
  filterDefaultState: 'closed',
  panelsDefaultState: 'closed',
  parentComponentSeason: null,
};
