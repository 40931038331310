/* eslint-disable no-lonely-if */
import styled from 'styled-components';

import { Components, ThemeProps as Prop, StaticProfiles } from '@powdr/constants';
import { themeUtil } from '@powdr/utils';

const CPNT = Components.COMPARE_CARDS;

export const StyledCompareCard = styled.div`
  ${({ isMobile }) => ((isMobile) ? 'perspective: 300px;' : '')}
`;

export const CompareCardOuter = styled.div`
  width: 325px;
  padding: 15px 25px;
  display: block;

  ${({ isHighlighted, isMobile }) => {
    let styles = '';
    if (isHighlighted) {
      styles += `
        background-color: ${themeUtil(Prop.LIGHTEST, CPNT, StaticProfiles.SHADES)};
        border-radius: 10px;
      `;

      if (isMobile) {
        styles += 'width: 275px;';
      }
    } else {
      if (isMobile) {
        styles += `
          width: 275px;
          padding: 55px 10px 15px 10px;
        `;
      } else {
        styles += 'padding: 55px 25px 15px 25px;';
      }
    }

    return styles;
  }}
`;

export const CardOuterHeader = styled.div`
  color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
  margin-bottom: 15px;

  ${({ isHighlighted, theme }) => {
    if (isHighlighted) {
      return `
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      font-size: ${theme.FontSize.REG30};
      line-height: 1.2;
      font-weight: bold;
      text-align: center;
    `;
    }
    return '';
  }}
`;

export const CardFlipContainer = styled.div`
  transition: transform 1s;
  transform-style: preserve-3d;
  position: relative;
  height: 552px;

  ${({ isCardFlipped }) => ((isCardFlipped) ? 'transform: rotateY(-180deg);' : '')}
`;
