import styled, { css } from 'styled-components';

import { VerticalPosition, Breakpoints } from '@powdr/constants';

export const StyledUiColumn = styled.div`
  display: flex;
  flex-direction: row;
  ${Breakpoints.mobile(css`flex-direction: column;`)}
  flex-wrap: wrap;
  justify-content: center;
  padding: 5px;
  align-items: ${({ contentPosition }) => {
    switch (contentPosition) {
      case VerticalPosition.TOP: return 'flex-start';
      case VerticalPosition.MIDDLE: return 'center';
      case VerticalPosition.BOTTOM: return 'flex-end';
      default: return 'flex-start';
    }
  }};

  ${Breakpoints.desktopOnly(css`
    ${({ isAlternatePadding }) => ((isAlternatePadding) ? 'padding: 0;' : '')};
  `)}

  > * {
    position: relative;
    width: 50%;
    padding: 25px;

    ${Breakpoints.mobile(css`
      width: 100%;
    `)}
  }

  > .ui-image {
    ${Breakpoints.desktopOnly(css`
      ${({ isAlternatePadding }) => ((isAlternatePadding) ? 'padding: 0; img { margin: 0; }' : '')};
    `)}
  }

  > .ui-html {
    ${Breakpoints.desktopOnly(css`
      ${({ isAlternatePadding }) => ((isAlternatePadding) ? 'padding: 0; padding-left: 100px; padding-right: 100px;' : '')};
    `)}
  }

  > .form-formbucket {
    padding: none;
  }

  > .booking-widget {}
`;
