import { useEffect, useState } from 'react';

export const useClickedOutside = (
  ref,
) => {
  const [isClickedOutside, setClickedOutside] = useState(false);

  useEffect(() => {
    const handleClickOutsideRef = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setClickedOutside(true);
      } else {
        setClickedOutside(false);
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutsideRef);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutsideRef);
    };
  }, [ref]);

  return isClickedOutside;
};
