// eslint-disable-next-line import/no-unresolved
import { globalHistory } from '@reach/router';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
  useContext, useEffect, useState, Fragment,
} from 'react';

import { MegaNav, Icon } from '@powdr/components';
import {
  RouteAction, HeaderStyles, LinkTargets,
} from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { useSeason } from '@powdr/hooks';
import { getLevelName } from '@powdr/utils';
import { useStaticData } from '@powdr/web/src/hooks';

import {
  StyledMainNav,
  MainNavigationList, NavigationItem,
  NavigationItemInnerContentWrapper,
  NavItemContent,
  WoodwardNavIcon,
  HoverStateElement,
  NavigationLevel1OnlyLink,
} from './styles';

export const MainNav = ({
  className,
  isLandingPage,
}) => {
  const { checkSeasonality } = useSeason();
  const { navigation, headerConfig } = useStaticData();
  const {
    isMobile,
    toggleBodyScroll,
    inView,
    setDrawerOpen,
  } = useContext(AppContext);
  const [megaNavOpen, setMegaNavOpen] = useState(null);
  let navigationHoverTimeout;

  useEffect(() => globalHistory.listen(({ action }) => {
    // remove noscroll class from body on route change
    if (action === RouteAction.PUSH) toggleBodyScroll(false);
  }), [toggleBodyScroll]);

  const toggleDesktopMegaNav = (index, open) => {
    clearTimeout(navigationHoverTimeout);
    navigationHoverTimeout = setTimeout(() => {
      if (!isMobile) {
        toggleBodyScroll(open);
        if (open) {
          setMegaNavOpen(index);
        } else {
          setMegaNavOpen(null);
        }
      }
    }, 200);
  };

  const toggleMobileMegaNav = (index) => {
    if (isMobile) {
      if (megaNavOpen === index) {
        setMegaNavOpen(null);
      } else {
        setMegaNavOpen(index);
      }
    }
  };

  const navBackground = (
    (headerConfig.navigationOpacityPercentage === 100 || !inView)
      ? HeaderStyles.SOLID
      : HeaderStyles.TRANSLUCENT
  );

  const isHideNavItem = (page) => (!!((
    page?.isHideFromNav
    || !checkSeasonality(page?.season?.toLowerCase()))
  ));

  const isLevel2Overrides = (page) => (
    page.navRelationships.navigationOverrides
    && page.navRelationships.navigationOverrides?.length > 0
  );

  const isLevel1Only = (page) => (!!(
    page.childPages.length === 0
    || page.childPages.filter((childPage) => !isHideNavItem(childPage)).length === 0
  ));

  if (!navigation) return null;

  return (
    <StyledMainNav
      id="main-nav-start"
      className={className}
      tabindex="0"
      aria-label="Start of Main Navigation"
      role="navigation"
      opacity={headerConfig.navigationOpacityPercentage}
      background={navBackground}
      isMobile={isMobile}
      $isRightAlignedNav={headerConfig?.isRightAlignedNav}
      $splitHeight={!!(headerConfig?.navigationComponents?.length > 0)}
    >
      {(!isLandingPage) && (
      <MainNavigationList
        className="main-navigation-list"
        role="menu"
        navigationStyle={headerConfig?.navigationDropdownStyle}
        isRightAlignedNav={headerConfig?.isRightAlignedNav}
      >
        {navigation?.childPages.map((level1, idx) => {
          // check nav items to be hidden based on season/hide toggle
          if (isHideNavItem(level1)) return null;

          /// level 1 only main navigation links
          if (isLevel1Only(level1) && !isLevel2Overrides(level1)) {
            return (
              <NavigationItem
                key={level1.id}
                role="menuitem"
                aria-label="Menu Navigation Item"
                navigationStyle={headerConfig?.navigationDropdownStyle}
                isRightAlignedNav={headerConfig?.isRightAlignedNav}
              >
                <NavigationLevel1OnlyLink href={level1.link} target={LinkTargets.SELF}>
                  <NavigationItemInnerContentWrapper
                    isNavActiveUnderline={headerConfig?.isNavActiveUnderline || false}
                  >
                    <NavItemContent
                      className="nav-item-content level-1-only"
                      background={navBackground}
                      isMatchScrollState={headerConfig?.isMatchScrollState}
                      onClick={() => ((isMobile) ? setDrawerOpen(false) : null)}
                      isRightAlignedNav={headerConfig?.isRightAlignedNav}
                      isNavActiveUnderline={headerConfig?.isNavActiveUnderline}
                    >
                      {headerConfig?.isRightAlignedNav ? (
                        <>
                          <span>{getLevelName(level1)}</span>
                          <span className="underline-element" />
                        </>
                      ) : getLevelName(level1)}
                    </NavItemContent>
                  </NavigationItemInnerContentWrapper>
                </NavigationLevel1OnlyLink>
              </NavigationItem>
            );
          }

          return (
            <Fragment key={level1.id}>
              <NavigationItem
                tabIndex="0"
                role="menuitem"
                aria-label="Menu Navigation Item"
                onClick={() => toggleMobileMegaNav(idx)}
                onMouseEnter={() => toggleDesktopMegaNav(idx, true)}
                onMouseLeave={() => toggleDesktopMegaNav(idx, false)}
                navigationStyle={headerConfig?.navigationDropdownStyle}
                isRightAlignedNav={headerConfig?.isRightAlignedNav}
              >
                <NavigationItemInnerContentWrapper
                  isOpen={megaNavOpen === idx}
                  isMatchScrollState={headerConfig?.isMatchScrollState}
                  className={classNames({ 'woodward-nav-item': getLevelName(level1) === 'Woodward' })}
                >
                  {(!isMobile) && (
                  <>
                    <HoverStateElement className="bar" isMatchScrollState={headerConfig?.isMatchScrollState} />
                    <HoverStateElement className="arrow" isMatchScrollState={headerConfig?.isMatchScrollState} />
                  </>
                  )}

                  {/* Standard Level 1 Item */}
                  {getLevelName(level1) !== 'Woodward' && (
                  <NavItemContent
                    className="nav-item-content"
                    background={navBackground}
                    isMatchScrollState={headerConfig?.isMatchScrollState}
                    isRightAlignedNav={headerConfig?.isRightAlignedNav}
                    isNavActiveUnderline={headerConfig?.isNavActiveUnderline}
                  >
                    {headerConfig?.isRightAlignedNav ? (
                      <>
                        <span>{getLevelName(level1)}</span>
                        <span className="underline-element" />
                      </>
                    ) : getLevelName(level1)}
                    {isMobile && megaNavOpen === idx && (<Icon className="active-indicator" name="ui-minus" width="12" height="12" />)}
                    {isMobile && megaNavOpen !== idx && (<Icon className="active-indicator" name="ui-plus" width="12" height="12" />)}
                  </NavItemContent>
                  )}

                  {/* Woodward Level 1 Item */}
                  {getLevelName(level1) === 'Woodward' && (
                  <NavItemContent
                    className="nav-item-content woodward-nav-item-content"
                    isMobile={isMobile}
                    background={navBackground}
                    isMatchScrollState={headerConfig?.isMatchScrollState}
                    isRightAlignedNav={headerConfig?.isRightAlignedNav}
                    isNavActiveUnderline={headerConfig?.isNavActiveUnderline}
                  >
                    <WoodwardNavIcon className="woodward-nav-icon" name="wrapper-woodward-header" />
                    {isMobile && megaNavOpen === idx && (<Icon className="active-indicator" name="ui-minus" width="12" height="12" />)}
                    {isMobile && megaNavOpen !== idx && (<Icon className="active-indicator" name="ui-plus" width="12" height="12" />)}
                  </NavItemContent>
                  )}
                </NavigationItemInnerContentWrapper>

                {/* Desktop Mega Nav */}
                {!isMobile
                  && megaNavOpen === idx
                  && (level1.childPages.length > 0 || isLevel2Overrides(level1))
                  && (
                    <MegaNav
                      pages={(isLevel2Overrides(level1))
                        ? level1.navRelationships.navigationOverrides
                        : level1.childPages}
                      isLevel2Overrides={isLevel2Overrides(level1)}
                    />
                  )}
              </NavigationItem>
              {/* Mobile Mega Nav */}
              {isMobile
                && megaNavOpen === idx
                && (level1.childPages.length > 0 || isLevel2Overrides(level1))
                && (
                  <li key={level1.id} className="mobile-mega-wrapper">
                    <MegaNav
                      pages={(isLevel2Overrides(level1))
                        ? level1.navRelationships.navigationOverrides
                        : level1.childPages}
                      isLevel2Overrides={isLevel2Overrides(level1)}
                    />
                  </li>
                )}
            </Fragment>
          );
        })}
      </MainNavigationList>
      )}
    </StyledMainNav>
  );
};

MainNav.propTypes = {
  className: PropTypes.string,
  isLandingPage: PropTypes.bool,
};

MainNav.defaultProps = {
  className: '',
  isLandingPage: false,
};
