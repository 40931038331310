import styled, { css } from 'styled-components';

import {
  ThemeProps as Prop, Components, Breakpoints,
} from '@powdr/constants';
import { themeUtil } from '@powdr/utils';

const CPNT = Components.PRODUCT_FINDER;

export const StyledProducts = styled.div`

  header {
    margin-bottom: 25px;
    border-bottom: 1px solid;
    border-bottom-color: ${({ colorProfile }) => themeUtil(Prop.BORDER, CPNT, colorProfile)};

    h3 {
      font-size: ${({ theme }) => theme.FontSize.LRG10};
    }

    h3, p {
      margin-bottom: 15px;

    }
  }

  .content-block {
    margin-bottom: 16px;
    .content-block-price-split {
      .price {
        h4 {
          margin-bottom: 16px
        }
      }
    }
  }

  .ui-accordion {
    ${Breakpoints.mobile(css`
      padding: 15px;
    `)}
  }

  .btn-clear {
      color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
      font-size: ${({ theme }) => theme.FontSize.REG30};
      text-transform: uppercase;
      margin-top: 15px;
    }

  .ui-accordion-panel {
    background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};

    .panel-header {
      background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};
      border-color: ${({ colorProfile }) => themeUtil(Prop.BORDER, CPNT, colorProfile)};
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px solid;
      padding: 5px;
      z-index: 10;

      ${Breakpoints.mobile(css`
        top: 0;
      `)}

      &.open-sector {
        .icon.expand {
          transition : all 0.35s ease-in-out;
          transform: rotate(0deg);
        }
      }

      .panel-icon {
        display: inline-block;
        padding: 10px;

        .icon svg {
          fill: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
          width: 100%;
          height: 100%;
        }
      }


      &.btn .panel-label {
        color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
        font-size: ${({ theme }) => theme.FontSize.MED30};
        text-transform: uppercase;
        margin: 0;
      }
    }
  }
`;

export const StyledCheckboxGroup = styled.div`
  ul.checkboxes {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0;

    li,
    label {
      align-items: center;
      display: flex;
      cursor: pointer;
      position: relative;
    }

    li {
      margin-right: 10px;
      width: 100%;
    }

    label {
      cursor: pointer;
      position: relative;
      ${({ theme }) => theme.fonts.PRI_BDY_MED};
      font-size: ${({ theme }) => theme.FontSize.REG30};

      input[type='checkbox'],
      input[type='checkbox']:after,
      input[type='checkbox']:before {
        content: none;
        overflow: hidden;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        outline: none;
      }

      input[type='checkbox']:after,
      input[type='checkbox']:before {
        content: "";
        cursor: pointer;
        top: 3px;
        left: 3px;
        position: absolute;
        border: 1px solid;
        border-radius: 4px;
        border-color: ${({ colorProfile }) => themeUtil(Prop.BORDER, CPNT, colorProfile)};
        margin-right: 7px;
        display: block;
      }

      input[type='checkbox']:before {
        background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};
      }

      input[type='checkbox']:checked:after {
        border-color: ${({ colorProfile }) => themeUtil(Prop.BORDER, CPNT, colorProfile)};
        transform: rotate(-57deg);
        border: 0.15625rem solid;
        width: 9px;
        height: 5px;
        top: 9px;
        left: 8px;
        border-top: none;
        border-right: none;
        border-radius: 0;
      }
    }
  }
`;

export const StyledLayoutMain = styled.div`
  flex: 1 1 auto;
  position: relative;
  z-index: 1;
`;

export const StyledLayoutAside = styled.div`
  position: relative;
  flex: 0 0 250px;
  margin-bottom: 20px;
  z-index: 70;
`;

export const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => `${props.columns}`}, 1fr);
  gap: 10px;
  grid-auto-rows: minmax(100px, auto);
`;

export const StyledProductTags = styled.ul`
  display: flex;
  margin-bottom: 15px;
  flex-direction: row;
  flex-wrap: wrap;

  button.btn {
    display: flex;
    padding: 10px;
    margin-right: 5px;
    margin-bottom: 5px;
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};

    &:hover {
      color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    }

    &:active {
      color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_ACTIVE, CPNT, colorProfile)};
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_ACTIVE, CPNT, colorProfile)};
    }
  }
  .icon {
    margin-left: 5px;
    svg {
      fill: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
    }
  }
`;

export const StyledLayoutRow = styled.div`
  display: flex;
  gap: 15px;

  ${Breakpoints.mobile(css`
    display: block;
  `)}
`;

export const StyledDrawer = styled.div`
  position: sticky;
  top: ${({ top }) => `${top}px`};
  z-index: 70;

  .ui-accordion {
    margin-bottom: 15px;
  }

  button.btn-clear {
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};

    &:hover {
      color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)} ;
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    }

    &:active {
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_ACTIVE, CPNT, colorProfile)};
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_ACTIVE, CPNT, colorProfile)};
    }
  }

  .ui-drawer {
    height: 100%;

    .drawer {
      padding: 0;
      background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};

      button.fixed-open {
        position: absolute;
        right: 0;
        top: 50px;
        width: 130px;

        &.an-open {
          right: -130px;
        }
      }
    }
  }

  .ui-drawer-enabled {
    width: 310px;
  }

  .ui-drawer-content-wrap {
    background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};
  }

  .ui-drawer-content {
    max-height: ${(props) => `calc(100vh - ${parseFloat(props.top) + 35}px)`} ;
    overflow-x: hidden;
  }

  .ui-drawer-btn-toggle {
    font-family: ${({ theme }) => theme.fonts.PRI_HDR_BLK};
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
    position: absolute;
    left: 100%;
    padding: 15px;
    transform-origin: bottom left;
    transform: rotate(90deg);
    text-transform: uppercase;
    font-size: 24px;
    line-height: 30px;

    &:hover {
      color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)} ;
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    }

    &:active {
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_ACTIVE, CPNT, colorProfile)};
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_ACTIVE, CPNT, colorProfile)};
    }
  }

  .ui-accordion-panel {
    background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};

    .checkboxes li {
      margin-bottom: 10px;
      padding-left: 5px;
    }

    &:after {
      background-color: ${({ colorProfile }) => themeUtil(Prop.BORDER, CPNT, colorProfile)};
      content: "";
      display: block;
      height: 1px;
    }

    &.collapsed::after {
      display: none;
    }

    .panel-render {
      display: flex;
      align-items: center;
    }

    .panel-details {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }

    .panel-header {
      background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};
      border-color: ${({ colorProfile }) => themeUtil(Prop.BORDER, CPNT, colorProfile)};
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px solid;
      padding: 5px;

      ${Breakpoints.mobile(css`
        top: 0;
      `)}

      &.open-sector {
        .icon.expand {
          transition : all 0.35s ease-in-out;
          transform: rotate(0deg);
        }
      }

      .panel-icon {
        display: inline-block;
        padding: 10px;

        .icon svg {
          fill: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
          width: 100%;
          height: 100%;
        }
      }


      &.btn .panel-label {
        color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
        font-size: ${({ theme }) => theme.FontSize.MED30};
        text-transform: uppercase;
        margin: 0;
      }
    }
  }
`;
