import { css } from 'styled-components';

const mobileTabletPortrait = (...args) => css`
  @media only screen and (max-width: ${960 - 1}px) {
    html.mobile &, html.tablet.portrait & {
      ${args}
    }
  }
`;

const desktopOnly = (...args) => css`
  @media only screen and (min-width: 960px) {
    ${args}
  }
`;

const mobileSmall = (...args) => css`
  @media only screen and (max-width: ${415 - 1}px) {
    ${args}
  }
`;

const mobile = (...args) => css`
  @media only screen and (max-width: ${960 - 1}px) {
    ${args}
  }
`;

const mobileTablet = (...args) => css`
  html.mobile &, html.tablet & {
    ${args}
  }
`;

const large = (...args) => css`
  @media only screen and (min-width: 1600px) {
    ${args}
  }
`;

const larger = (...args) => css`
  @media only screen and (min-width: 1920px) {
    ${args}
  }
`;

const standard = (...args) => css`
  @media only screen and (max-width: ${1600 - 1}px) {
    ${args}
  }
`;

const mobileLandscape = (...args) => css`
  @media only screen and (max-width:  ${960 - 1}px) {
    html.mobile.landscape & {
      ${args}
    }
  }
`;

const portrait = (...args) => css`
  @media only screen and (max-width: ${768 - 1}px) {
    ${args}
  }
`;

const portraitSmall = (...args) => css`
  @media only screen and (max-width: ${480 - 1}px) {
    ${args}
  }
`;

const smallest = (...args) => css`
  @media only screen and (max-width: ${960 - 1}px) {
    ${args}
  }
`;

const smaller = (...args) => css`
  @media only screen and (max-width: ${1024 - 1}px) {
    ${args}
  }
`;

const smallInbetween = (...args) => css`
  @media only screen and (max-width: ${1250 - 1}px) {
    ${args}
  }
`;

const small = (...args) => css`
  @media only screen and (max-width: ${1366 - 1}px) {
    ${args}
  }
`;

export const Breakpoints = {
  mobileTabletPortrait,
  mobileLandscape,
  mobile,
  mobileSmall,
  desktopOnly,
  mobileTablet,
  large,
  larger,
  standard,
  portrait,
  portraitSmall,
  smallest,
  smaller,
  smallInbetween,
  small,
};

export const BGImageBreakpoints = [960, 1024, 1366, 1920];
