/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import { useStaticQuery, graphql } from 'gatsby';
import { useMemo } from 'react';

import {
  HeaderStyles, NavigationStyles, Components,
} from '@powdr/constants';
import { isEmpty } from '@powdr/utils';

export const useStaticData = () => {
  const {
    site,
    nodeDefaultData,
    paragraphHeaderConfiguration,
    nestedNavigation,
    paragraphDorFeed,
    allNodeGlobalTable,
    allFooterPropertyListJson,
  } = useStaticQuery(
    graphql`
      query {
        site {
          ...SiteMetadataQuery
        }
        nodeDefaultData {
          ...DefaultDataQuery
        }
        paragraphHeaderConfiguration {
          ...HeaderConfigurationQuery
        }
        nestedNavigation: taxonomyTermSiteNavigation(field_navigation_link: {eq: "/"}) {
          ...NestedSiteNavigationQuery
        }
        paragraphDorFeed {
          ...DorFeedQuery
        }
        allNodeGlobalTable {
          ...AllGlobalTableQuery
        }
        allFooterPropertyListJson {
          nodes {
            title
            iconName
            height
            link
          }
        }
      }
    `,
  );

  const filteredDefaultDataComponents = nodeDefaultData?.relationships.defaultComponents
    .filter((component) => !isEmpty(component));
  const filteredGlobalContent = allNodeGlobalTable.nodes.filter((node) => node?.html?.value);

  // TODO: maybe make this utility if other components are doing something similar
  const findComponent = (key) => filteredDefaultDataComponents
    .filter((component) => component.typeName === key) || [];

  // TODO: revisit possiblity of handling hide from nav logic here rather than in main-nav/mega-nav
  // possibly not feasible due to level 2/level 3 link logic with referencing children
  const sortNavigationByWeight = (childPages) => childPages.reduce((allPages, childPage) => {
    const season = childPage.navRelationships?.season?.name?.toLowerCase() || null;
    if (childPage.relationships?.pages) {
      allPages.push({
        ...childPage,
        childPages: sortNavigationByWeight(childPage.relationships.pages
          .sort((a, b) => a.weight - b.weight)),
        relationships: null,
        season,
      });
    } else {
      allPages.push({
        ...childPage,
        childPages: [],
        relationships: null,
        season,
      });
    }
    return allPages;
  }, []);

  const navigationObject = useMemo(() => ({
    ...nestedNavigation,
    childPages: sortNavigationByWeight(
      nestedNavigation.relationships.pages.sort((a, b) => a.weight - b.weight),
    ),
  }), [nestedNavigation]);

  return {
    ...site.siteMetadata,
    defaultSeason: nodeDefaultData?.defaultSeason?.toLowerCase(),
    headerConfig: {
      ...paragraphHeaderConfiguration,
      headerStyle: (paragraphHeaderConfiguration?.navigationOpacityPercentage < 100)
        ? HeaderStyles.TRANSLUCENT
        : HeaderStyles.SOLID,
      navigationDropdownStyle:
        paragraphHeaderConfiguration?.navigationDropdownStyle
        || NavigationStyles.FULL_WIDTH,
      isRightAlignedNav: paragraphHeaderConfiguration?.isRightAlignedNav || false,
      bannerLinkGroups: (paragraphHeaderConfiguration?.relationships?.bannerLinkGroups
        && paragraphHeaderConfiguration?.relationships?.bannerLinkGroups?.length > 0)
        ? paragraphHeaderConfiguration?.relationships?.bannerLinkGroups
        : null,
      promotions: (paragraphHeaderConfiguration?.relationships?.promotions)
        ? paragraphHeaderConfiguration.relationships.promotions
        : null,
      isDorFeedEnabled: paragraphHeaderConfiguration?.isDorFeedEnabled,
      feedTempHide: paragraphHeaderConfiguration?.hideDorFeedSection === 1,
      feedSnowfallHide: paragraphHeaderConfiguration?.hideDorFeedSection === 2,
      seasonalityPlacement: (paragraphHeaderConfiguration?.isSeasonalityEnabled && paragraphHeaderConfiguration?.seasonalityPlacement) ? paragraphHeaderConfiguration?.seasonalityPlacement : '',
      navigationComponents: (paragraphHeaderConfiguration?.relationships?.navigationComponents)
        ? paragraphHeaderConfiguration.relationships.navigationComponents
        : null,
    },
    dorHeader: {
      ...paragraphDorFeed,
    },
    /* socialIcons: {
      ...allParagraphSocialIcon,
    }, */
    navigation: {
      ...navigationObject,
    },
    globalContent: filteredGlobalContent,
    chatComponent: {
      hasChatComponent: !!(
        !isEmpty(findComponent(Components.CHAT_SATISFI)?.[0])
        || !isEmpty(findComponent(Components.CHAT_TRACKHS)?.[0])
      ),
      chatSatisfi: findComponent(Components.CHAT_SATISFI)?.[0],
      chatTrackhs: findComponent(Components.CHAT_TRACKHS)?.[0],
    },
    mobileDrawer: {
      drawerButton: findComponent(Components.MOBILE_DRAWER_BUTTON)?.[0],
    },
    socialIcons: findComponent(Components.FOOTER_SOCIALS),
    footerPropertyList: [...allFooterPropertyListJson.nodes],
  };
};
