/* eslint-disable import/no-unresolved */
import { useLocation } from '@reach/router';
import classNames from 'classnames';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { ContentCarousel } from '@powdr/components';
import { AppContext } from '@powdr/context';
import { useSeason } from '@powdr/hooks';
import { colorProfileByIndex, isRuntime } from '@powdr/utils';

import {
  StyledQrQueryCards, OverlayHeader, OverlayLogo, OverlayLogoLink,
  OverlayCloseButton, CloseButtonText, CloseButtonIcon,
  AlignmentWrapper, OverlayBody, MainHeader,
  DatePickerSection, DatePickerSectionHeader,
  DatePickerWrapper, DatePickerCustomInput, DatePickerIcon,
  CardHeader, CardGrid, CardCarousel,
  SingleCard, ColorWrapper, CardTitle, CardBodyContainer, HeadlineText,
  FeatureItems, SingleFeatureItem, ItemAlignmentWrapper, ItemIcon, ItemText,
  PriceSection, PriceTextWrapper, Price, PriceDescription, ButtonSection, PurchaseButton,
} from './styles';

// TODO: Handle pico color override (do this when woodward takeovers are figured out, follows
// the same concept over on powdr-ui)
// TODO: Use DatePicker wrapper
export const QrQueryCards = ({
  colorProfile,
  title,
  componentSeason,
  relationships,
}) => {
  if (isRuntime()) return null; // prevent running due to usage of URL, does not exist @ build time
  const { isMobile, svgs } = useContext(AppContext);
  const { checkSeasonality } = useSeason();
  const today = new Date();
  const [currentDate, setCurrentDate] = useState(today);
  const [isDatePickerOpen, setDatePickerOpen] = useState(false);
  const location = useLocation();
  const { cardData } = relationships;
  const colorProfileString = colorProfileByIndex(colorProfile);

  const onDateChange = (date) => {
    setCurrentDate(date);
    setDatePickerOpen(!isDatePickerOpen);
  };

  const getProductLink = (href, productId) => `${href}?${productId}=${format(currentDate, 'yyyy-MM-dd')}${(location.href.indexOf('?') !== -1) ? `&${location.href.slice(location.href.indexOf('?') + 1)}` : ''}`;

  const CardMap = () => cardData.filter((card) => !card.isHideCard).map((card) => {
    const {
      id: cardId, cardTitle, cardSubtitle, relationships: { featureItems },
      productPriceDescription, productPrice, productId, isPicoColorOverride,
      buttonText, buttonLink,
    } = card;

    return (
      <SingleCard key={cardId} isPicoColorOverride={isPicoColorOverride}>
        <ColorWrapper colorProfile={colorProfileString}>
          <CardTitle>{cardTitle}</CardTitle>
          <CardBodyContainer>
            <HeadlineText>{cardSubtitle}</HeadlineText>
            <FeatureItems>
              {featureItems.map((item) => {
                const {
                  id, itemIconName, itemText, itemIconColor,
                } = item;
                const iconColor = itemIconColor || '#000000';

                return (
                  <SingleFeatureItem key={id}>
                    <ItemAlignmentWrapper>
                      <ItemIcon name={itemIconName} width="20" height="20" iconColor={iconColor} />
                      <ItemText>{itemText}</ItemText>
                    </ItemAlignmentWrapper>
                  </SingleFeatureItem>
                );
              })}
            </FeatureItems>
            <PriceSection>
              <PriceTextWrapper>
                <PriceDescription>{productPriceDescription}</PriceDescription>
                <Price>{productPrice}</Price>
              </PriceTextWrapper>
              <ButtonSection>
                <PurchaseButton href={getProductLink(buttonLink, productId)}>
                  {buttonText}
                </PurchaseButton>
              </ButtonSection>
            </PriceSection>
          </CardBodyContainer>
        </ColorWrapper>
      </SingleCard>
    );
  });

  return (
    checkSeasonality(componentSeason) && (
      <StyledQrQueryCards colorProfile={colorProfileString}>
        <OverlayHeader colorProfile={colorProfileString}>
          <OverlayLogo>
            <OverlayLogoLink title="Return to Homepage" href="/">
              {isMobile ? <svgs.SiteLogoMobile /> : <svgs.SiteLogoDesktop />}
            </OverlayLogoLink>
          </OverlayLogo>
          <OverlayCloseButton title="Return to Homepage" href="/">
            <CloseButtonText colorProfile={colorProfileString}>CLOSE</CloseButtonText>
            <CloseButtonIcon name="ui-close-circle" width="35" height="35" colorProfile={colorProfileString} />
          </OverlayCloseButton>
        </OverlayHeader>
        <AlignmentWrapper>
          <OverlayBody>
            <MainHeader>{title}</MainHeader>
            <DatePickerSection>
              <DatePickerSectionHeader>Select Date:</DatePickerSectionHeader>
              <DatePickerWrapper>
                <DatePickerCustomInput
                  onClick={() => setDatePickerOpen(!isDatePickerOpen)}
                  colorProfile={colorProfileString}
                  className={classNames({ open: isDatePickerOpen })}
                >
                  <DatePickerIcon colorProfile={colorProfileString} name="ui-calendar-picker" width="20" height="20" />
                  {format(currentDate, 'MMM dd yyyy')}
                </DatePickerCustomInput>
                {(isDatePickerOpen) && (
                <DatePicker
                  minDate={today}
                  dateFormat="MMM dd yyyy"
                  selected={currentDate}
                  onChange={(date) => onDateChange(date)}
                  inline
                />
                )}
              </DatePickerWrapper>
            </DatePickerSection>
            <CardHeader>Select Product:</CardHeader>
            {(!isMobile) ? (
              <CardGrid>{ CardMap() }</CardGrid>)
              : (
                <CardCarousel>
                  <ContentCarousel
                    isBottomIndicators
                    isPreviewSlides
                  >
                    { CardMap() }
                  </ContentCarousel>
                </CardCarousel>
              )}
          </OverlayBody>
        </AlignmentWrapper>
      </StyledQrQueryCards>
    )
  );
};

QrQueryCards.propTypes = {
  colorProfile: PropTypes.number,
  title: PropTypes.string,
  componentSeason: PropTypes.string,
  relationships: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        cardTitle: PropTypes.string,
        cardSubtitle: PropTypes.string,
        productPriceDescription: PropTypes.string,
        productPrice: PropTypes.string,
        productId: PropTypes.string,
        buttonText: PropTypes.string,
        buttonLink: PropTypes.string,
        isPicoColorOverride: PropTypes.bool,
        relationships: PropTypes.objectOf(
          PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string,
              itemIconName: PropTypes.string,
              itemText: PropTypes.string,
              itemIconColor: PropTypes.string,
            }),
          ),
        ),
      }),
    ),
  ).isRequired,
};

QrQueryCards.defaultProps = {
  colorProfile: '',
  title: '',
  componentSeason: null,
};
