import styled from 'styled-components';

import {
  ThemeProps as Prop, Components,
} from '@powdr/constants';
import { getDorComponentColor } from '@powdr/utils';

const CPNT = Components.DOR;

export const StyledDorWeather = styled.div`
  padding: 0 25px;

  .dw-status {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    min-height: 160px;
    align-items: center;
    gap: 40px;
    margin-top: 16px;
    margin-bottom: 16px;

    .slides {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .current {
      .time-of-day {
        background-color: ${({ isParentNavFeed, colorProfile }) => getDorComponentColor(isParentNavFeed, colorProfile, Prop.BTN_BG_ACTIVE, CPNT)};
        color: ${({ isParentNavFeed, colorProfile }) => getDorComponentColor(isParentNavFeed, colorProfile, Prop.BTN_TXT_ACTIVE, CPNT)};
        border-radius: 0.3125rem;
      }
    }

    .icon {
      background-color: ${({ isParentNavFeed, colorProfile }) => getDorComponentColor(isParentNavFeed, colorProfile, Prop.BACKGROUND, CPNT)};
      display: block;
      width: 32px;
      height: 32px;
      margin: 0 auto 15px;

      svg {
        fill: ${({ isParentNavFeed, colorProfile }) => getDorComponentColor(isParentNavFeed, colorProfile, Prop.ICON, CPNT)};
      }
    }

    .temperature,
    .forecast-short,
    .time-of-day {
      margin-bottom: 10px;
      text-align: center;
    }

    .time-of-day {
      display: inline-block;
      min-width: 96px;
      padding: 10px;
      position: relative;
      font-size: ${({ theme }) => theme.FontSize.REG30};
      ${({ theme }) => theme.fonts.PRI_BDY_MED};
      border-top: 1px solid ${({ isParentNavFeed, colorProfile }) => getDorComponentColor(isParentNavFeed, colorProfile, Prop.BORDER, CPNT)};
    }

    .temperature {
      font-size: ${({ theme }) => theme.FontSize.MED20};
      ${({ theme }) => theme.fonts.PRI_BDY_HVY};
      margin-bottom: 16px;
    }

    .forecast-short {
      font-size: ${({ theme }) => theme.FontSize.REG30};
      ${({ theme }) => theme.fonts.PRI_BDY_MED};
    }
  }

  .dw-details {
    p {
      line-height: 20px;
    }

    h3,
    small,
    p {
      margin-bottom: 16px;
      color: ${({ isParentNavFeed, colorProfile }) => getDorComponentColor(isParentNavFeed, colorProfile, Prop.CONTENT, CPNT)};
    }

    a {
      color: ${({ isParentNavFeed, colorProfile }) => getDorComponentColor(isParentNavFeed, colorProfile, Prop.LINK_TXT, CPNT)};

      &:hover {
        color: ${({ isParentNavFeed, colorProfile }) => getDorComponentColor(isParentNavFeed, colorProfile, Prop.LINK_TXT_HOVER, CPNT)};
      }
    }
  }

`;
