import React from 'react';

import { Icon } from '@powdr/components';
import { useStaticData } from '@powdr/web/src/hooks';

import { StyledGlobalFooter } from './styles';

export const GlobalFooter = () => {
  const { footerPropertyList } = useStaticData();

  return (
    <StyledGlobalFooter>
      <ul className="footer" role="navigation">
        {footerPropertyList.map((p) => (
          <li className="property-logo" key={p.iconName}>
            <a title={p.title} href={p.link} target="_blank" rel="noreferrer" aria-label={`${p.title} (opens in new tab)`}>
              <Icon name={p.iconName} height={p.height} className="icon-c" />
            </a>
          </li>
        ))}
      </ul>
    </StyledGlobalFooter>
  );
};

GlobalFooter.propTypes = {

};

GlobalFooter.defaultProps = {

};
