import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { CalendarBadge } from '@powdr/components/calendar-badge';
import { Link } from '@powdr/components/link';
import { BlockTypes, ThemeProps as Prop } from '@powdr/constants';
import { ContentBlockType } from '@powdr/model';
import { themeUtil } from '@powdr/utils';

const CPNT = BlockTypes.FLAG_V2;

// styled components
const ContentBlockFlagV2 = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 230px;

  .calendar-badge {
    .date-day-month-wrapper {
      border: none;

      .day-of-week-text {
        transition: all .3s ease-in-out;
        background-color: transparent;
      }

      .date-month-wrapper {
        .day-of-month-text {
          color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};
          margin-bottom: 10px;
        }
        .month {
          color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};
        }
      }
    }
  }

  &:hover:not(.overlay) {
    .inner {
      h3 {
        color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};
      }
    }

    .calendar-badge {
      .date-day-month-wrapper {
        .day-of-week-text {
          color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};
        }
      }
    }
  }

  .calendar-badge {
    width: 110px;
    position: absolute;
  }

  .cb-content-wrapper {
    position: relative;
    background: transparent;
    text-align: left;
    width: 100%;
    padding: 25px;
    z-index: 1;

    .inner {
      position: relative;
      margin-left: 124px;
    }

    .cb-title, .cb-copy {
      transition: .3s ease-in-out;
    }

    .cb-title {
      margin-bottom: 10px;
    }

    .cb-copy {
      margin-bottom: 28px;
      display: block;
    }

    .button {
      border-radius: 5px;
    }
  }

  .cb-content {
    position: relative;
  }
`;

export const FlagV2 = ({
  title, copy, isOverlayEnabled, colorProfile, blockType, ariaLabel,
  buttonEnabled, buttonText, buttonLink, buttonTarget, buttonClick,
  date, endDate,
}) => (
  <ContentBlockFlagV2
    href={buttonLink}
    target={buttonTarget}
    colorProfile={colorProfile}
    onClick={() => buttonClick()}
    aria-label={ariaLabel}
    role="button"
    className={classNames(`content-block-${blockType} `, { overlay: isOverlayEnabled })}
  >
    <div className="cb-content-wrapper">
      <div className="cb-content">
        <CalendarBadge
          type={blockType}
          startDate={date}
          endDate={endDate}
          colorProfile={colorProfile}
        />
        <div className="inner">
          {(title) && <h3 className="cb-title">{title}</h3>}
          {(copy) && <p className="cb-copy">{copy}</p>}
          {(buttonEnabled && buttonText) && (
            <button type="button" className="button uppercase with-background" aria-label={ariaLabel}>
              {buttonText}
            </button>
          )}
        </div>
      </div>
    </div>
  </ContentBlockFlagV2>
);

FlagV2.propTypes = PropTypes.objectOf(ContentBlockType).isRequired;
