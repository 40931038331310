import styled from 'styled-components';

import {
  ThemeProps as Prop, Components,
} from '@powdr/constants';
import { getDorComponentColor } from '@powdr/utils';

const CPNT = Components.DOR;

export const StyledDorSnowReport = styled.div`
  &.dor-snow-report {
    margin-bottom: 16px;

    .dor-snow-report-grid {
      display: flex;
      flex-wrap: wrap;

      .col {
        display: flex;
        justify-content: center;
        flex: 1;
        flex-direction: column;
        margin: 0 5px;

        &:first-child {
          margin: 0 5px 0 0;
        }

        &:last-child {
          margin: 0 0 0 5px;
        }
      }
    }

    .icon {
      justify-content: center;
      display: flex;
      margin: 0 auto;
      margin-bottom: 10px;
      svg {
        fill: ${({ isParentNavFeed, colorProfile }) => getDorComponentColor(isParentNavFeed, colorProfile, Prop.ICON, CPNT)};
      }
    }

    hr {
      width: 100%;
      height: 1px;
      background-color: ${({ isParentNavFeed, colorProfile }) => getDorComponentColor(isParentNavFeed, colorProfile, Prop.ICON, CPNT)};
      border: none;
      margin: 0;
    }

    h3.amount, p.duration  {
      color: ${({ isParentNavFeed, colorProfile }) => getDorComponentColor(isParentNavFeed, colorProfile, Prop.CONTENT, CPNT)};
      text-align: center;
      text-transform: capitalize;
    }

    h3.amount {
      ${({ theme }) => theme.fonts.PRI_BDY_BLK};
      font-size: 28px;
      margin-bottom: 12px;
    }

    p.duration {
      margin-top: 12px;
      font-size: 14px;
      ${({ theme }) => theme.fonts.PRI_BDY_MED};
    }
  }

`;
