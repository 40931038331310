import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Link } from '@powdr/components/link';
import { BlockTypes, ThemeProps as Prop } from '@powdr/constants';
import { useWindowResize } from '@powdr/hooks';
import { ContentBlockType } from '@powdr/model';
import {
  themeUtil, getBackgroundImageSrc, rgbaTransparencyToHex, clamp,
} from '@powdr/utils';

const CPNT = BlockTypes.FULL_ALT;

// styled components
const ContentBlockFullAlt = styled(Link)`
  display: block;
  position: relative;
  width: 100%;

  .cb-title {
    ${() => clamp(2)}
    margin-bottom: 15px;
  }

  .cb-copy {
    ${() => clamp(3)}
  }

  .full-alt-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    min-height: 330px;
    overflow: hidden;
    background-image: ${({ bgImage }) => `url('${bgImage}')`};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &:hover:not(.overlay) {
      &:before {
        transform: translateY(0);
      }
    }

    &:before {
      content: '';
      background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile) + rgbaTransparencyToHex(0.75)};
      transition: .3s ease-in-out;
      transform: translateY(100%);
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .cb-content {
      text-align: left;
      width: 100%;
      padding: 0 40px;
      background: transparent;
      position: relative;
      z-index: 1;

      .cb-title, .cb-copy {
        max-width: 100%;
      }

      .cb-copy {
        margin-bottom: 30px;
      }
    }
  }

  /* .content-block-group .full-height & {
     .full-alt-container {
      height: 100%;
    }
  } */
`;

export const FullAlt = ({
  title, copy, isOverlayEnabled, image, colorProfile, blockType, columnOption, ariaLabel,
  buttonEnabled, buttonText, buttonLink, buttonTarget, buttonClick, isStaticImageLink,
}) => {
  const [bgImage, setBgImage] = useState('');
  const resize = useWindowResize();

  useEffect(() => {
    if (resize.windowWidth) {
      setBgImage(
        (!isStaticImageLink)
          ? getBackgroundImageSrc(image?.src, resize.windowWidth, columnOption)
          : image.src,
      );
    }
  }, [resize.windowWidth]);

  return (
    <ContentBlockFullAlt
      href={buttonLink}
      target={buttonTarget}
      colorProfile={colorProfile}
      bgImage={bgImage}
      onClick={() => buttonClick()}
      aria-label={ariaLabel}
      role="button"
      className={`content-block-${blockType} `}
    >
      <div className={classNames('full-alt-container', { overlay: isOverlayEnabled })}>
        <div className="cb-content">
          {(title) && <h3 className="cb-title">{title}</h3>}
          {(copy) && <p className="cb-copy">{copy}</p>}
        </div>
        {(buttonEnabled && buttonText) && (
          <div className="cb-content">
            <button type="button" className="button with-background uppercase" aria-label={ariaLabel}>{buttonText}</button>
          </div>
        )}
      </div>
    </ContentBlockFullAlt>
  );
};

FullAlt.propTypes = PropTypes.objectOf(ContentBlockType).isRequired;
