import styled, { css } from 'styled-components';

import { Icon } from '@powdr/components/icon';
import {
  Breakpoints,
  Components,
  ComponentGroups,
  ThemeProps as Prop,
} from '@powdr/constants';
import { themeUtil, componentFontSize } from '@powdr/utils';

const CPNT = Components.UI_HEADER;

const getAlignment = (alignment = 'center') => {
  const alignmentMapping = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
  };

  return alignmentMapping[alignment] || alignmentMapping.center;
};

export const StyledUiHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignment }) => getAlignment(alignment)};
  padding: ${({ $paddingTop, $paddingBottom }) => `${$paddingTop}px 0px ${$paddingBottom}px`};

  iframe {
    overflow: hidden;
  }
`;

export const StyledIcon = styled(Icon)`
  margin-bottom: 30px;

  svg {
    fill: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
  }
`;

export const StyledHeaderContent = styled.div`
  text-align: ${({ alignment }) => alignment || 'center'};

  h1, h2, h3, h4, h5, h6 {
    color: ${({ colorProfile }) => themeUtil(Prop.HEADER, CPNT, colorProfile)};
    a { font-size: inherit; }
  }

  p, li, ul {
    color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
    ${({ theme }) => theme.fonts.PRI_BDY_MED};
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'content')}
    margin-bottom: 15px;
    line-height: 1.25;

    ${Breakpoints.large(css`
      ${() => componentFontSize(ComponentGroups.WYSIWIG, 'content-large-breakpoint')}
    `)}
  }

  h1 {
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h1')}
    margin-bottom: 40px;
  }

  h2 {
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h2')}
    margin-bottom: 20px;
  }

  h3 {
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h3')}
    margin-bottom: 15px;
  }

  h4 {
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h4')}
    margin-bottom: 10px;
  }

  h5 {
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h5')}
    margin-bottom: 10px;
  }

  h6 {
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h6')}
    margin-bottom: 5px;
  }

  table {
    margin-bottom: 15px;
  }

  li {
    line-height: 1.25;
  }

  a {
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'content')}
    color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};

    &:hover {
      color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};
    }

    ${Breakpoints.large(css`
      ${() => componentFontSize(ComponentGroups.WYSIWIG, 'content-large-breakpoint')}
    `)}
  }

  .btn {
    ${({ theme }) => theme.fonts.PRI_BDY_HVY};
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'button')}
    border: none;
    cursor: pointer;
    display: inline-block;
    position: relative;
    text-align: center;
    width: auto;
    height: auto;
    line-height: 1;
    padding: 16px 20px;
    text-transform: none;
    margin: 0;
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
    font-weight: bold;

    &:hover {
      color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    }
  }

  p {
    ${({ theme }) => theme.fonts.PRI_BDY_MED};
    margin-bottom: 15px;
    line-height: 1.25;
  }

  hr {
    margin: 30px 0;
    border: 1px solid ${({ colorProfile }) => themeUtil(Prop.BORDER, CPNT, colorProfile)};
  }
`;
