import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
  useEffect, useCallback, useContext, useMemo, useState, useRef,
} from 'react';
import useResizeObserver from 'use-resize-observer';

import { Link, Icon } from '@powdr/components';
import { SeasonalityPlacement } from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { useSeason } from '@powdr/hooks';
import { NavigationType } from '@powdr/model';
import { getLevelLink, getLevelName, isLevelLinkNormal } from '@powdr/utils';
import { useStaticData } from '@powdr/web/src/hooks';

import { StyledStickyNav } from './styles';

export const StickyNav = ({ levels, levelTwoNav, levelThreeNav }) => {
  const {
    isMobile,
    isWoodwardColorTakeover,
    setSecondLevelNavHeight,
    fullHeaderHeight,
  } = useContext(AppContext);
  const { headerConfig } = useStaticData();
  const mobileNav = useMemo(
    () => (levelThreeNav.length > 0 ? levelThreeNav : levelTwoNav),
    [levelTwoNav, levelThreeNav],
  );
  const { checkSeasonality } = useSeason();

  const mobileNavLinkCheck = useMemo(
    () => (levelThreeNav.length > 0 ? `/${levels[0]}/${levels[1]}/${levels[2]}` : `/${levels[0]}/${levels[1]}`),
    [levels, levelThreeNav],
  );

  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const onMobileNavToggle = useCallback(
    () => {
      setIsMobileNavOpen(!isMobileNavOpen);
    },
    [isMobileNavOpen],
  );

  const secondLevelNavRef = useRef(null);
  const { height } = useResizeObserver({ ref: secondLevelNavRef });

  useEffect(() => {
    setSecondLevelNavHeight((height === undefined) ? 0 : height);
  }, [height, setSecondLevelNavHeight]);

  return (
    <StyledStickyNav
      className={isWoodwardColorTakeover ? 'woodward-color-overrides' : ''}
      headerOffset={fullHeaderHeight}
      seasonalityTabOffset={headerConfig.seasonalityPlacement === SeasonalityPlacement.IN_SIDE_TAB}
      ref={secondLevelNavRef}
    >
      {!isMobile && (
        <div className="sticky-nav-desktop">
          <nav className="second-level">
            <ul className="l-group u-txt-center">
              {levelTwoNav.map((levelTwoItem) => (
                <li className="l-item u-inline" key={levelTwoItem.id}>
                  {isLevelLinkNormal(levelTwoItem) && (
                    <Link
                      className={classNames('btn', 'm-link', { 'm-active': getLevelLink(levelTwoItem).includes(`/${levels[1]}`) })}
                      href={getLevelLink(levelTwoItem)}
                    >
                      {getLevelName(levelTwoItem)}
                    </Link>
                  )}
                  { // This is a bit confusing; look up the second level children
                    // them mask the third level link with the second level name
                    levelTwoItem?.childPages
                      .filter((page) => checkSeasonality(page.season))
                      .map((levelThreeItem, idx) => (
                        (!isLevelLinkNormal(levelTwoItem) && idx === 0) && (
                          <Link
                            className={classNames('btn', 'm-link', { 'm-active': getLevelLink(levelTwoItem).includes(`/${levels[1]}`) })}
                            href={getLevelLink(levelThreeItem)}
                            key={levelTwoItem.id}
                          >
                            {getLevelName(levelTwoItem)}
                          </Link>
                        )
                      ))
                  }
                </li>
              ))}
            </ul>
          </nav>
        </div>
      )}
      {isMobile && (
        <div className={classNames('sticky-nav-mobile', { 'mobile-sticky-nav-open': isMobileNavOpen })}>
          {/* Mobile sticky active item for normal L2 and L3s */}
          <ul className="sticky-mobile-active">
            {mobileNav.filter(
              (mobileNavItem) => (mobileNavItem.link === mobileNavLinkCheck),
            ).map((mobileNavItem) => (
              <li className="active" key={mobileNavItem.id}>
                <button onClick={onMobileNavToggle} type="button">
                  <span>{getLevelName(mobileNavItem)}</span>
                  <Icon name="ui-chevron-thin" width="25" height="25" />
                </button>
              </li>
            ))}
          </ul>
          {/* Mobile sticky nav dropdown for L2 and L3s */}
          {isMobileNavOpen && (
            <ul className="sticky-mobile-dropdown">
              {mobileNav.filter(
                (mobileNavItem) => !(mobileNavItem.link === mobileNavLinkCheck),
              ).map((mobileNavItem) => (
                <li key={mobileNavItem.id}>
                  {isLevelLinkNormal(mobileNavItem) && (
                    <Link
                      className="m-link"
                      href={mobileNavItem.link}
                    >
                      {getLevelName(mobileNavItem)}
                    </Link>
                  )}
                  { // This is a bit confusing, look up the second level children
                    // them mask the third level link with the second level name
                    mobileNav?.filter(
                      (page) => checkSeasonality(page.season),
                    )
                      .map((levelThreeItem, idx) => (
                        (!isLevelLinkNormal(mobileNavItem) && idx === 0) && (
                          <Link
                            className="m-link"
                            href={mobileNavItem.link}
                            key={mobileNavItem.id}
                          >
                            {getLevelName(mobileNavItem)}
                          </Link>
                        )
                      ))
                  }
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </StyledStickyNav>
  );
};

StickyNav.propTypes = {
  levels: PropTypes.instanceOf(Array).isRequired,
  levelTwoNav: PropTypes.arrayOf(NavigationType).isRequired,
  levelThreeNav: PropTypes.arrayOf(NavigationType).isRequired,
};
