/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';

import {
  Components, Breakpoints, ThemeProps as Prop, ComponentGroups,
} from '@powdr/constants';
import {
  themeUtil, rgbaTransparencyToHex, clamp, componentFontSize,
} from '@powdr/utils';

const CPNT = Components.CONTENT_BLOCK;

export const ContentBlockWrapper = styled.div`
  position: relative;
  flex: 0 1 ${({
    columnOption, columnOptionMobile, isMobile, paddingAmount,
  }) => ((!columnOption || (isMobile && !columnOptionMobile))
    ? '100%'
    : `calc(${100 / ((isMobile) ? columnOptionMobile : columnOption)}% - ${(isMobile) ? (paddingAmount / columnOptionMobile) * (columnOptionMobile - 1) : (paddingAmount / columnOption) * (columnOption - 1)}px)`)};

  h3 {
    ${({ theme }) => theme.fonts.PRI_HDR_BLK};
    color: ${({ colorProfile }) => themeUtil(Prop.HEADER, CPNT, colorProfile)};

    ${Breakpoints.large(css`
      font-size: ${({ theme }) => theme.FontSize.MED30};
    `)}

    ${Breakpoints.standard(css`
      font-size: ${({ theme }) => theme.FontSize.MED30};
    `)}

    ${Breakpoints.small(css`
      font-size: ${({ theme }) => theme.FontSize.MED10};
    `)}

    ${Breakpoints.mobile(css`
      font-size: ${({ theme }) => theme.FontSize.MED30};
    `)}

    ${Breakpoints.portrait(css`
      font-size: ${({ theme }) => theme.FontSize.MED30};
    `)}
  }

  p, strong {
    ${({ theme }) => theme.fonts.PRI_BDY_MED};
    font-size: ${({ theme }) => theme.FontSize.REG30};
    color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};

    ${Breakpoints.large(css`
      font-size: ${({ theme }) => theme.FontSize.REG40};
    `)}

    ${Breakpoints.larger(css`
      font-size: ${({ theme }) => theme.FontSize.REG50};
    `)}
  }

  .cb-title {
    color: ${({ colorProfile }) => themeUtil(Prop.HEADER, CPNT, colorProfile)};
  }

  .cb-copy {
    line-height: normal;
    color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
  }

  small {
    ${({ theme }) => theme.fonts.PRI_BDY_MED};
    color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
  }

  .button {
    ${({ theme }) => theme.fonts.PRI_BDY_HVY};
    font-weight: bold;
    line-height: normal;
    padding: 0;
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};

    &:hover {
      color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};

      svg {
        fill: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
      }
    }

    svg {
      fill: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
    }

    &.with-background {
      padding: 12px 16px;
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};

      &:hover {
        background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
      }
    }

    &.uppercase {
      text-transform: ${({ theme }) => ((theme?.HeaderTransform) ? theme.HeaderTransform : 'uppercase')};
    }

    &.capitalize {
      text-transform: ${({ theme }) => ((theme?.HeaderTransform) ? theme.HeaderTransform : 'capitalize')};
    }

    ${Breakpoints.large(css`
      font-size: ${({ theme }) => theme.FontSize.REG40};
    `)}

    ${Breakpoints.standard(css`
      font-size: ${({ theme }) => theme.FontSize.REG40};
    `)}

    ${Breakpoints.small(css`
      font-size: ${({ theme }) => theme.FontSize.REG30};
    `)}

    ${Breakpoints.portrait(css`
      font-size: ${({ theme }) => theme.FontSize.REG20};
    `)}
  }

  .cb-icon {
    svg {
      fill: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
    }
  }

  .range-dash {
    display: inline-block;
    vertical-align: middle;
    margin-top: -7px;
  }

  [class*="content-block-"] {
    ${({ isFullHeight }) => ((isFullHeight) ? 'height: 100%;' : '')}
  }

  .cb-formatted-content {
    li, p {
      ${({ theme }) => theme.fonts.PRI_BDY_MED};
      line-height: 1.1;
      margin-bottom: 8px;

      > ul {
        margin-top: 8px;
      }
    }

    ul {
      li {
        list-style: disc outside none;
        margin-left: 15px;
      }
    }

    li {
      font-size: ${({ theme }) => theme.FontSize.REG30};
      color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
    }
  }
`;

export const ContentBlockOverlay = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 18;
  background-color: ${() => `#000000${rgbaTransparencyToHex(0.75)}`};

  ${({ blockType }) => {
    if (blockType === 'flag') {
      return `
        padding: 15px;

        .overlay-title {
          margin-bottom: 0px;
        }

        .overlay-message {
          margin: 0 10px;
          ${() => clamp(3)}
        }
      `;
    }

    if (blockType === 'image-flag') {
      return `
        justify-content: center;
        text-align: center;
        flex-direction: column;
        padding: 15px;

        .overlay-title {
          margin-bottom: 10px;
          ${() => clamp(2)}
        }

        .overlay-message {
          margin-bottom: 10px;
          ${() => clamp(2)}
        }
      `;
    }

    return `
      justify-content: center;
      text-align: center;
      flex-direction: column;
      padding: 50px;

      .overlay-title {
        margin-bottom: 15px;
        ${() => clamp(2)}
      }

      .overlay-message {
        margin-bottom: 15px;
        ${() => clamp(4)}
      }
    `;
  }}

  .overlay-title {
    color: white;
    ${({ theme }) => theme.fonts.PRI_HDR_BLK};
  }

  .overlay-message {
    color: white;
  }
`;

export const ExpandCollapseContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  transition: height 0.6s ease-out, padding 0.1s ease-out 0.4s;
  overflow: hidden;
  padding: 20px;
  height: 100%;
  width: 100%;
`;

export const ExpandCollapseContent = styled.div`
  width: 100%;
  animation-delay: 0.6s;

  p, li, ul {
    color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
  }

  h1, h2, h3, h4, h5, h6 {
    color: ${({ colorProfile }) => themeUtil(Prop.HEADER, CPNT, colorProfile)};
    a { font-size: inherit; }
  }

  svg {
    fill: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
  }

  a {
    transition: color .25s ease-out, background-color .25s ease-out;
    color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};

    &:hover {
      color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};
    }
  }

  .btn {
    ${({ theme }) => theme.fonts.PRI_BDY_HVY};
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'button')}
    border: none;
    cursor: pointer;
    display: inline-block;
    position: relative;
    text-align: center;
    width: auto;
    height: auto;
    padding: 16px 20px;
    text-transform: none;
    margin: 0;
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};

    &:hover {
      color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    }
  }

  h1 {
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h1')}
    margin-bottom: 40px;
  }

  h2 {
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h2')}
    margin-bottom: 20px;
  }

  h3 {
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h3')}
    margin-bottom: 15px;
  }

  h4 {
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h4')}
    margin-bottom: 10px;
  }

  h5 {
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h5')}
    margin-bottom: 10px;
  }

  h6 {
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h6')}
    margin-bottom: 5px;
  }

  ul, ol {
    margin-bottom: 25px;
  }

  ul {
    li {
      list-style: disc outside none;
      margin-left: 15px;
      margin-bottom: 5px;
      line-height: 1.25;
    }
  }

  ol {
    li {
      list-style: decimal;
      margin-left: 15px;
      margin-bottom: 5px;
    }
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  li, p, table {
    ${({ theme }) => theme.fonts.PRI_BDY_MED};
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'content')}

    ${Breakpoints.large(css`
      ${() => componentFontSize(ComponentGroups.WYSIWIG, 'content-large-breakpoint')}
    `)}
  }

  p {
    line-height: 1.25;
    margin-bottom: 15px
  }

  table {
    width: 100%;
    line-height: 25px;
    margin-bottom: 15px;

    td {
      font-size: 16px;
    }
  }
`;

export const CloseButton = styled.button`
  animation-delay: 0.6s;
  margin-left: 15px;

  svg {
    fill: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
  }

  &:hover {
    svg {
      fill: ${({ colorProfile }) => themeUtil(Prop.ICON_HOVER, CPNT, colorProfile)};
    }
  }
`;

export const VideoModalPositionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const FrameWrapper = styled.div`
  position: relative;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  width: 75%;
  padding-bottom: 42.1875%;

  .video-block-modal-frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
