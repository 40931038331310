import styled, { css } from 'styled-components';

import { Icon } from '@powdr/components/icon';
import { Link } from '@powdr/components/link';
import { Breakpoints, Components, ThemeProps as Prop } from '@powdr/constants';
import { themeUtil, rgbaTransparencyToHex } from '@powdr/utils';

const CPNT = Components.SECTION_CONTENT_TABS;

// TODO: handle WW color profile changes with theme utilities
// TODO: animate opening/closing of tab on mobile (used to use TweenLite in Angular)
export const StyledSectionContentTabs = styled.div`
  background-image: url(${({ bgImage }) => bgImage});
  background-size: cover;
  background-repeat: no-repeat;
`;

export const SectionHeader = styled.h2`
  background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile) + rgbaTransparencyToHex(0.7)};
  color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
  font-size: 28px;
  margin: 0;
  padding: 30px 10%;
  text-align: ${({ alignment }) => alignment};
  ${Breakpoints.mobile(css`
    display: none;
  `)}
`;

export const ContentSection = styled.div`
  background-color: ${() => `#FFFFFF${rgbaTransparencyToHex(0.7)}`};
  padding: 25px 0;
`;

export const TabsWrapper = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin: 0 10%;

  ${Breakpoints.mobile(css`
    flex-direction: column;
    margin: 0 20px;
  `)}

  ${Breakpoints.small(css`
    margin: 0 5%;
  `)}
`;

export const IndividualTabWrapper = styled.li`
  display: flex;
  width: 100%;
  margin-right: 10px;
  margin-bottom: 0;
  text-align: left;
  position: relative;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  ${Breakpoints.mobile(css`
    margin-right: 0;
    margin-bottom: 15px;
  `)}
`;

export const IndividualTab = styled.div`
  background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};
  display: flex;
  border-radius: 7px;
  width: 100%;

  ${Breakpoints.mobile(css`
    display: block;
  `)}
`;

export const ActiveTabTopAccent = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  height: 8px;
  top: -4px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  background-color:  ${({ colorProfile }) => themeUtil(Prop.ELEMENT, CPNT, colorProfile)};

  ${Breakpoints.mobile(css`
    display: none;
  `)}
`;

export const ActiveTabConnector = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  height: 25px;
  bottom: -19px;
  background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};

  ${Breakpoints.mobile(css`
    display: none;
  `)}
`;

export const LeftConnectorRounding = styled.div`
  border-width: 0 0 1px 0;
  position: absolute;
  bottom: -16px;
  width: 6px;
  height: 6px;
  border-bottom: 1px solid ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};
  box-shadow: 2px 2px 0 ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};
  border-bottom-right-radius: 10px;
  left: -6px;
  ${Breakpoints.mobile(css`
    display: none;
  `)}
`;

export const RightConnectorRounding = styled.div`
  border-width: 0 0 1px 0;
  position: absolute;
  bottom: -16px;
  width: 6px;
  height: 6px;
  border-bottom: 1px solid ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};
  box-shadow: -2px 2px 0 ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};
  border-bottom-left-radius: 10px;
  right: -6px;

  ${Breakpoints.mobile(css`
    display: none;
  `)}
`;

export const TabIconTextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 10px;

  ${Breakpoints.mobile(css`
    margin: 10px 17px;
  `)}
`;

export const TabTitle = styled.h3`
  display: inline-block;
  margin: 0;
  padding-left: 10px;
  line-height: 22px;
  font-size: ${({ theme }) => theme.FontSize.REG50};
  color: ${({ colorProfile }) => themeUtil(Prop.HEADER, CPNT, colorProfile)};

  ${Breakpoints.mobile(css`
    padding: 0 43px 0 15px;
    font-size: ${({ theme }) => theme.FontSize.REG50};
  `)}

  ${Breakpoints.small(css`
  font-size: ${({ theme }) => theme.FontSize.REG40};
  `)}
`;

export const TabButtonIcon = styled(Icon)`
  margin-left: 5px;
  min-width: 25px;

  svg {
    fill: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
  }
`;

export const AccordionIndicator = styled(Icon)`
  animation-direction: alternate;
  transition: transform 0.5s ease-in-out;
  position: absolute;
  right: 20px;

  ${({ activeTab }) => {
    if (activeTab) {
      return 'transform: rotate(180deg);';
    }

    return 'transform: rotate(0deg);';
  }}

  svg {
    fill: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
  }

  @media only screen and (max-width: (440px - 1px)) {
    position: static;
    padding-left: 5px;
  }
`;

export const ActiveContentWrapper = styled.div`
  ${Breakpoints.desktopOnly(css`
    background-color: transparent;
  `)}

  ${({ activeTab, isMobile }) => {
    if (!activeTab && isMobile) {
      return `
        height: 0;
        overflow: hidden;
      `;
    }

    return '';
  }}
`;

export const DesktopActiveContentWrapper = styled.div`
  border-radius: 7px;
  margin: 15px 10% 0 10%;
  display: flex;
  align-items: flex-start;

  ${Breakpoints.desktopOnly(css`
    background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};
  `)}

  ${Breakpoints.small(css`
    margin: 15px 5% 0 5%;
  `)}
`;

export const ActiveContentOuterContainer = styled.div`
  display: flex;
  padding: 30px;
  border-radius: 5px;
  width: 100%;
`;

export const ContentCopyIcon = styled(Icon)`
  svg {
    fill: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
  }
`;

export const TabContent = styled.div`
  width: 100%;
  padding-left: 25px;

  ${Breakpoints.mobile(css`
    padding: 0;
  `)}
`;

export const ContentTitle = styled.h2`
  font-size: ${({ theme }) => theme.FontSize.LRG10};
  margin-bottom: 12px;
  color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
  font-size: ${({ theme }) => theme.FontSize.LRG12};

  ${Breakpoints.mobile(css`
    display: none;
  `)}
`;

export const ContentCopy = styled.p`
  ${({ theme }) => theme.fonts.PRI_BDY_MED};
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 15px;
  color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};

  ${Breakpoints.mobile(css`
    margin: 0 26px;
  `)}
`;

export const CTAList = styled.ul`

  ${Breakpoints.mobile(css`
    display: flex;
    flex-direction: row;
    text-align: center;
    flex-direction: column;
  `)}
`;

// this referece to li.cta-button in original template
export const CTAItem = styled.li`
  display: inline-block;
  text-align: center;
  margin: 10px 15px 10px 15px;

  &:first-child {
    margin: 10px 15px 10px 0;
  }

  ${Breakpoints.mobile(css`
    &:first-child {
      margin: 10px 15px 10px 15px;
    }
  `)}
`;

// this refers to a.btn in original template
export const CTAButton = styled(Link)`
  display: inline-block;
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  font-size: 16px;
  border-radius: 10px;
  text-transform: uppercase;
  min-width: 200px;
  padding: 10px 20px;
  background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
  color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};

  &:hover {
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
  }

  ${Breakpoints.mobile(css`
    display: inline-block;
    min-width: 0;
    border-radius: 10px;
    text-transform: uppercase;
    width: 85%;
    padding: 10px 20px;
  `)}
`;
