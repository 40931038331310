import ContentAccessibility from './content-accessibility.svg';
import ContentAgeGroup from './content-age-group.svg';
import ContentAid from './content-aid.svg';
import ContentAirConditioning from './content-air-conditioning.svg';
import ContentArtTools from './content-art-tools.svg';
import ContentBackpack from './content-backpack.svg';
import ContentBarn from './content-barn.svg';
import ContentBbq from './content-bbq.svg';
import ContentBicycle2 from './content-bicycle-2.svg';
import ContentBicycle from './content-bicycle.svg';
import ContentBinoculars from './content-binoculars.svg';
import ContentBullseye from './content-bullseye.svg';
import ContentBurger from './content-burger.svg';
import ContentBus from './content-bus.svg';
import ContentCalendar from './content-calendar.svg';
import ContentCamera from './content-camera.svg';
import ContentCamp from './content-camp.svg';
import ContentCampground from './content-campground.svg';
import ContentCar from './content-car.svg';
import ContentCartAlt from './content-cart-alt.svg';
import ContentCart from './content-cart.svg';
import ContentCellphone from './content-cellphone.svg';
import ContentChatbox from './content-chatbox.svg';
import ContentCircleStar from './content-circle-star.svg';
import ContentClock from './content-clock.svg';
import ContentCloseX from './content-close-x.svg';
import ContentCloudySun from './content-cloudy-sun.svg';
import ContentContributions from './content-contributions.svg';
import ContentCounter from './content-counter.svg';
import ContentDesert from './content-desert.svg';
import ContentEarth from './content-earth.svg';
import ContentElectricPump from './content-electric-pump.svg';
import ContentEnvironment from './content-environment.svg';
import ContentExerciseBike from './content-exercise-bike.svg';
import ContentExpand from './content-expand.svg';
import ContentEyeball from './content-eyeball.svg';
import ContentFarm from './content-farm.svg';
import ContentFastTracks from './content-fast-tracks.svg';
import ContentFeedback from './content-feedback.svg';
import ContentFireplace from './content-fireplace.svg';
import ContentFoodDonation from './content-food-donation.svg';
import ContentGarbageTruck from './content-garbage-truck.svg';
import ContentGas from './content-gas.svg';
import ContentGlutenFree from './content-gluten-free.svg';
import ContentGondola from './content-gondola.svg';
import ContentHandleaf from './content-handleaf.svg';
import ContentHandshakeAlt from './content-handshake-alt.svg';
import ContentHandshake from './content-handshake.svg';
import ContentHangLoose from './content-hang-loose.svg';
import ContentHeadquarters from './content-headquarters.svg';
import ContentHeadshot from './content-headshot.svg';
import ContentHeartHands from './content-heart-hands.svg';
import ContentHearts from './content-hearts.svg';
import ContentHeli from './content-heli.svg';
import ContentHighFive2 from './content-highfive-2.svg';
import ContentHighfive from './content-highfive.svg';
import ContentHospitalLodging from './content-hospital-lodging.svg';
import ContentHotelRoom from './content-hotel-room.svg';
import ContentHottubShared from './content-hottub-shared.svg';
import ContentHottub from './content-hottub.svg';
import ContentInfoAlt from './content-info-alt.svg';
import ContentInfoClose from './content-info-close.svg';
import ContentInfoOpen from './content-info-open.svg';
import ContentKitchenTools from './content-kitchen-tools.svg';
import ContentLaundry from './content-laundry.svg';
import ContentLessons from './content-lessons.svg';
import ContentLetterBlocks from './content-letter-blocks.svg';
import ContentLift from './content-lift.svg';
import ContentLightbulbAlt from './content-lightbulb-alt.svg';
import ContentLightbulb from './content-lightbulb.svg';
import ContentLockers from './content-lockers.svg';
import ContentLodge from './content-lodge.svg';
import ContentMap from './content-map.svg';
import ContentMask from './content-mask.svg';
import ContentMedalAlt from './content-medal-alt.svg';
import ContentMedal from './content-medal.svg';
import ContentMegaphone from './content-megaphone.svg';
import ContentMembership from './content-membership.svg';
import ContentMessages from './content-messages.svg';
import ContentMobileOrder from './content-mobile-order.svg';
import ContentMountainDetailed from './content-mountain-detailed.svg';
import ContentMountainTrail from './content-mountain-trail.svg';
import ContentMountain from './content-mountain.svg';
import ContentNews from './content-news.svg';
import ContentNoPets from './content-no-pets.svg';
import ContentNonSmoking from './content-non-smoking.svg';
import ContentOutlet from './content-outlet.svg';
import ContentPark from './content-park.svg';
import ContentParking from './content-parking.svg';
import ContentPartners from './content-partners.svg';
import ContentPassCopper from './content-pass-copper.svg';
import ContentPassFastTracks from './content-pass-fast-tracks.svg';
import ContentPassKillington from './content-pass-killington.svg';
import ContentPassMtbachelor from './content-pass-mtbachelor.svg';
import ContentPeace from './content-peace.svg';
import ContentPeople from './content-people.svg';
import ContentPet from './content-pet.svg';
import ContentPhone from './content-phone.svg';
import ContentPhotos from './content-photos.svg';
import ContentPinHollow from './content-pin-hollow.svg';
import ContentPin from './content-pin.svg';
import ContentPlant2 from './content-plant-2.svg';
import ContentPlant from './content-plant.svg';
import ContentPlayHollow from './content-play-hollow.svg';
import ContentPlay from './content-play.svg';
import ContentPowdr from './content-powdr.svg';
import ContentPower from './content-power.svg';
import ContentPriceTag from './content-price-tag.svg';
import ContentQrCode from './content-qr-code.svg';
import ContentRaisedHands from './content-raised-hands.svg';
import ContentRecycle from './content-recycle.svg';
import ContentRv from './content-rv.svg';
import ContentShinyHeart from './content-shiny-heart.svg';
import ContentShopping from './content-shopping.svg';
import ContentShovel from './content-shovel.svg';
import ContentSkateboard from './content-skateboard.svg';
import ContentSkiLift from './content-ski-lift.svg';
import ContentSkier from './content-skier.svg';
import ContentSkisPoles from './content-skis-poles.svg';
import ContentSkis from './content-skis.svg';
import ContentSnowCannon from './content-snow-cannon.svg';
import ContentSnowCat from './content-snow-cat.svg';
import ContentSnowflake from './content-snowflake.svg';
import ContentSnowmaking from './content-snowmaking.svg';
import ContentSnowshoe from './content-snowshoe.svg';
import ContentSnowyMountain from './content-snowy-mountain.svg';
import ContentSolarPower from './content-solar-power.svg';
import ContentSun from './content-sun.svg';
import ContentSupport from './content-support.svg';
import ContentSwimming from './content-swimming.svg';
import ContentSwitch from './content-switch.svg';
import ContentTableware from './content-tableware.svg';
import ContentTakeout from './content-takeout.svg';
import ContentTent from './content-tent.svg';
import ContentTree from './content-tree.svg';
import ContentTrees from './content-trees.svg';
import ContentTrophy from './content-trophy.svg';
import ContentTube2 from './content-tube-2.svg';
import ContentTube from './content-tube.svg';
import ContentTv from './content-tv.svg';
import ContentVegetables from './content-vegetables.svg';
import ContentVip from './content-vip.svg';
import ContentWallet from './content-wallet.svg';
import ContentWaterHand from './content-water-hand.svg';
import ContentWater from './content-water.svg';
import ContentWaterbottle from './content-waterbottle.svg';
import ContentWaves from './content-waves.svg';
import ContentWebcam from './content-webcam.svg';
import ContentWhatBadge1 from './content-what-badge-1.svg';
import ContentWhatBadge2 from './content-what-badge-2.svg';
import ContentWifi from './content-wifi.svg';
import ContentX7 from './content-x7.svg';
import DecorationBlur from './decoration-blur.svg';
import DecorationHashMarks from './decoration-hash-marks.svg';
import DifficultyLevelBlack2Outline from './difficulty-level-black-2-outline.svg';
import DifficultyLevelBlack2Pill from './difficulty-level-black-2-pill.svg';
import DifficultyLevelBlack2Technical from './difficulty-level-black-2-technical.svg';
import DifficultyLevelBlack2WhiteOutline from './difficulty-level-black-2-white-outline.svg';
import DifficultyLevelBlack2 from './difficulty-level-black-2.svg';
import DifficultyLevelBlack3WhiteOutline from './difficulty-level-black-3-white-outline.svg';
import DifficultyLevelBlack3 from './difficulty-level-black-3.svg';
import DifficultyLevelBlackOutline from './difficulty-level-black-outline.svg';
import DifficultyLevelBlackPill from './difficulty-level-black-pill.svg';
import DifficultyLevelBlackTechnical from './difficulty-level-black-technical.svg';
import DifficultyLevelBlackWhiteOutline from './difficulty-level-black-white-outline.svg';
import DifficultyLevelBlack from './difficulty-level-black.svg';
import DifficultyLevelBlueOutline from './difficulty-level-blue-outline.svg';
import DifficultyLevelBluePill from './difficulty-level-blue-pill.svg';
import DifficultyLevelBlueTechnical from './difficulty-level-blue-technical.svg';
import DifficultyLevelBlueWhiteOutline from './difficulty-level-blue-white-outline.svg';
import DifficultyLevelBlue from './difficulty-level-blue.svg';
import DifficultyLevelGreenOutline from './difficulty-level-green-outline.svg';
import DifficultyLevelGreenPill from './difficulty-level-green-pill.svg';
import DifficultyLevelGreenTechnical from './difficulty-level-green-technical.svg';
import DifficultyLevelGreenWhiteOutline from './difficulty-level-green-white-outline.svg';
import DifficultyLevelGreen from './difficulty-level-green.svg';
import DifficultyLevelHiking from './difficulty-level-hiking.svg';
import DifficultyLevelProline from './difficulty-level-proline.svg';
import DifficultyLevelUphill from './difficulty-level-uphill.svg';
import DorChanceflurries from './dor-chanceflurries.svg';
import DorChancerain from './dor-chancerain.svg';
import DorChancesleat from './dor-chancesleat.svg';
import DorChancesnow from './dor-chancesnow.svg';
import DorChancetstorms from './dor-chancetstorms.svg';
import DorClear from './dor-clear.svg';
import DorCloudy from './dor-cloudy.svg';
import DorFlurries from './dor-flurries.svg';
import DorHazy from './dor-hazy.svg';
import DorMostlycloudy from './dor-mostlycloudy.svg';
import DorMostlysunny from './dor-mostlysunny.svg';
import DorNtChanceflurries from './dor-nt_chanceflurries.svg';
import DorNtChancerain from './dor-nt_chancerain.svg';
import DorNtChancesleat from './dor-nt_chancesleat.svg';
import DorNtChancesnow from './dor-nt_chancesnow.svg';
import DorNtChancestorms from './dor-nt_chancetstorms.svg';
import DorNtClear from './dor-nt_clear.svg';
import DorNtCloudy from './dor-nt_cloudy.svg';
import DorNtFlurries from './dor-nt_flurries.svg';
import DorNtHazy from './dor-nt_hazy.svg';
import DorNtMostlycloudy from './dor-nt_mostlycloudy.svg';
import DorNtRain from './dor-nt_rain.svg';
import DorNtSleat from './dor-nt_sleat.svg';
import DorNtSnow from './dor-nt_snow.svg';
import DorNtTstorms from './dor-nt_tstorms.svg';
import DorNtUnknown from './dor-nt_unknown.svg';
import DorPartlycloudy from './dor-partlycloudy.svg';
import DorPartlysunny from './dor-partlysunny.svg';
import DorRain from './dor-rain.svg';
import DorSleat from './dor-sleat.svg';
import DorSnow from './dor-snow.svg';
import DorSunny from './dor-sunny.svg';
import DorTstorms from './dor-tstorms.svg';
import DorUnknown from './dor-unknown.svg';
import DorWind from './dor-wind.svg';
import EcommAdventureMulti from './ecomm-adv-multi.svg';
import EcommAdventureSingle from './ecomm-adv-single.svg';
import EcommGondola from './ecomm-gondola.svg';
import EcommMtbMulti from './ecomm-mtb-multi.svg';
import EcommMtbSingle from './ecomm-mtb-single.svg';
import EcommMultiDay from './ecomm-multi-day.svg';
import EcommSecureCheckout from './ecomm-secure-checkout.svg';
import EcommSingleDay from './ecomm-single-day.svg';
import FeatureFinishline from './feature-finishline.svg';
import FeatureGladed from './feature-gladed.svg';
import FeatureGroomedAm from './feature-groomed-am.svg';
import FeatureGroomedPm from './feature-groomed-pm.svg';
import FeatureGroomedRecent from './feature-groomed-recent.svg';
import FeatureGroomed from './feature-groomed.svg';
import FeatureRace from './feature-race.svg';
import FeatureSnowflake from './feature-snowflake.svg';
import FeatureSnowmaking24Hours from './feature-snowmaking-24-hours.svg';
import FeatureSnowmakingCapable from './feature-snowmaking-capable.svg';
import FeatureSnowmakingProgess from './feature-snowmaking-progress.svg';
import FeatureTerrainPark from './feature-terrain-park.svg';
import FeatureTerrainParkExtraSmallSmall from './feature-terrain-park_extra-small-small.svg';
import FeatureTerrainParkExtraSmall from './feature-terrain-park_extra-small.svg';
import FeatureTerrainParkLarge from './feature-terrain-park_large.svg';
import FeatureTerrainParkMediumLarge from './feature-terrain-park_medium-large.svg';
import FeatureTerrainParkMedium from './feature-terrain-park_medium.svg';
import FeatureTerrainParkSmallMediumLarge from './feature-terrain-park_small-medium-large.svg';
import FeatureTerrainParkSmallMedium from './feature-terrain-park_small-medium.svg';
import FeatureTerrainParkSmall from './feature-terrain-park_small.svg';
import LiftDouble from './lift-double.svg';
import LiftGondola from './lift-gondola.svg';
import LiftQuad from './lift-quad.svg';
import LiftTriple from './lift-triple.svg';
import LoaderCoppercolorado from './loader-coppercolorado.svg';
import LoaderEldora from './loader-eldora.svg';
import LoaderKillington from './loader-killington.svg';
import LoaderLeecanyonlv from './loader-leecanyonlv.svg';
import LoaderMtbachelor from './loader-mtbachelor.svg';
import LoaderPicomountain from './loader-picomountain.svg';
import LoaderPowdr from './loader-powdr.svg';
import LoaderPowdrplayitforward from './loader-powdrplayitforward.svg';
import LoaderRideboreal from './loader-rideboreal.svg';
import LoaderSkisodasprings from './loader-skisodasprings.svg';
import LoaderSuncountrytours from './loader-suncountrytours.svg';
import LoaderWoodward from './loader-woodward.svg';
import Partner10barrel from './partner-10barrel.svg';
import PartnerActionsportscamp from './partner-actionsportscamp.svg';
import PartnerActionsportshub from './partner-actionsportshub.svg';
import PartnerActionsportspark from './partner-actionsportspark.svg';
import PartnerAdventureWagon from './partner-adventure-wagon.svg';
import PartnerAnon from './partner-anon.svg';
import PartnerBachelorBike from './partner-bachelor-bike.svg';
import PartnerBannister from './partner-bannister.svg';
import PartnerBlackStrap from './partner-black-strap.svg';
import PartnerBudLight from './partner-bud-light.svg';
import PartnerBurton from './partner-burton.svg';
import PartnerCocaCola from './partner-coca-cola.svg';
import PartnerCoorsLightAlt from './partner-coors-light-alt.svg';
import PartnerCoorsLight from './partner-coors-light.svg';
import PartnerDakine from './partner-dakine.svg';
import PartnerDickies from './partner-dickies.svg';
import PartnerDosEquis from './partner-dos-equis.svg';
import PartnerEarthcruiser from './partner-earthcruiser.svg';
import PartnerFallsCreek from './partner-falls-creek.svg';
import PartnerFirstBank from './partner-first-bank.svg';
import PartnerForestService from './partner-forest-service.svg';
import PartnerFreepublic from './partner-freepublic.svg';
import PartnerGatorade from './partner-gatorade.svg';
import PartnerGooseIsland from './partner-goose-island.svg';
import PartnerGopro from './partner-gopro.svg';
import PartnerHappyCamper from './partner-happy-camper.svg';
import PartnerHotWheels from './partner-hot-wheels.svg';
import PartnerHovland from './partner-hovland.svg';
import PartnerInnov8te from './partner-innov8te.svg';
import PartnerIntermountainHealthcare from './partner-intermountain-healthcare.svg';
import PartnerKaltire from './partner-kaltire.svg';
import PartnerMammut from './partner-mammut.svg';
import PartnerMichelob from './partner-michelob.svg';
import PartnerMonster from './partner-monster.svg';
import PartnerMountainparks from './partner-mountainparks.svg';
import PartnerMtBachelor from './partner-mt-bachelor.svg';
import PartnerMtndew from './partner-mtndew.svg';
import PartnerNeff from './partner-neff.svg';
import PartnerNeverSummer from './partner-never-summer.svg';
import PartnerNokian from './partner-nokian.svg';
import PartnerNorco from './partner-norco.svg';
import PartnerNswg from './partner-nswg.svg';
import PartnerOdellCrown from './partner-odell-crown.svg';
import PartnerOdellLeaf from './partner-odell-leaf.svg';
import PartnerPepsiZero from './partner-pepsi-zero.svg';
import PartnerPepsi from './partner-pepsi.svg';
import PartnerPineMountain from './partner-pinemountain.svg';
import PartnerPistenBully from './partner-pisten-bully.svg';
import PartnerPlayforever from './partner-playforever.svg';
import PartnerPlayitforward from './partner-playitforward.svg';
import PartnerPowderbird from './partner-powderbird.svg';
import PartnerPowdr from './partner-powdr.svg';
import PartnerPowerade from './partner-powerade.svg';
import PartnerRedBull from './partner-red-bull.svg';
import PartnerRiversSystem from './partner-rivers-system.svg';
import PartnerRobertModavi from './partner-robert-modavi.svg';
import PartnerSkullcandy from './partner-skullcandy.svg';
import PartnerSkye from './partner-skye.svg';
import PartnerSpy from './partner-spy.svg';
import PartnerSubaruMs from './partner-subaru-ms.svg';
import PartnerSubaru from './partner-subaru.svg';
import PartnerSvedka from './partner-svedka.svg';
import PartnerUnderArmour from './partner-under-armour.svg';
import PartnerUpslope from './partner-upslope.svg';
import PartnerVibram from './partner-vibram.svg';
import PartnerWeston from './partner-weston.svg';
import PartnerWoodwardtv from './partner-woodwardtv.svg';
import PartnerWw2021 from './partner-ww2021.svg';
import PartnerYeti from './partner-yeti.svg';
import PartnerZeal from './partner-zeal.svg';
// TODO: Can we make this dynamic based on which site is building?
import PropertyBorealIcon from './property-boreal-icon.svg';
import PropertyBorealWoodward from './property-boreal-woodward.svg';
import PropertyCampwoodward from './property-campwoodward.svg';
import PropertyCoppermountainAlt from './property-coppermountain-alt.svg';
import PropertyCoppermountain from './property-coppermountain.svg';
import PropertyEldora from './property-eldora.svg';
import PropertyKillingtonSmall from './property-killington-small.svg';
import PropertyKillington from './property-killington.svg';
import PropertyLeecanyonlv from './property-leecanyonlv.svg';
import PropertyMtbachelor from './property-mtbachelor.svg';
import PropertyOutsidetv from './property-outsidetv.svg';
import PropertyPicomountain from './property-picomountain.svg';
import PropertyPowderbird from './property-powderbird.svg';
import PropertyPowdr from './property-powdr.svg';
import PropertyRideboreal from './property-rideboreal.svg';
import PropertySkisilverstar from './property-skisilverstar.svg';
import PropertySkisodasprings from './property-skisodasprings.svg';
import PropertySnowbird from './property-snowbird.svg';
import PropertyStovepipedeathvalley from './property-stovepipedeathvalley.svg';
import PropertySuncountrytours from './property-suncountrytours.svg';
import PropertyWoodward from './property-woodward.svg';
import PropertyWoodwardbend from './property-woodwardbend.svg';
import PropertyWoodwardcopper from './property-woodwardcopper.svg';
import PropertyWoodwardeldora from './property-woodwardeldora.svg';
import PropertyWoodwardkillington from './property-woodwardkillington.svg';
import PropertyWoodwardmtbachelor from './property-woodwardmtbachelor.svg';
import PropertyWoodwardoceancity from './property-woodwardoceancity.svg';
import PropertyWoodwardpa from './property-woodwardpa.svg';
import PropertyWoodwardparkcityAlt from './property-woodwardparkcity-alt.svg';
import PropertyWoodwardparkcity from './property-woodwardparkcity.svg';
import PropertyWoodwardriviera from './property-woodwardriviera.svg';
import PropertyWoodwardsnowbird from './property-woodwardsnowbird.svg';
import PropertyWoodwardsydneyAlt from './property-woodwardsydney-alt.svg';
import PropertyWoodwardsydney from './property-woodwardsydney.svg';
import PropertyWoodwardtahoe from './property-woodwardtahoe.svg';
import PropertyWoodwardwest from './property-woodwardwest.svg';
import PropertyWrecktangle from './property-wrecktangle.svg';
import PropertyWw from './property-ww.svg';
import SocialEmailHollow from './social-email-hollow.svg';
import SocialFacebookHollow from './social-facebook-hollow.svg';
import SocialFacebook from './social-facebook.svg';
import SocialInstagram from './social-instagram.svg';
import SocialLinkedinHollow from './social-linkedin-hollow.svg';
import SocialLinkedin from './social-linkedin.svg';
import SocialThreads from './social-threads.svg';
import SocialTwitterHollow from './social-twitter-hollow.svg';
import SocialTwitterOld from './social-twitter-old.svg';
import SocialTwitter from './social-twitter.svg';
import SocialYoutube from './social-youtube.svg';
import StatusClosedInverted from './status-closed-inverted.svg';
import StatusClosedWhiteOutline from './status-closed-white-outline.svg';
import StatusClosed from './status-closed.svg';
import StatusExpectedInverted from './status-expected-inverted.svg';
import StatusExpectedWhiteOutline from './status-expected-white-outline.svg';
import StatusExpected from './status-expected.svg';
import StatusOnHoldInverted from './status-on-hold-inverted.svg';
import StatusOnHoldWhiteOutline from './status-on-hold-white-outline.svg';
import StatusOnHold from './status-on-hold.svg';
import StatusOpeningInverted from './status-opening-inverted.svg';
import StatusOpeningWhiteOutline from './status-opening-white-outline.svg';
import StatusOpening from './status-opening.svg';
import UiArrowCircleLeft from './ui-arrow-circle-left.svg';
import UiArrowCircleRight from './ui-arrow-circle-right.svg';
import UiArrowDownThin from './ui-arrow-down-thin.svg';
import UiArrowDown from './ui-arrow-down.svg';
import UiArrowLeft from './ui-arrow-left.svg';
import UiArrowRight from './ui-arrow-right.svg';
import UiArrowScroll from './ui-arrow-scroll.svg';
import UiArrowThinRight from './ui-arrow-thin-right.svg';
import UiArrowUp from './ui-arrow-up.svg';
import UiBackArrow from './ui-back-arrow.svg';
import UiBack from './ui-back.svg';
import UiCalendarPicker from './ui-calendar-picker.svg';
import UiCheckmark2 from './ui-checkmark-2.svg';
import UiCheckmark from './ui-checkmark.svg';
import UiChevronDown from './ui-chevron-down.svg';
import UiChevronThin from './ui-chevron-thin.svg';
import UiCloseCircle from './ui-close-circle.svg';
import UiClose from './ui-close.svg';
import UiEqualizer from './ui-equalizer.svg';
import UiError from './ui-error.svg';
import UiExpand from './ui-expand.svg';
import UiMenu from './ui-menu.svg';
import UiMinusThick from './ui-minus-thick.svg';
import UiMinus from './ui-minus.svg';
import UiPlus2 from './ui-plus-2.svg';
import UiPlusThick from './ui-plus-thick.svg';
import UiPlus from './ui-plus.svg';
import UiRightTriangle from './ui-right-triangle.svg';
import Unknown from './unknown.svg';
import WoodwardBmx from './woodward-bmx.svg';
import WoodwardCheer from './woodward-cheer.svg';
import WoodwardDance from './woodward-dance.svg';
import WoodwardGaming from './woodward-gaming.svg';
import WoodwardGymnastics from './woodward-gymnastics.svg';
import WoodwardInline from './woodward-inline.svg';
import WoodwardMountainBike from './woodward-mountain-bike.svg';
import WoodwardMultiSport from './woodward-multi-sport.svg';
import WoodwardNinja from './woodward-ninja.svg';
import WoodwardParkour from './woodward-parkour.svg';
import WoodwardPhotography from './woodward-photography.svg';
import WoodwardRollerSkating from './woodward-roller-skating.svg';
import WoodwardScooter from './woodward-scooter.svg';
import WoodwardSkateboarding from './woodward-skateboarding.svg';
import WoodwardSki from './woodward-ski.svg';
import WoodwardSnowboarding from './woodward-snowboarding.svg';
import WoodwardTumbling from './woodward-tumbling.svg';
import WoodwardVideoProduction from './woodward-video-production.svg';
import WrapperCoppercoloradoLogoDesktopFooter from './wrapper-coppercolorado-logo-desktop-footer.svg';
import WrapperCoppercoloradoLogoDesktop from './wrapper-coppercolorado-logo-desktop.svg';
import WrapperCoppercoloradoLogoMobile from './wrapper-coppercolorado-logo-mobile.svg';
import WrapperCoppercoloradoLogoSmPrimary from './wrapper-coppercolorado-logo-sm-primary.svg';
import WrapperCoppercoloradoLogoSm from './wrapper-coppercolorado-logo-sm.svg';
import WrapperWoodwardHeader from './wrapper-woodward-header.svg';

export const contentIcons = {
  'content-accessibility': ContentAccessibility,
  'content-age-group': ContentAgeGroup,
  'content-aid': ContentAid,
  'content-air-conditioning': ContentAirConditioning,
  'content-art-tools': ContentArtTools,
  'content-backpack': ContentBackpack,
  'content-barn': ContentBarn,
  'content-bbq': ContentBbq,
  'content-bicycle': ContentBicycle,
  'content-bicycle-2': ContentBicycle2,
  'content-binoculars': ContentBinoculars,
  'content-bullseye': ContentBullseye,
  'content-burger': ContentBurger,
  'content-bus': ContentBus,
  'content-calendar': ContentCalendar,
  'content-camera': ContentCamera,
  'content-camp': ContentCamp,
  'content-campground': ContentCampground,
  'content-car': ContentCar,
  'content-cart-alt': ContentCartAlt,
  'content-cart': ContentCart,
  'content-cellphone': ContentCellphone,
  'content-chatbox': ContentChatbox,
  'content-circle-star': ContentCircleStar,
  'content-clock': ContentClock,
  'content-close-x': ContentCloseX,
  'content-cloudy-sun': ContentCloudySun,
  'content-contributions': ContentContributions,
  'content-counter': ContentCounter,
  'content-desert': ContentDesert,
  'content-earth': ContentEarth,
  'content-electric-pump': ContentElectricPump,
  'content-environment': ContentEnvironment,
  'content-exercise-bike': ContentExerciseBike,
  'content-expand': ContentExpand,
  'content-eyeball': ContentEyeball,
  'content-farm': ContentFarm,
  'content-fast-tracks': ContentFastTracks,
  'content-feedback': ContentFeedback,
  'content-fireplace': ContentFireplace,
  'content-food-donation': ContentFoodDonation,
  'content-garbage-truck': ContentGarbageTruck,
  'content-gas': ContentGas,
  'content-gluten-free': ContentGlutenFree,
  'content-gondola': ContentGondola,
  'content-handleaf': ContentHandleaf,
  'content-handshake-alt': ContentHandshakeAlt,
  'content-handshake': ContentHandshake,
  'content-hang-loose': ContentHangLoose,
  'content-headquarters': ContentHeadquarters,
  'content-headshot': ContentHeadshot,
  'content-heart-hands': ContentHeartHands,
  'content-hearts': ContentHearts,
  'content-heli': ContentHeli,
  'content-highfive': ContentHighfive,
  'content-highfive-2': ContentHighFive2,
  'content-hospital-lodging': ContentHospitalLodging,
  'content-hotel-room': ContentHotelRoom,
  'content-hottub': ContentHottub,
  'content-hottub-shared': ContentHottubShared,
  'content-info-alt': ContentInfoAlt,
  'content-info-close': ContentInfoClose,
  'content-info-open': ContentInfoOpen,
  'content-kitchen-tools': ContentKitchenTools,
  'content-laundry': ContentLaundry,
  'content-lessons': ContentLessons,
  'content-letter-blocks': ContentLetterBlocks,
  'content-lift': ContentLift,
  'content-lightbulb-alt': ContentLightbulbAlt,
  'content-lightbulb': ContentLightbulb,
  'content-lockers': ContentLockers,
  'content-lodge': ContentLodge,
  'content-map': ContentMap,
  'content-mask': ContentMask,
  'content-medal-alt': ContentMedalAlt,
  'content-medal': ContentMedal,
  'content-megaphone': ContentMegaphone,
  'content-membership': ContentMembership,
  'content-messages': ContentMessages,
  'content-mobile-order': ContentMobileOrder,
  'content-mountain-detailed': ContentMountainDetailed,
  'content-mountain-trail': ContentMountainTrail,
  'content-mountain': ContentMountain,
  'content-news': ContentNews,
  'content-no-pets': ContentNoPets,
  'content-non-smoking': ContentNonSmoking,
  'content-outlet': ContentOutlet,
  'content-park': ContentPark,
  'content-parking': ContentParking,
  'content-partners': ContentPartners,
  'content-pass-copper': ContentPassCopper,
  'content-pass-fast-tracks': ContentPassFastTracks,
  'content-pass-killington': ContentPassKillington,
  'content-pass-mtbachelor': ContentPassMtbachelor,
  'content-peace': ContentPeace,
  'content-people': ContentPeople,
  'content-pet': ContentPet,
  'content-phone': ContentPhone,
  'content-photos': ContentPhotos,
  'content-pin': ContentPin,
  'content-pin-hollow': ContentPinHollow,
  'content-plant': ContentPlant,
  'content-plant-2': ContentPlant2,
  'content-play-hollow': ContentPlayHollow,
  'content-play': ContentPlay,
  'content-powdr': ContentPowdr,
  'content-power': ContentPower,
  'content-price-tag': ContentPriceTag,
  'content-qr-code': ContentQrCode,
  'content-raised-hands': ContentRaisedHands,
  'content-recycle': ContentRecycle,
  'content-rv': ContentRv,
  'content-shiny-heart': ContentShinyHeart,
  'content-shovel': ContentShovel,
  'content-shopping': ContentShopping,
  'content-skateboard': ContentSkateboard,
  'content-ski-lift': ContentSkiLift,
  'content-skier': ContentSkier,
  'content-skis-poles': ContentSkisPoles,
  'content-skis': ContentSkis,
  'content-snow-cannon': ContentSnowCannon,
  'content-snow-cat': ContentSnowCat,
  'content-snowflake': ContentSnowflake,
  'content-snowmaking': ContentSnowmaking,
  'content-snowshoe': ContentSnowshoe,
  'content-snowy-mountain': ContentSnowyMountain,
  'content-solar-power': ContentSolarPower,
  'content-sun': ContentSun,
  'content-support': ContentSupport,
  'content-swimming': ContentSwimming,
  'content-switch': ContentSwitch,
  'content-tableware': ContentTableware,
  'content-takeout': ContentTakeout,
  'content-tent': ContentTent,
  'content-tree': ContentTree,
  'content-trees': ContentTrees,
  'content-trophy': ContentTrophy,
  'content-tube-2': ContentTube2,
  'content-tube': ContentTube,
  'content-tv': ContentTv,
  'content-vegetables': ContentVegetables,
  'content-vip': ContentVip,
  'content-wallet': ContentWallet,
  'content-water-hand': ContentWaterHand,
  'content-water': ContentWater,
  'content-waterbottle': ContentWaterbottle,
  'content-waves': ContentWaves,
  'content-webcam': ContentWebcam,
  'content-what-badge-1': ContentWhatBadge1,
  'content-what-badge-2': ContentWhatBadge2,
  'content-wifi': ContentWifi,
  'content-x7': ContentX7,
};

export const decorationIcons = {
  'decoration-blur': DecorationBlur,
  'decoration-hash-marks': DecorationHashMarks,
};

export const difficultyLevelIcons = {
  'difficulty-level-black-2-outline': DifficultyLevelBlack2Outline,
  'difficulty-level-black-2-pill': DifficultyLevelBlack2Pill,
  'difficulty-level-black-2-technical': DifficultyLevelBlack2Technical,
  'difficulty-level-black-2-white-outline': DifficultyLevelBlack2WhiteOutline,
  'difficulty-level-black-3-white-outline': DifficultyLevelBlack3WhiteOutline,
  'difficulty-level-black-2': DifficultyLevelBlack2,
  'difficulty-level-black-3': DifficultyLevelBlack3,
  'difficulty-level-black-outline': DifficultyLevelBlackOutline,
  'difficulty-level-black-pill': DifficultyLevelBlackPill,
  'difficulty-level-black-technical': DifficultyLevelBlackTechnical,
  'difficulty-level-black-white-outline': DifficultyLevelBlackWhiteOutline,
  'difficulty-level-black': DifficultyLevelBlack,
  'difficulty-level-blue-outline': DifficultyLevelBlueOutline,
  'difficulty-level-blue-pill': DifficultyLevelBluePill,
  'difficulty-level-blue-technical': DifficultyLevelBlueTechnical,
  'difficulty-level-blue-white-outline': DifficultyLevelBlueWhiteOutline,
  'difficulty-level-blue': DifficultyLevelBlue,
  'difficulty-level-green-outline': DifficultyLevelGreenOutline,
  'difficulty-level-green-pill': DifficultyLevelGreenPill,
  'difficulty-level-green-technical': DifficultyLevelGreenTechnical,
  'difficulty-level-green-white-outline': DifficultyLevelGreenWhiteOutline,
  'difficulty-level-green': DifficultyLevelGreen,
  'difficulty-level-hiking': DifficultyLevelHiking,
  'difficulty-level-proline': DifficultyLevelProline,
  'difficulty-level-uphill': DifficultyLevelUphill,
};

export const dorIcons = {
  'dor-chanceflurries': DorChanceflurries,
  'dor-chancerain': DorChancerain,
  'dor-chancesleat': DorChancesleat,
  'dor-chancesnow': DorChancesnow,
  'dor-chancetstorms': DorChancetstorms,
  'dor-clear': DorClear,
  'dor-cloudy': DorCloudy,
  'dor-flurries': DorFlurries,
  'dor-hazy': DorHazy,
  'dor-mostlycloudy': DorMostlycloudy,
  'dor-mostlysunny': DorMostlysunny,
  'dor-nt_chanceflurries': DorNtChanceflurries,
  'dor-nt_chancerain': DorNtChancerain,
  'dor-nt_chancesleat': DorNtChancesleat,
  'dor-nt_chancesnow': DorNtChancesnow,
  'dor-nt_chancetstorms': DorNtChancestorms,
  'dor-nt_clear': DorNtClear,
  'dor-nt_cloudy': DorNtCloudy,
  'dor-nt_flurries': DorNtFlurries,
  'dor-nt_hazy': DorNtHazy,
  'dor-nt_mostlycloudy': DorNtMostlycloudy,
  'dor-nt_rain': DorNtRain,
  'dor-nt_sleat': DorNtSleat,
  'dor-nt_snow': DorNtSnow,
  'dor-nt_tstorms': DorNtTstorms,
  'dor-nt_unknown': DorNtUnknown,
  'dor-partlycloudy': DorPartlycloudy,
  'dor-partlysunny': DorPartlysunny,
  'dor-rain': DorRain,
  'dor-sleat': DorSleat,
  'dor-snow': DorSnow,
  'dor-sunny': DorSunny,
  'dor-tstorms': DorTstorms,
  'dor-unknown': DorUnknown,
  'dor-wind': DorWind,
};

export const ecommIcons = {
  'ecomm-adv-single': EcommAdventureSingle,
  'ecomm-adv-multi': EcommAdventureMulti,
  'ecomm-gondola': EcommGondola,
  'ecomm-mtb-single': EcommMtbSingle,
  'ecomm-mtb-multi': EcommMtbMulti,
  'ecomm-multi-day': EcommMultiDay,
  'ecomm-secure-checkout': EcommSecureCheckout,
  'ecomm-single-day': EcommSingleDay,
};

export const featureIcons = {
  'feature-finishline': FeatureFinishline,
  'feature-gladed': FeatureGladed,
  'feature-groomed-am': FeatureGroomedAm,
  'feature-groomed-pm': FeatureGroomedPm,
  'feature-groomed-recent': FeatureGroomedRecent,
  'feature-groomed': FeatureGroomed,
  'feature-race': FeatureRace,
  'feature-snowflake': FeatureSnowflake,
  'feature-snowmaking-24-hours': FeatureSnowmaking24Hours,
  'feature-snowmaking-capable': FeatureSnowmakingCapable,
  'feature-snowmaking-progress': FeatureSnowmakingProgess,
  'feature-terrain-park': FeatureTerrainPark,
  'feature-terrain-park_extra-small-small': FeatureTerrainParkExtraSmallSmall,
  'feature-terrain-park_extra-small': FeatureTerrainParkExtraSmall,
  'feature-terrain-park_large': FeatureTerrainParkLarge,
  'feature-terrain-park_medium-large': FeatureTerrainParkMediumLarge,
  'feature-terrain-park_medium': FeatureTerrainParkMedium,
  'feature-terrain-park_small-medium-large': FeatureTerrainParkSmallMediumLarge,
  'feature-terrain-park_small-medium': FeatureTerrainParkSmallMedium,
  'feature-terrain-park_small': FeatureTerrainParkSmall,
};

export const liftIcons = {
  'lift-double': LiftDouble,
  'lift-gondola': LiftGondola,
  'lift-quad': LiftQuad,
  'lift-triple': LiftTriple,
};

export const loaderIcons = {
  'loader-coppercolorado': LoaderCoppercolorado,
  'loader-eldora': LoaderEldora,
  'loader-killington': LoaderKillington,
  'loader-leecanyonlv': LoaderLeecanyonlv,
  'loader-mtbachelor': LoaderMtbachelor,
  'loader-picomountain': LoaderPicomountain,
  'loader-powdr': LoaderPowdr,
  'loader-powdrplayitfoward': LoaderPowdrplayitforward,
  'loader-rideboreal': LoaderRideboreal,
  'loader-skisodasprings': LoaderSkisodasprings,
  'loader-suncountrytours': LoaderSuncountrytours,
  'loader-woodward': LoaderWoodward,
};

export const partnerIcons = {
  'partner-10barrel': Partner10barrel,
  'partner-actionsportscamp': PartnerActionsportscamp,
  'partner-actionsportshub': PartnerActionsportshub,
  'partner-actionsportspark': PartnerActionsportspark,
  'partner-adventure-wagon': PartnerAdventureWagon,
  'partner-anon': PartnerAnon,
  'partner-bachelor-bike': PartnerBachelorBike,
  'partner-bannister': PartnerBannister,
  'partner-black-strap': PartnerBlackStrap,
  'partner-bud-light': PartnerBudLight,
  'partner-burton': PartnerBurton,
  'partner-coca-cola': PartnerCocaCola,
  'partner-coors-light-alt': PartnerCoorsLightAlt,
  'partner-coors-light': PartnerCoorsLight,
  'partner-dakine': PartnerDakine,
  'partner-dickies': PartnerDickies,
  'partner-dos-equis': PartnerDosEquis,
  'partner-earthcruiser': PartnerEarthcruiser,
  'partner-falls-creek': PartnerFallsCreek,
  'partner-first-bank': PartnerFirstBank,
  'partner-forest-service': PartnerForestService,
  'partner-freepublic': PartnerFreepublic,
  'partner-gatorade': PartnerGatorade,
  'partner-goose-island': PartnerGooseIsland,
  'partner-gopro': PartnerGopro,
  'partner-happy-camper': PartnerHappyCamper,
  'partner-hot-wheels': PartnerHotWheels,
  'partner-hovland': PartnerHovland,
  'partner-innov8te': PartnerInnov8te,
  'partner-intermountain-healthcare': PartnerIntermountainHealthcare,
  'partner-kaltire': PartnerKaltire,
  'partner-mammut': PartnerMammut,
  'partner-michelob': PartnerMichelob,
  'partner-monster': PartnerMonster,
  'partner-mountainparks': PartnerMountainparks,
  'partner-mt-bachelor': PartnerMtBachelor,
  'partner-mtndew': PartnerMtndew,
  'partner-neff': PartnerNeff,
  'partner-never-summer': PartnerNeverSummer,
  'partner-nokian': PartnerNokian,
  'partner-norco': PartnerNorco,
  'partner-nswg': PartnerNswg,
  'partner-odell-crown': PartnerOdellCrown,
  'partner-odell-leaf': PartnerOdellLeaf,
  'partner-pepsi': PartnerPepsi,
  'partner-pepsi-zero': PartnerPepsiZero,
  'partner-pinemountain': PartnerPineMountain,
  'partner-pisten-bully': PartnerPistenBully,
  'partner-playforever': PartnerPlayforever,
  'partner-playitforward': PartnerPlayitforward,
  'partner-powderbird': PartnerPowderbird,
  'partner-powdr': PartnerPowdr,
  'partner-powerade': PartnerPowerade,
  'partner-red-bull': PartnerRedBull,
  'partner-rivers-system': PartnerRiversSystem,
  'partner-robert-modavi': PartnerRobertModavi,
  'partner-skullcandy': PartnerSkullcandy,
  'partner-skye': PartnerSkye,
  'partner-spy': PartnerSpy,
  'partner-subaru-ms': PartnerSubaruMs,
  'partner-subaru': PartnerSubaru,
  'partner-svedka': PartnerSvedka,
  'partner-under-armour': PartnerUnderArmour,
  'partner-upslope': PartnerUpslope,
  'partner-vibram': PartnerVibram,
  'partner-weston': PartnerWeston,
  'partner-woodwardtv': PartnerWoodwardtv,
  'partner-ww2021': PartnerWw2021,
  'partner-yeti': PartnerYeti,
  'partner-zeal': PartnerZeal,
};

// TODO: Can we simplify this with the list of property names
// TODO: First make naming of icons consistent with property TLD
export const propertyIcons = {
  'property-boreal-icon': PropertyBorealIcon,
  'property-boreal-woodward': PropertyBorealWoodward,
  'property-campwoodward': PropertyCampwoodward,
  'property-coppermountain-alt': PropertyCoppermountainAlt,
  'property-coppermountain': PropertyCoppermountain,
  'property-eldora': PropertyEldora,
  'property-killington': PropertyKillington,
  'property-killington-small': PropertyKillingtonSmall,
  'property-leecanyonlv': PropertyLeecanyonlv,
  'property-mtbachelor': PropertyMtbachelor,
  'property-outsidetv': PropertyOutsidetv,
  'property-picomountain': PropertyPicomountain,
  'property-powderbird': PropertyPowderbird,
  'property-powdr': PropertyPowdr,
  'property-rideboreal': PropertyRideboreal,
  'property-skisilverstar': PropertySkisilverstar,
  'property-skisodasprings': PropertySkisodasprings,
  'property-snowbird': PropertySnowbird,
  'property-stovepipedeathvalley': PropertyStovepipedeathvalley,
  'property-suncountrytours': PropertySuncountrytours,
  'property-woodward': PropertyWoodward,
  'property-woodwardbend': PropertyWoodwardbend,
  'property-woodwardcopper': PropertyWoodwardcopper,
  'property-woodwardeldora': PropertyWoodwardeldora,
  'property-woodwardkillington': PropertyWoodwardkillington,
  'property-woodwardmtbachelor': PropertyWoodwardmtbachelor,
  'property-woodwardoceancity': PropertyWoodwardoceancity,
  'property-woodwardpa': PropertyWoodwardpa,
  'property-woodwardparkcity-alt': PropertyWoodwardparkcityAlt,
  'property-woodwardparkcity': PropertyWoodwardparkcity,
  'property-woodwardriviera': PropertyWoodwardriviera,
  'property-woodwardsnowbird': PropertyWoodwardsnowbird,
  'property-woodwardsydney-alt': PropertyWoodwardsydneyAlt,
  'property-woodwardsydney': PropertyWoodwardsydney,
  'property-woodwardtahoe': PropertyWoodwardtahoe,
  'property-woodwardwest': PropertyWoodwardwest,
  'property-wrecktangle': PropertyWrecktangle,
  'property-ww': PropertyWw,
};

export const socialIcons = {
  'social-instagram': SocialInstagram,
  'social-facebook': SocialFacebook,
  'social-twitter': SocialTwitter,
  'social-youtube': SocialYoutube,
  'social-email-hollow': SocialEmailHollow,
  'social-facebook-hollow': SocialFacebookHollow,
  'social-linkedin-hollow': SocialLinkedinHollow,
  'social-linkedin': SocialLinkedin,
  'social-threads': SocialThreads,
  'social-twitter-hollow': SocialTwitterHollow,
  'social-twitter-old': SocialTwitterOld,
};

export const statusIcons = {
  'status-closed': StatusClosed,
  'status-closed-inverted': StatusClosedInverted,
  'status-closed-white-outline': StatusClosedWhiteOutline,
  'status-expected-inverted': StatusExpectedInverted,
  'status-expected-white-outlined': StatusExpectedWhiteOutline,
  'status-expected': StatusExpected,
  'status-on-hold-inverted': StatusOnHoldInverted,
  'status-on-hold-white-outline': StatusOnHoldWhiteOutline,
  'status-on-hold': StatusOnHold,
  'status-opening-inverted': StatusOpeningInverted,
  'status-opening-white-outline': StatusOpeningWhiteOutline,
  'status-opening': StatusOpening,
};

export const uiIcons = {
  'ui-arrow-circle-left': UiArrowCircleLeft,
  'ui-arrow-circle-right': UiArrowCircleRight,
  'ui-arrow-down-thin': UiArrowDownThin,
  'ui-arrow-down': UiArrowDown,
  'ui-arrow-left': UiArrowLeft,
  'ui-arrow-right': UiArrowRight,
  'ui-arrow-scroll': UiArrowScroll,
  'ui-arrow-thin-right': UiArrowThinRight,
  'ui-arrow-up': UiArrowUp,
  'ui-back-arrow': UiBackArrow,
  'ui-back': UiBack,
  'ui-calendar-picker': UiCalendarPicker,
  'ui-checkmark-2': UiCheckmark2,
  'ui-checkmark': UiCheckmark,
  'ui-chevron-down': UiChevronDown,
  'ui-chevron-thin': UiChevronThin,
  'ui-close-circle': UiCloseCircle,
  'ui-close': UiClose,
  'ui-equalizer': UiEqualizer,
  'ui-error': UiError,
  'ui-expand': UiExpand,
  'ui-menu': UiMenu,
  'ui-minus-thick': UiMinusThick,
  'ui-minus': UiMinus,
  'ui-plus-2': UiPlus2,
  'ui-plus-thick': UiPlusThick,
  'ui-plus': UiPlus,
  'ui-right-triangle': UiRightTriangle,
};

export const unknownIcons = {
  unknown: Unknown,
};

export const woodwardIcons = {
  'woodward-bmx': WoodwardBmx,
  'woodward-cheer': WoodwardCheer,
  'woodward-dance': WoodwardDance,
  'woodward-gaming': WoodwardGaming,
  'woodward-gymnastics': WoodwardGymnastics,
  'woodward-inline': WoodwardInline,
  'woodward-mountain-bike': WoodwardMountainBike,
  'woodward-multi-sport': WoodwardMultiSport,
  'woodward-ninja': WoodwardNinja,
  'woodward-parkour': WoodwardParkour,
  'woodward-photography': WoodwardPhotography,
  'woodward-roller-skating': WoodwardRollerSkating,
  'woodward-scooter': WoodwardScooter,
  'woodward-skateboarding': WoodwardSkateboarding,
  'woodward-ski': WoodwardSki,
  'woodward-snowboarding': WoodwardSnowboarding,
  'woodward-tumbling': WoodwardTumbling,
  'woodward-video-production': WoodwardVideoProduction,
};

export const wrapperIcons = {
  'wrapper-coppercolorado-logo-desktop-footer': WrapperCoppercoloradoLogoDesktopFooter,
  'wrapper-coppercolorado-logo-desktop': WrapperCoppercoloradoLogoDesktop,
  'wrapper-coppercolorado-logo-mobile': WrapperCoppercoloradoLogoMobile,
  'wrapper-coppercolorado-logo-sm-primary': WrapperCoppercoloradoLogoSmPrimary,
  'wrapper-coppercolorado-logo-sm': WrapperCoppercoloradoLogoSm,
  'wrapper-woodward-header': WrapperWoodwardHeader,
};

export const icons = {
  ...contentIcons,
  ...decorationIcons,
  ...difficultyLevelIcons,
  ...dorIcons,
  ...ecommIcons,
  ...featureIcons,
  ...liftIcons,
  ...loaderIcons,
  ...partnerIcons,
  ...propertyIcons,
  ...socialIcons,
  ...statusIcons,
  ...uiIcons,
  ...unknownIcons,
  ...woodwardIcons,
  ...wrapperIcons,
};
