import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';

import { ContentCarousel } from '@powdr/components';
import { AppContext } from '@powdr/context/app-context';
import { colorProfileByIndex } from '@powdr/utils';

import { CompareCard } from './components';
import { StyledCompareCards, CarouselContainer } from './styles';

export const CompareCards = ({
  colorProfile,
  relationships,
}) => {
  const { cardData } = relationships;
  const { isMobile } = useContext(AppContext);
  const colorProfileString = colorProfileByIndex(colorProfile);

  const cardMap = useMemo(() => cardData.filter((card) => !card.isHideCard).map((card) => (
    <CompareCard
      key={card.id}
      card={card}
      colorProfile={colorProfileString}
    />
  )), [cardData, colorProfileString]);

  return (
    <StyledCompareCards colorProfile={colorProfile} isMobile={isMobile}>
      {(isMobile) ? (
        <CarouselContainer>
          <ContentCarousel isBottomIndicators isPreviewSlides>
            { cardMap }
          </ContentCarousel>
        </CarouselContainer>
      ) : cardMap}
    </StyledCompareCards>
  );
};

CompareCards.propTypes = {
  colorProfile: PropTypes.number,
  relationships: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        highlightedText: PropTypes.string,
        title: PropTypes.string,
        length: PropTypes.number,
      }).isRequired,
    ).isRequired,
  ).isRequired,
};

CompareCards.defaultProps = {
  colorProfile: '',
};
