import styled, { css } from 'styled-components';

import { Components, Breakpoints, ThemeProps as Prop } from '@powdr/constants';
import { componentColor } from '@powdr/utils';

const CPNT = Components.FOOTER;
const CPNT_COPYRIGHT = Components.COPYRIGHT;

export const StyledFooter = styled.footer`
  .container {
    background: ${() => componentColor(Prop.BACKGROUND, CPNT)};
    padding-top: 70px;
    padding-bottom: 60px;
    position: relative;
    z-index: 9;

    h3 {
      color: ${() => componentColor(Prop.HEADER, CPNT)};
      font-size: ${({ theme }) => theme.FontSize.REG50};
      margin-bottom: 10px;
    }

    .footer-row {
      margin-bottom: 50px;
      width: 100%;
      display: flex;

      ${Breakpoints.mobile(css`
        flex-direction: column;
      `)}

      .footer-column {
        vertical-align: top;

        &.left {
          min-width: 300px;
          ${Breakpoints.mobile(css`
            margin-bottom: 50px;
          `)}
        }

        &.right {
          flex: auto;
          padding-left: 50px;
          ${Breakpoints.mobile(css`
            padding-left: 0;
          `)}
        }
      }
    }

    // TODO: if we are only going to use the one footer component this could move into the footer-nav/style.js
    .footer-nav {
      margin-bottom: 60px;
      min-height: auto;
      ul.l-group {
        padding: 0 !important;
        li.l-item {
          display: block;
        }
        a.btn {
          line-height: 1.5;
          text-align: left;
          padding: 0;
        }
      }
    }

    .social {
      margin-top: 10px;
      ${Breakpoints.mobile(css`
        text-align: left;
      `)}
    }

    .partners-component {

      ul {
        text-align: left;

        li.l-item {
          padding: 0 25px 20px 0;
        }
      }
    }

    .footer-logo {
      text-align: left;

      a {
        display: block;
        padding-right: 50px;

        ${Breakpoints.mobile(css`
          padding-right: 40%;
        `)}

        svg {
          max-width: 150px;
        }
      }
    }
  }

  .copyright {
    background-color: ${() => componentColor(Prop.BACKGROUND, CPNT_COPYRIGHT)};

    p {
      color: ${() => componentColor(Prop.LINK_TXT, CPNT_COPYRIGHT)};
      font-size: ${({ theme }) => theme.FontSize.REG20};

      ${Breakpoints.large(css`
        font-size: ${({ theme }) => theme.FontSize.REG30};
      `)}

      ${Breakpoints.larger(css`
         font-size: ${({ theme }) => theme.FontSize.REG40};
      `)}
    }
  }
`;

export const FooterCopyright = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  background-color: ${() => componentColor(Prop.BACKGROUND, CPNT_COPYRIGHT)};

  p {
    color: ${() => componentColor(Prop.LINK_TXT, CPNT_COPYRIGHT)};
    ${({ theme }) => theme.fonts.PRI_BDY_MED};
    font-size: ${({ theme }) => theme.FontSize.REG40};
  }
`;
