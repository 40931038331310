import styled, { css } from 'styled-components';

import { ThemeProps as Prop, Breakpoints, Components } from '@powdr/constants';
import { themeUtil } from '@powdr/utils';

const CPNT = Components.HOTSPOT_GROUP;

export const StyledHotspotGroup = styled.div``;

export const HotspotImageSelectors = styled.ul`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const HotspotImageSelector = styled.li`
  ${({ theme }) => theme.fonts.PRI_HDR_BLK};
  font-size: ${({ theme }) => theme.FontSize.MED10};
  line-height: ${({ theme }) => theme.FontSize.MED10};
  text-align: center;
  margin: 15px;
  padding: 20px;
  min-width: 125px;
  border-radius: 30px;
  cursor: pointer;

  &:hover {
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_ACTIVE, CPNT, colorProfile)};
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_ACTIVE, CPNT, colorProfile)};
  }

  ${({ active, colorProfile }) => ((active) ? `
    color: ${themeUtil(Prop.BTN_TXT_ACTIVE, CPNT, colorProfile)};
    background-color: ${themeUtil(Prop.BTN_BG_ACTIVE, CPNT, colorProfile)};
  ` : `
    color: ${themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
    background-color: ${themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
  `)}
`;

export const ActiveHotspotImageContainer = styled.div``;
