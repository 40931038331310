/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
  useCallback, useContext, useEffect,
  useState, useRef, useMemo,
} from 'react';
import { useSwipeable } from 'react-swipeable';

import { Icon } from '@powdr/components';
import { ColorProfiles, CarouselIndicatorTypes, VerticalPosition } from '@powdr/constants';
import { AppContext } from '@powdr/context/app-context';
import { useWindowResize } from '@powdr/hooks';

import {
  StyledContentCarousel, CarouselItemsWrapper, CarouselItem, ThumbnailItemWrapper,
  CarouselControlOuterWrapper, CarouselControlInnerWrapper,
  OnSlideArrows, ControlArrowWrapper, ControlArrow,
  CarouselIndicators, CarouselIndicatorWrapper, CarouselDotIndicator,
  CarouselCustomIndicator, TextIndicator, IconIndicator, ThumbnailCarouselContainer,
} from './styles';

export const ContentCarousel = ({
  isAutoRotate,
  isInfiniteScroll,
  isArrowsInSlide,
  isHideArrowsInSlideBackground,
  customIndicators,
  isTopArrows,
  isTopIndicators,
  isBottomArrows,
  isBottomIndicators,
  isPreviewSlides,
  isThumbnails,
  slidesToShow,
  colorProfile,
  children,
}) => {
  const { isMobile } = useContext(AppContext);
  const resize = useWindowResize();
  const [activeItemIndex, setActiveItemIndex] = useState(null);
  const [resetCloneIndexes, setResetCloneIndexes] = useState([-1, -1]);
  const [trueStartIndex, setTrueStartIndex] = useState(0);
  const [trueEndIndex, setTrueEndIndex] = useState(children.filter((e) => e !== null).length - 1);
  const [carouselItems, setCarouselItems] = useState(children.filter((e) => e !== null));
  const [hasTransitionClass, setHasTransitionClass] = useState(false);
  const [isControlsDisabled, setControlsDisabled] = useState(false);
  const [previewSlidePercentage, setPreviewSlidePercentage] = useState(80);
  const [wasMobile, setWasMobile] = useState();
  const animationDuration = 600; // speed in milliseconds
  const rotationSpeed = 7000; // speed in milliseconds
  const rotationInterval = useRef(null);
  // cannot have preview with only 2 slides
  const previewOptionCheck = !!((children.length > 2 && isPreviewSlides && !(slidesToShow > 1)));
  const controlsOnTop = !!((isTopArrows || isTopIndicators));
  const controlsOnBottom = !!((isBottomArrows || isBottomIndicators));

  if (carouselItems?.length === 0) return null;

  useEffect(() => {
    setCarouselItems(children.filter((e) => e !== null));
  }, [children]);

  const setAutoRotation = useCallback(() => {
    clearInterval(rotationInterval.current);
    // initialize/reset auto rotate interval
    if (isAutoRotate) {
      rotationInterval.current = setInterval(() => {
        setActiveItemIndex((prevVisibleSlide) => prevVisibleSlide + 1);
      }, rotationSpeed);
    }
    return null;
  }, [isAutoRotate]);

  const clearRotation = useCallback((interval) => {
    clearInterval(interval);
  }, []);

  const scrollLeft = useCallback(() => {
    if (isAutoRotate) clearRotation(rotationInterval.current);
    if (!isInfiniteScroll && activeItemIndex === trueStartIndex) return;
    setActiveItemIndex(activeItemIndex - 1);
    setAutoRotation();
  }, [
    activeItemIndex, clearRotation, isAutoRotate,
    isInfiniteScroll, setAutoRotation, trueStartIndex,
  ]);

  const scrollRight = useCallback(() => {
    if (isAutoRotate) clearRotation(rotationInterval.current);
    if (!isInfiniteScroll && activeItemIndex === trueEndIndex) return;
    setActiveItemIndex(activeItemIndex + 1);
    setAutoRotation();
  }, [
    activeItemIndex, clearRotation, setAutoRotation,
    isAutoRotate, isInfiniteScroll, trueEndIndex,
  ]);
  const isActive = (index) => (
    index === activeItemIndex
    || (previewOptionCheck && activeItemIndex === carouselItems.length - 2 && index === 2)
    || (previewOptionCheck && activeItemIndex === 1
      && index === carouselItems.length - children.length)
    || (index === 1 && activeItemIndex === carouselItems.length - 1)
    || (index === carouselItems.length - 2 && activeItemIndex === 0)
  );
  const getTranslateAmount = (previewTranslate) => (
    (previewTranslate)
      ? `-${((previewSlidePercentage * 2) - ((100 - previewSlidePercentage) / 2)) + ((activeItemIndex - 2) * previewSlidePercentage)}%`
      : `-${(activeItemIndex) * (100 / ((isMobile) ? 1 : slidesToShow))}%`
  );

  const swipeHandler = useSwipeable({
    onSwipedLeft: () => { if (!isControlsDisabled) scrollRight(); },
    onSwipedRight: () => { if (!isControlsDisabled) scrollLeft(); },
    preventScrollOnSwipe: true,
    trackTouch: true,
    trackMouse: true,
  });

  const setupSlides = useCallback(() => {
    if (isInfiniteScroll) {
      const itemsWithClones = [...children.filter((e) => e !== null)];
      itemsWithClones.unshift(React.cloneElement(itemsWithClones[itemsWithClones.length - 1]));
      itemsWithClones.push(React.cloneElement(itemsWithClones[1]));
      if (isThumbnails) {
        itemsWithClones.push(React.cloneElement(itemsWithClones[2]));
        itemsWithClones.push(React.cloneElement(itemsWithClones[3]));
        itemsWithClones.push(React.cloneElement(itemsWithClones[itemsWithClones.length - 1]));
        setResetCloneIndexes([1, itemsWithClones.length - 3]);
        setTrueStartIndex(2);
        setTrueEndIndex(itemsWithClones.length - 4);
      } else {
        if ((slidesToShow === 2 || slidesToShow === 3) && !isMobile) {
          itemsWithClones.push(React.cloneElement(itemsWithClones[2]));
          itemsWithClones.push(React.cloneElement(itemsWithClones[3]));
          setResetCloneIndexes([0, itemsWithClones.length - slidesToShow]);
          setTrueStartIndex(1);
          setTrueEndIndex(itemsWithClones.length - slidesToShow - 1);
        }
        if (previewOptionCheck) {
          itemsWithClones.unshift(React.cloneElement(itemsWithClones[itemsWithClones.length - 2]));
          itemsWithClones.push(React.cloneElement(itemsWithClones[itemsWithClones.length - 2]));
          setResetCloneIndexes([1, itemsWithClones.length - 2]);
          setTrueStartIndex(2);
          setTrueEndIndex(itemsWithClones.length - 3);
        }
        if ((slidesToShow === 1 || isMobile) && !previewOptionCheck) {
          setResetCloneIndexes([0, itemsWithClones.length - 1]);
          setTrueStartIndex(1);
          setTrueEndIndex(itemsWithClones.length - 2);
        }
      }
      setCarouselItems(itemsWithClones);
    }
  }, [children,
    isInfiniteScroll,
    isMobile,
    isThumbnails,
    previewOptionCheck,
    slidesToShow,
  ]);

  useEffect(() => {
    setActiveItemIndex((!isInfiniteScroll) ? 0 : (previewOptionCheck) ? 2 : 1);
    // initial setup of carousel items, clones for infinite scroll
    setupSlides();

    if (isThumbnails && children.length > 2) {
      setPreviewSlidePercentage(33);
    }

    setAutoRotation();

    return () => {
      clearRotation(rotationInterval.current);
    };
  }, [
    children.length, setAutoRotation, clearRotation,
    isInfiniteScroll, isThumbnails, previewOptionCheck, setupSlides,
  ]);

  // if going from desktop to mobile or mobile, reset carousel for proper use
  useEffect(() => {
    if ((isMobile && !wasMobile) || (!isMobile && wasMobile)) {
      setupSlides();
      setWasMobile(isMobile);
    }
  }, [isMobile, setupSlides, wasMobile]);

  useEffect(() => {
    if (resize.windowWidth && !isThumbnails) {
      if (resize.windowWidth > 650 && resize.windowWidth < 960) {
        setPreviewSlidePercentage(50);
      }
      if (resize.windowWidth < 650 && resize.windowWidth > 500) {
        setPreviewSlidePercentage(65);
      }
      if (resize.windowWidth < 500) {
        setPreviewSlidePercentage(85);
      }
    }
  }, [isThumbnails, resize.windowWidth]);

  // handle slide transtions/looping on activeItemIndex update
  useEffect(() => {
    // if on true start/end
    if (activeItemIndex === trueStartIndex || activeItemIndex === trueEndIndex) {
      setTimeout(() => {
        setHasTransitionClass(true);
      }, animationDuration);
    }

    // if reached left clone
    if (activeItemIndex === resetCloneIndexes[0]) {
      setControlsDisabled(true);
      setTimeout(() => {
        setHasTransitionClass(false);
        setActiveItemIndex(trueEndIndex);
      }, animationDuration);
      setHasTransitionClass(true);
    }

    // if reached right clone
    if (activeItemIndex === resetCloneIndexes[1]) {
      setControlsDisabled(true);
      setTimeout(() => {
        setHasTransitionClass(false);
        setActiveItemIndex(trueStartIndex);
      }, animationDuration);
    }
  }, [activeItemIndex, resetCloneIndexes, trueEndIndex, trueStartIndex]);

  // prevents spamming of slide controls
  useEffect(() => {
    if (isControlsDisabled) {
      setTimeout(() => {
        setControlsDisabled(false);
      }, animationDuration * 2);
    }
  }, [isControlsDisabled]);

  const ControlArrowHandler = ({
    placeOnSlide,
    direction,
    hideBackground,
  }) => useMemo(() => (
    <ControlArrowWrapper
      placeOnSlide={placeOnSlide}
      direction={direction}
      onClick={isControlsDisabled ? null : (direction === 'prev') ? scrollLeft : scrollRight}
    >
      <ControlArrow
        className="control-arrow"
        placeOnSlide={placeOnSlide}
        hideBackground={hideBackground}
        direction={direction}
        colorProfile={colorProfile || ColorProfiles.BASE}
      >
        <Icon
          className="control-arrow-icon"
          name={(placeOnSlide) ? 'ui-arrow-left' : 'ui-arrow-circle-left'}
          height={(placeOnSlide) ? '15' : '45'}
          width={(placeOnSlide) ? '15' : '45'}
        />
      </ControlArrow>
    </ControlArrowWrapper>
  ), [direction, placeOnSlide, hideBackground]);

  const CarouselControls = ({ position, showArrows, showIndicators }) => useMemo(() => (
    <CarouselControlOuterWrapper position={position}>
      <CarouselControlInnerWrapper
        showArrows={showArrows}
        showIndicators={showIndicators}
      >
        {(showArrows) && (
          <ControlArrowHandler
            direction="prev"
            placeOnSlide={false}
          />
        )}
        {(showIndicators) && (
          <CarouselIndicators numIndicators={children.length}>
            {carouselItems.map((item, idx) => {
              // if a clone slide, don't create indicator for it
              if (idx === 0
                || idx === (carouselItems.length - 1)
                || (previewOptionCheck && idx === carouselItems.length - 2)
                || (previewOptionCheck && idx === 1)
              ) {
                return null;
              }

              return (
                <CarouselIndicatorWrapper key={`indicator-${item.key}-${idx}`} animationDuration={animationDuration}>
                  {(customIndicators.items?.length > 0) ? (
                    <CarouselCustomIndicator
                      onClick={() => {
                        if (isAutoRotate) clearRotation(rotationInterval.current);
                        setActiveItemIndex(idx);
                        setAutoRotation();
                      }}
                    >
                      {(customIndicators.type === CarouselIndicatorTypes.TEXT) ? (
                        <TextIndicator
                          animationDuration={animationDuration}
                          className={classNames({ active: isActive(idx) })}
                          colorProfile={colorProfile || ColorProfiles.BASE}
                        >
                          {customIndicators.items[idx - 1]?.iconName}
                        </TextIndicator>
                      ) : (
                        <IconIndicator
                          className={classNames('icon-indicator', { active: isActive(idx) })}
                          colorProfile={colorProfile || ColorProfiles.BASE}
                          animationDuration={animationDuration}
                        >
                          <Icon
                            name={customIndicators.items[idx - 1]?.iconName}
                            height={customIndicators.items[idx - 1]?.height || '30'}
                          />
                        </IconIndicator>
                      )}
                    </CarouselCustomIndicator>
                  ) : (
                    <CarouselDotIndicator
                      onClick={() => {
                        if (isAutoRotate) clearRotation(rotationInterval.current);
                        setActiveItemIndex(idx);
                        setAutoRotation();
                      }}
                      className={classNames({ active: isActive(idx) })}
                      colorProfile={colorProfile || ColorProfiles.BASE}
                      animationDuration={animationDuration}
                    />
                  )}
                </CarouselIndicatorWrapper>
              );
            })}
          </CarouselIndicators>
        )}
        {(showArrows) && (
          <ControlArrowHandler
            direction="next"
            placeOnSlide={false}
          />
        )}
      </CarouselControlInnerWrapper>
    </CarouselControlOuterWrapper>
  ), [position, showArrows, showIndicators]);

  return (
    <StyledContentCarousel {...swipeHandler} colorProfile={colorProfile}>
      {(controlsOnTop) && (
        <CarouselControls
          position={VerticalPosition.TOP}
          showArrows={isTopArrows}
          showIndicators={isTopIndicators}
        />
      )}
      <CarouselItemsWrapper
        className={classNames({ 'transition-active': hasTransitionClass })}
        translateAmount={getTranslateAmount((!isThumbnails) ? previewOptionCheck : false)}
        animationDuration={animationDuration}
        isAddPadding={(controlsOnTop || controlsOnBottom)}
        isPreviewSlides={(!isThumbnails) ? previewOptionCheck : false}
      >
        {carouselItems.map((item, idx) => (
          <CarouselItem
            key={`${idx}`}
            slideWidth={((!isThumbnails) ? previewOptionCheck : false)
              ? previewSlidePercentage : (100 / ((isMobile) ? 1 : slidesToShow))}
            isThumbnailItem={false}
          >
            {item}
          </CarouselItem>
        ))}
      </CarouselItemsWrapper>
      {(isThumbnails && children.length > 2) && (
        <ThumbnailCarouselContainer>
          <CarouselItemsWrapper
            className={classNames('thumbnail-items', { 'transition-active': hasTransitionClass })}
            translateAmount={getTranslateAmount(true)}
            animationDuration={animationDuration}
            isPreviewSlides={(children.length > 2)}
          >
            {carouselItems.map((item, idx) => (
              <ThumbnailItemWrapper
                key={`${idx}`}
                slideWidth={33}
                onClick={() => ((isControlsDisabled) ? null : setActiveItemIndex(idx))}
              >
                <CarouselItem isThumbnailItem>
                  {item}
                </CarouselItem>
              </ThumbnailItemWrapper>
            ))}
          </CarouselItemsWrapper>
        </ThumbnailCarouselContainer>
      )}
      {(isArrowsInSlide) && (
        <OnSlideArrows
          onMouseEnter={() => clearRotation(rotationInterval.current)}
          onMouseLeave={() => setAutoRotation()}
        >
          <ControlArrowHandler placeOnSlide direction="prev" hideBackground={isHideArrowsInSlideBackground} />
          <ControlArrowHandler placeOnSlide direction="next" hideBackground={isHideArrowsInSlideBackground} />
        </OnSlideArrows>
      )}
      {(controlsOnBottom) && (
        <CarouselControls
          position={VerticalPosition.BOTTOM}
          showArrows={isBottomArrows}
          showIndicators={isBottomIndicators}
        />
      )}
    </StyledContentCarousel>
  );
};

ContentCarousel.propTypes = {
  isAutoRotate: PropTypes.bool,
  isInfiniteScroll: PropTypes.bool,
  isArrowsInSlide: PropTypes.bool,
  customIndicators: PropTypes.shape({
    type: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        iconName: PropTypes.string,
        height: PropTypes.string,
      }),
    ),
  }),
  isTopArrows: PropTypes.bool,
  isTopIndicators: PropTypes.bool,
  isBottomArrows: PropTypes.bool,
  isBottomIndicators: PropTypes.bool,
  isPreviewSlides: PropTypes.bool,
  isThumbnails: PropTypes.bool,
  isHideArrowsInSlideBackground: PropTypes.bool,
  slidesToShow: PropTypes.number,
  colorProfile: PropTypes.string,
  children: PropTypes.node,
};

ContentCarousel.defaultProps = {
  isAutoRotate: false,
  isInfiniteScroll: true,
  isArrowsInSlide: false,
  customIndicators: {},
  isTopArrows: false,
  isTopIndicators: false,
  isBottomArrows: false,
  isBottomIndicators: false,
  isPreviewSlides: false,
  isThumbnails: false,
  isHideArrowsInSlideBackground: false,
  slidesToShow: 1,
  colorProfile: null,
  children: null,
};
