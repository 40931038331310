import styled, { css } from 'styled-components';

import { Link } from '@powdr/components';
import {
  ThemeProps as Prop, Components, Breakpoints,
} from '@powdr/constants';
import { componentColor } from '@powdr/utils';

const CPNT = Components.PROMOTIONS;

export const StyledPromotionList = styled.div`
  display: flex;
  justify-content: space-between;

  ${Breakpoints.mobile(css`
    height: 100%;
    padding: 70px 30px 30px 30px;
    flex-direction: column;
    justify-content: unset;
  `)}
`;

export const PromoBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: ${() => componentColor(Prop.PROMO_ITEM_BG, CPNT, Prop.BACKGROUND)};
  padding: 30px 15px;
  border-radius: 5px;
  width: 380px;
  height: 275px;
  text-align: center;
  box-shadow: 4px 4px 8px -3px rgb(0 0 0 / 50%);

  ${Breakpoints.small(css`
    width: 300px;
    height: 250px;
  `)}

  ${Breakpoints.mobile(css`
    width: 100%;
    margin-top: 25px;

    &:first-child {
      margin-top: 0px;
    }
  `)}
`;

export const PromoHeader = styled.h1`
  color: ${() => componentColor(Prop.PROMO_ITEM_HEADER, CPNT, Prop.HEADER)};
  text-transform: initial;
  font-size: ${({ theme }) => theme.FontSize.LRG10};

  ${Breakpoints.mobile(css`
    font-size: ${({ theme }) => theme.FontSize.MED20};
  `)}
`;

export const PromoPrice = styled.h2`
  display: flex;
  align-items: baseline;
  font-size: ${({ theme }) => theme.FontSize.LRG40};
  color: ${() => componentColor(Prop.PROMO_ITEM_HEADER, CPNT, Prop.HEADER)};

  ${Breakpoints.small(css`
    font-size: ${({ theme }) => theme.FontSize.LRG20};
  `)}

  ${Breakpoints.mobile(css`
    font-size: ${({ theme }) => theme.FontSize.LRG12};
  `)}
`;

export const PromoPriceDesc = styled.div`
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  font-size: ${({ theme }) => theme.FontSize.REG30};
  text-transform: initial;
  color: ${() => componentColor(Prop.PROMO_ITEM_CONTENT, CPNT, Prop.CONTENT)};

  ${Breakpoints.small(css`
    font-size: ${({ theme }) => theme.FontSize.REG20};
  `)}

  ${Breakpoints.mobile(css`
    margin-left: 3px;
  `)}
`;

export const PromoDescription = styled.p`
  ${({ theme }) => theme.fonts.PRI_BDY_MED};
  font-size: ${({ theme }) => theme.FontSize.REG30};
  line-height: normal;
  color: ${() => componentColor(Prop.PROMO_ITEM_CONTENT, CPNT, Prop.CONTENT)};

  ${Breakpoints.small(css`
    font-size: ${({ theme }) => theme.FontSize.REG20};
  `)}
`;

export const PromoButton = styled(Link)`
  ${({ theme }) => theme.fonts.SEC_HDR_BLK};
  font-size: ${({ theme }) => theme.FontSize.REG50};
  font-weight: bold;
  padding: 15px 20px;
  min-width: 125px;
  text-transform: uppercase;
  background-color: ${() => componentColor(Prop.PROMO_ITEM_BTN_BG, CPNT, Prop.BTN_BG)};
  color: ${() => componentColor(Prop.PROMO_ITEM_BTN_TXT, CPNT, Prop.BTN_TXT)};

  &:hover {
    background-color: ${() => componentColor(Prop.PROMO_ITEM_BTN_BG_HOVER, CPNT, Prop.BTN_BG_HOVER)};
    color: ${() => componentColor(Prop.PROMO_ITEM_BTN_TXT_HOVER, CPNT, Prop.BTN_TXT_HOVER)};
  }
`;
