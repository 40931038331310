// eslint-disable-next-line import/no-unresolved
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { CheckedState } from '@powdr/constants';

import { StyledCheckbox } from './styles';

export const Checkbox = ({
  className,
  colorProfile,
  checked,
  isChips,
  label,
  name,
  onChange,
}) => {
  const handleCheckedState = useCallback((e) => {
    onChange(name);
    e.stopPropagation();
  }, [onChange, name]);

  return (
    <StyledCheckbox
      className={className}
      isChips={isChips}
      isChecked={checked === CheckedState.CHECKED}
      colorProfile={colorProfile}
    >
      <label
        htmlFor={name}
        className="input-checkbox"
      >
        <input
          id={name}
          name={name}
          onChange={(e) => handleCheckedState(e)}
          checked={checked === CheckedState.CHECKED}
          type="checkbox"
          value={name}
        />
        <span className="label">{label}</span>
      </label>
    </StyledCheckbox>
  );
};

Checkbox.propTypes = {
  className: PropTypes.string,
  colorProfile: PropTypes.string,
  checked: PropTypes.oneOf(Object.values(CheckedState)),
  isChips: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

Checkbox.defaultProps = {
  className: '',
  colorProfile: null,
  checked: CheckedState.UNCHECKED,
  isChips: false,
  onChange: () => {},
};
