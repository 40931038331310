import classNames from 'classnames';
import React, { useState } from 'react';

import { SeasonToggle } from '@powdr/components';
import { SeasonalityPlacement } from '@powdr/constants';
import { useStaticData } from '@powdr/web/src/hooks';

import { StyledSeasonalityTab } from './styles';

export const SeasonalityTab = () => {
  const [active, setIsActive] = useState(false);
  const { headerConfig } = useStaticData();
  const { isSeasonalityEnabled, seasonalityPlacement } = headerConfig;

  if (isSeasonalityEnabled
    && seasonalityPlacement !== SeasonalityPlacement.IN_SIDE_TAB) {
    return null;
  }

  return (
    <StyledSeasonalityTab
      className={classNames('seasonality-tab', { active })}
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
    >
      {headerConfig.isSeasonalityEnabled && <SeasonToggle />}
    </StyledSeasonalityTab>

  );
};
