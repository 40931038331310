import styled, { css } from 'styled-components';

import { Pagination } from '@powdr/components';
import { Breakpoints, Components } from '@powdr/constants';

const CPNT = Components.GRID;

export const StyledGrid = styled.div`
  display: flex;
  gap: ${({ $gap }) => `${$gap}px;`};
  margin: ${({ $gap }) => `${$gap}px;`};
  //margin: ${({ $topPadding, $bottomPadding }) => `${$topPadding}px 0px ${$bottomPadding}px;`};
  ${({ disableDesktopWrap }) => ((disableDesktopWrap) ? '' : 'flex-wrap: wrap;')}
  ${({ isCenterAlignUnevenRows }) => ((isCenterAlignUnevenRows) ? 'justify-content: center;' : '')}

  ${Breakpoints.mobile(css`
    flex-wrap: wrap;
  `)}
`;

export const GridPagination = styled(Pagination)``;

export const GridFilters = styled.div``;

export const FilterHeader = styled.h2`
  text-transform: uppercase;
  font-size: 26px;
  margin-bottom: 10px;
`;

export const FilterToggles = styled.ul`
  display: flex;
  flex-wrap: wrap;

  li {
    margin-right: 25px;
    margin-bottom: 10px;
  }
`;
