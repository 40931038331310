// eslint-disable-next-line import/no-unresolved
import PropTypes from 'prop-types';
import React from 'react';

import { VerticalPosition } from '@powdr/constants';
import { componentFactory } from '@powdr/utils';

import { StyledUiColumn } from './styles';

export const UiColumn = ({
  colorProfile,
  parentColorProfile,
  isUsingBrandingColors,
  isNoPadding,
  isAlternatePadding,
  contentPosition,
  relationships,
}) => {
  const columnComponents = relationships?.columnComponents;

  return (
    <StyledUiColumn
      contentPosition={contentPosition}
      isUsingBrandingColors={isUsingBrandingColors}
      isNoPadding={isNoPadding}
      isAlternatePadding={isAlternatePadding}
    >
      {(componentFactory(
        columnComponents,
        colorProfile || parentColorProfile,
        undefined,
        { is5050: true },
      ))}
    </StyledUiColumn>
  );
};

UiColumn.propTypes = {
  colorProfile: PropTypes.string,
  parentColorProfile: PropTypes.string,
  isUsingBrandingColors: PropTypes.bool,
  isNoPadding: PropTypes.bool,
  isAlternatePadding: PropTypes.bool,
  contentPosition: PropTypes.string,
  relationships: PropTypes.shape().isRequired,
};

UiColumn.defaultProps = {
  colorProfile: null,
  parentColorProfile: null,
  isUsingBrandingColors: false,
  isNoPadding: false,
  isAlternatePadding: false,
  contentPosition: VerticalPosition.TOP,
};
